'use client';

import { useRef } from 'react';
import PropTypes from 'prop-types';
// next
// @mui
import gsap from 'gsap';
import { useGSAP } from '@gsap/react';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';

// ----------------------------------------------------------------------

const AVAILABLE_ICONS = {
  stripe: `<svg xmlns='http://www.w3.org/2000/svg' x='856' y='193'  width='120' height='60' fill-rule='evenodd' fill='#6772e5'><path d='M101.547 30.94c0-5.885-2.85-10.53-8.3-10.53-5.47 0-8.782 4.644-8.782 10.483 0 6.92 3.908 10.414 9.517 10.414 2.736 0 4.805-.62 6.368-1.494v-4.598c-1.563.782-3.356 1.264-5.632 1.264-2.23 0-4.207-.782-4.46-3.494h11.24c0-.3.046-1.494.046-2.046zM90.2 28.757c0-2.598 1.586-3.678 3.035-3.678 1.402 0 2.897 1.08 2.897 3.678zm-14.597-8.345c-2.253 0-3.7 1.057-4.506 1.793l-.3-1.425H65.73v26.805l5.747-1.218.023-6.506c.828.598 2.046 1.448 4.07 1.448 4.115 0 7.862-3.3 7.862-10.598-.023-6.667-3.816-10.3-7.84-10.3zm-1.38 15.84c-1.356 0-2.16-.483-2.713-1.08l-.023-8.53c.598-.667 1.425-1.126 2.736-1.126 2.092 0 3.54 2.345 3.54 5.356 0 3.08-1.425 5.38-3.54 5.38zm-16.4-17.196l5.77-1.24V13.15l-5.77 1.218zm0 1.747h5.77v20.115h-5.77zm-6.185 1.7l-.368-1.7h-4.966V40.92h5.747V27.286c1.356-1.77 3.655-1.448 4.368-1.195v-5.287c-.736-.276-3.425-.782-4.782 1.7zm-11.494-6.7L34.535 17l-.023 18.414c0 3.402 2.552 5.908 5.954 5.908 1.885 0 3.264-.345 4.023-.76v-4.667c-.736.3-4.368 1.356-4.368-2.046V25.7h4.368v-4.897h-4.37zm-15.54 10.828c0-.897.736-1.24 1.954-1.24a12.85 12.85 0 0 1 5.7 1.47V21.47c-1.908-.76-3.793-1.057-5.7-1.057-4.667 0-7.77 2.437-7.77 6.506 0 6.345 8.736 5.333 8.736 8.07 0 1.057-.92 1.402-2.207 1.402-1.908 0-4.345-.782-6.276-1.84v5.47c2.138.92 4.3 1.3 6.276 1.3 4.782 0 8.07-2.368 8.07-6.483-.023-6.85-8.782-5.632-8.782-8.207z' /></svg>`,
};

function HeroDesktopAnimation({ sx, icon }) {
  const theme = useTheme();
  const accentColor = theme?.palette?.secondary?.main || '#FA541C';

  const container = useRef();
  useGSAP(
    () => {
      gsap.set('.main_animation_container svg', { opacity: 1 });
      gsap.set('#clickA1', { scale: 0, transformOrigin: '50% 100%' });
      gsap.set('#lineOkA1', { strokeDasharray: 17, strokeDashoffset: 17 });
      gsap.set('#elipsA1 > *', { scale: 0.8, transformOrigin: 'center' });
      gsap.set('#l1A1', { strokeDasharray: 71, strokeDashoffset: 71 });
      gsap.set('#l2A1', { strokeDasharray: 708, strokeDashoffset: 708 });
      gsap.set('#l3A1', { strokeDasharray: 53, strokeDashoffset: 53 });
      gsap.set('#lineA1All1', { strokeDasharray: 93, strokeDashoffset: 93 * 3, opacity: 0 });
      gsap.set('#lineA1All2', { strokeDasharray: 713, strokeDashoffset: 713 * 3, opacity: 0 });

      gsap.set('#w5A1', { scale: 0, transformOrigin: 'center' });
      gsap.set('#w1A1, #w2A1, #w3A1, #w4A1, #w6A1', { scale: 0, transformOrigin: 'center' });

      gsap.to('#liveA1M', {
        opacity: 0.5,
        duration: 0.8,
        ease: 'sine.inOut',
        repeat: -1,
        yoyo: true,
      });

      gsap
        .timeline({ repeat: -1, repeatDelay: 1, defaults: { ease: 'sine.inOut', duration: 0.5 } })
        .to('#btmA1, #cursor1A1', {
          scale: 0.98,
          transformOrigin: '50% 50%',
          repeat: 1,
          yoyo: true,
        })
        .to('#clickA1', { scale: 1, opacity: 0, duration: 1 }, '<');

      gsap.to('#w3A1M', {
        scale: 0.9,
        transformOrigin: 'center',
        duration: 1,
        ease: 'sine.inOut',
        repeat: -1,
        yoyo: true,
      });

      // gsap.to('#elipsA1 > *', {
      //   scale: 1,
      //   transformOrigin: 'center',
      //   duration: 2.5,
      //   delay: -5,
      //   ease: 'sine.inOut',
      //   stagger: { each: .5, repeat: -1, yoyo: true, from: 'end' },
      // });
      gsap.to('#btnLightningA1 > *', {
        scale: 0.9,
        transformOrigin: 'center',
        duration: 1,
        ease: 'sine.inOut',
        delay: -2,
        stagger: { each: 0.5, repeat: -1, yoyo: true },
      });

      gsap
        .timeline({ defaults: { ease: 'sine.inOut', duration: 0.25 } })
        .to('#lightning1A1', {
          scaleY: 1.05,
          x: -1,
          y: 1,
          transformOrigin: '50% 0%',
          repeat: -1,
          yoyo: true,
        })
        .to(
          '#lightning2A1',
          { scaleY: 1.05, x: 1, y: -1, transformOrigin: '50% 100%', repeat: -1, yoyo: true },
          '<',
        );

      gsap.to('#iconsMoveA1 > *', {
        y: 1,
        scale: 0.97,
        ease: 'sine.inOut',
        duration: 2.5,
        transformOrigin: 'center',
        delay: -15,
        stagger: { each: 0.75, repeat: -1, yoyo: true, from: 'random' },
      });
      gsap
        .timeline({ repeat: -1, delay: 3 })
        .to('#lineA1All1', { strokeDashoffset: 93, ease: 'none', duration: 1 })
        .to('#lineA1All2', { strokeDashoffset: 713, ease: 'none', duration: 4 })
        .to('#lineOkA1', { strokeDashoffset: 0, duration: 0.5, ease: 'sine.inOut' }, '>-3')
        .to('#lineOkA1', { opacity: 0, duration: 0.5, ease: 'sine.inOut' }, '>+1.2');

      gsap
        .timeline()
        .to('#w1A1', { scale: 1, ease: 'back.out(1.2)', duration: 1 })
        .to('#w2A1', { scale: 1, ease: 'back.out(1.2)', duration: 1 }, '>-.5')
        .to('#l1A1', { strokeDashoffset: 0, duration: 1, ease: 'sine.inOut' }, '<')
        .to('#w3A1', { scale: 1, ease: 'back.out(1.2)', duration: 1 }, '>-.5')
        .to('#l2A1', { strokeDashoffset: 0, duration: 1, ease: 'sine.inOut' }, '<')
        .to('#w4A1', { scale: 1, ease: 'back.out(1.2)', duration: 1 }, '>-.5')
        .to(
          '#w5A1',
          { scale: 0.9, ease: 'back.out(1.2)', duration: 1, transformOrigin: '49% 50%' },
          '>-.5',
        )
        .to('#l3A1', { strokeDashoffset: 0, duration: 1, ease: 'sine.inOut' }, '<')
        .to('#w6A1', { scale: 1, ease: 'back.out(1.2)', duration: 1 }, '>-.5')
        // .to('#w5A1', { scale: 1, ease: 'back.out(1.2)', duration: 1 }, '>-.5')
        .to('#lineA1All1', { opacity: 1, ease: 'sine.inOut', duration: 0.5 }, '<')
        .to('#lineA1All2', { opacity: 1, ease: 'sine.inOut', duration: 0.5 }, '<');
    },
    { scope: container },
  );

  const iconSvg = icon && AVAILABLE_ICONS[icon];

  const iconCard = iconSvg && `<g transform='scale(1.5) translate(-306, -95)'>
    <image overflow='visible' opacity='0.22' width='143' height='143' xlink:href='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAI8AAACPCAYAAADDY4iTAAAACXBIWXMAAAsSAAALEgHS3X78AAAA
GXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAEZNJREFUeNrsnYmO4yoQRQvbmf//
3m4DT0+aSAxTy60CL+mxJStLJx2Dj28tYIro2Z7t2Z7t2Z7t2Z7t52/paddpW306+Tn+B6oP7Pyf
qpIfC1T6wceXfggM9YHnuGNKN2h7Pel79YFn7FjSAW1KJwFTJ/3P+sAzBkP6MHiq82/17hClD4Im
gf8jXdD2kRONgFPvCFG62W+mya+vautMUOpdIUofAE0KfG5UlWaDgwIR/dwlEKWLwUFOvgUSCtpV
8CBAoO95IKqfDg+qNgl4z/OZM5UoqjTVgCUK1GkQpRupjQZLAoHywHQUPNJrz2N1ghbxtW4LTxqE
RnoP+Qyiamcpj6QeI8895u5QgNKF4FgnnwMHec+jQLPhQc1TBV/XSYp0CEDpBuBY6oLsqDpdBY8E
hgRLdUB0GUDpJHD6E+YBZnGCNKJEM3yd6oBH2osDIg2kQwHaTgQHMUkaNEsAKguio+FBoOFASQBI
2nGl7nllzk29AzwRcBIIzqK8h8AkqduoCUNNFfe6KK9Ld8JL97z/HgEQHQZQugk4EiQL+B6iRL0K
zYZHc4wtWNpH6Tn3iJg2LRKrVyvPCDgaCP2elPdQiCz/x4LI6+dI4PSAcK/fbXk/bx8JUCBOeXq1
GVKfbaLqeMCxFKXdVwGmxVCk5IRoFjwRcLS9Pe7SHOP77wsIkARMGKBtMjjkBAcBZnWAFAVo1GyN
KM57z8rrLEDUg1uUY53iJJ9htlBwFgWc9nEV/mZBZDnSEYDQXI7k20jgrA0w+fdx5waczLShPQaP
Ck1Rn+0gc+UFRwJmZeBZHRBxABFovhCAEHgQtckdQEsDy8IARJ35oisAmp3n8YIjqcv6+9hWY9fM
muZEaxlo7iKxxq8s1dEUp9/7PuoVJxuwF+XYp5qubZLqSH/rAdLAWRlwNgdM/f9FIrDZylNBU5UF
ePbu+aIcezaOsSgXwRT12SapjeXnWOBsAjjSc1SJPOrj9XkkgCzV0cBZfz++wdmF47YSg5aq1hkq
tA2oDhnQWCAhsHD7qoDE+UEcQCN+jyen06pPVkxVbsBpzdUbIAQIzrwW4LNh9dkmqg4HkqQ6Ejj9
/nKAtBoOdJpguqyhCMtkZcVU9W3YnYrT+179d6twrg5PElrDENbouAUQB8yrg+cFAmSpz0i+xwMP
ojr772PfFfg1xUGHHYpivsLqMxqqJ8B09ZEW5+9sHTAv5XUPkqY+K5OQ9A5XoONZiOoUximWwElB
5elH5y0FOjTDjNycpymOBc7KgGLtkgJZ6oP4PX270TBdSgpmxmztjep8A/BYv19BgKqiXqfleRIA
kAccTnl+Cc9fghlbDd8HyTbPMFuc8mQBnjbC+nbC49kj6mPCtA2aLEl1FsPXkcDZOlh+dc9fgi9k
RV4zcz1ojkdylls/Z2+UR/JzLHBaWN/fb+cCLR1A09RnC5gs1HlejGhLUpxXB44EkKU+aMQ1O9rS
lIdzkNdOdSTFsWYg9q8Xp+9D3mhsm6A6krlqX2uZYc1sIQBxkRcacXkGSJEB0aL4PLkBZxNUxwOO
tC+MAknqc/nYFpoUXAxwNsbHkQB6GQBFlScCD4HKkzuA1i4inAFODxAHjmS+3KZrGzRZVl5Hg8jK
7/xymK/ZyqNFW9pcHm5ognOWtWOTwLGAycIFU5SL+/RpqJ77qzTVQXwfDaDe99GShWlQeTwzCC2f
Z1UGPVFwWiDbvuzVJzEAaT6NK+oayTBbI+mWw4yE61LILkVe3mgrojwjSUIEHMlMcUMbWwdQZtSs
KAo0ZLqO9nkQ9ZHGsjQVah855VkA0+DN81RHorAwJ14DJxmKk3+3URpQXQWAUN/n9FF1Lfqypogi
jrMVxiPKE4HHY7asLPN7X41Rckm9cgfOi/GdPAECAaZrGJ7k9He8vo+VMJScaGmoQvJ50I4czfNY
pisD41Wc4ryYKG1j8kVLACApbIf9npGxLXRQdAHM18ao0AsAanMqz+gkeBSeyjivGQCH829eDTyb
kmj03gBAho83fXhCGxTVQIqYrtUJjgaPNQkeHZrQwOkhWEi+dcbycbjhjK0DCJmCu4DW4fL7trx+
jyfzrM0ilBKEnkgrCo+kPFx0kw1otg6ajUkqWneVIJPfEk1aHWVkPo932RPtLtAVAEl71CbEW9I9
674tYkayl055smCqtsax3gSH2Jrw78moc+0+7L4tlNio32OF8uitOCuT9pcirSOV5w1PEXyKVYjI
+nbvDrWJrluEOs0QPJHCIYlid1FotxhLd4pKt+xId5RK/s4R8PTDFMSMZK+NaVsVeBay5yh552uj
C3xC850jUzI0JfKsvZMAiCQgVrLvOo3kd9Boq188iTrFIUZ9qqA8a5fsQ9ptheWzfJ5pUzI8PtBI
CG8B4lk1Y8akdyucJfp7NS9uOgT3uDJJRO+9+dqANOLrhCOuo5aV8y4Vp62Y4ZXqRfndSGLQfUU2
Wzs0wEVkI21FLxRkkavToq3oGFcSGqsphgYI8n+Q/A7R/IU92+VOuPYX0tcoQha+SgFgpm4zbr1J
5Luvy/J/EmHLylnL0s1c1InzfZLxt9SoDzEmq5C89iJihiPgjCRGpyYJE/C3yKqn1kKWifCoIhnH
PVpikls4gITnqCIjpj7iHCdgZKBebbYsMxaBybsGMwUkPBKqcw6zdFJSAKIl2CeegCDsMC80f0PM
GDlgQgAhZ4SBqqjnQtGg9KpxAlUogb8x0tYh5RmV+ZE6Eh6IUBM600lGlKcakFlTRFDo0N+41GG2
IizEv4jAhHYgMu2Agn8nio1Ae5xXa90jTdGs3/CkIExTdoTZQpSHjAw1gZ0aVckR04UcIwEXjTfF
YWX2Pf7NlNH1M30eT7iPzvjzDNaOqI33ezOOywOcdWEeYraWiUB4ncoUvKrRSjrIVT/7orFyXZbq
eR1gOrhNpyuPp4PT5A5JB3/eA8wIaJ5jRS8qmi0UZ/s83qhuRPovuRonR6sJDP8vafNC99o8Nxd+
+uZ10m/X9uVDOvnWnXiDi2uGj/ox8PyLJ94b+t++j66Cp074Xv0h8HBlH+sntG+5YUeinfsvbnUA
yI+EB21IndxJ9eYnHOmXOtA3P055JHmuk66eoz9/xe9pfXOpIi+T1UP7jlUs1TJRnu8dWow+eLKt
vkSKyXq/N+O83d5hRkGrTnNVwd+e1YaIKa5ge2/nAy4HgWHV4JSk11PW2eM31IBajHw+clzeC6YC
fXuoA71MAqUCB4jcoqt1DPr5CijBbBDR34xcMN621knny9w2EBBuYrdHgaryHjnBQP2H5DzeI0yA
10/zXBwE9K0XEFdUd5bZIrCjrBoK5PhcBVVsVlurUzVH26Z9f4bjfKrP4+0EZDn84uhwNGI5IiJB
fw+9kLQ+KIG+PQSibRIonujBCxEClPQb0jrD/VzdGTmYajiy6DJ0kX6pYL/PSsOE4eFWhvCuVaxV
xdMK2ms1yxfQIeRu0KMOpGgex6uyI+0tToCsJONlCx2Q03epzg6y3uuXcfNGExH1qQF4tAvF096I
CbuN2fJ0qrZKKLdSulZnQavD0NeZasGiE9pJ4MVRA21D+wrxC+lO8EQd4uqApq+5IHX6W3lKExD0
AFXS7y0fAYdIXou5AqBkoA8sYLxqdBg8UqeiOQfLJEl1qaQiHe1S/MgytUT8yqQ14PtE/DrtIkDa
qf1d6scKBBXWeYTUarbyeOy+drXlroMlgDL9veKo5NMk0lc+R30f9IKpxskvRvu4fkBVGM0RTVee
SnYN7ogJQ8xRJrkGefv+0j22ax1zxzRS4S9isqqhqlkBRGt3Bs1b1FS5VGhzqIu0/t4INJLavFc9
556/lyDZyb51tl9QCVnkyFs+gIs0rWp/fZXj/nlWnmdHn3qiL7dzPZIkTIFEYAE6VOrktiQQV3qo
P76VSRaepTxc4ZLSgaCBsjv6JgJMCJbZPk/UYSwgMNwi1pLycOCspBcrmwmPFCRkQVkl5eF2L0DF
kY0/NFTXfKDkSAAWw8b3HbaSXKADqZDXFiez1mPmwEmAk2yZrCq08//Hb/qztvrevYfApDnVSOhO
NDAHfAsAI3VsxN+RCtW/ofkmvSiH5KyXTnX6NfyIfAtcogOexYi0epX5ZsDhoNoZlUL9HxQad8i+
OU2UtBKW12HufRmp3nhfsN4CpzDmCq1uPMtsaTVGJXi+GZA4iLLiYEcd5rD/M+LzWFEXlwTknOAs
KI0EjQZOWz1mtDR21NezIq3ebP3/+NUA8yUA1SuRFIllYwCVQLN1isOchA5cwAir92t6aFBTtdHf
dau46jdpEKAKmuvKJAQ15fkSwOEg2hVoEL9nynhXNM/TyrzXXK0MODvZ5Q5JUZz3/+5LRSPwjJqt
aKTVm6gvZtfgyYLz7DFfNALV5oyyuDrciOnqAVq6zlzIrguqgVPoz8p4SOmkiN/jGUm3cjytGfpi
AOpN2C4AZIXsHpPlut9t1thWcozvtHkatF4Uojq98qDwzFYeDR4uJfGtqA8C0A6E64dM0RiJtjTT
VejvQh39mJQEUHIqDjessZBd8e8In0fL8RQhIfjNOMuo6dqVQWRr6gaNQhWZklEdeZ4WpHbke2EA
QtWmv6pfTK4oojyWAnkzy5ryZCa3EwHIyjZ75vO4b9GeNTxhqU9i1MdThc4qH22VjLYKncyEB40w
dyXf8yU8ar6Px2G2koKHhOpWZRcNoNw9eoqKSOC8a6vvxNdVjyqPJ8PsVR5tiOJbAUmLurSIy3OH
6fSqN4jpsqKPBIDj9XNenfJYqoMWb/NEW9ZoOqo+38JwhTfPg5otGjVZUbMlOc5WBGIBpP2PYpgr
S3XQUH1GkrCQPoswK6Po34ov1MM16u+cdusNqj79VdhezRxA2u+hPoRkshYQHmtFeu99Wqj67Ir/
sxtjXLsxtiVFWjRLdUYcZkR9OJAS/T1lNDnhycJovFRaGil4NtPn0fweFCANFiu341GeoZzP7LEt
rnNLIAlXldDcAgepOU4HRFtE9v1XoxPDpJmI3lmFp45taUMTWu6nvw24ACdHA2drOkqaLLY6TBZN
gIccpkub5L4rj8gourUogtWW6UlCD1SaTS3O/8fB0063yMRPT/WAc9TY1ghAyOT3DITnnrk8l+R5
enAS4B+U4Ah1oT/n6XDAIObKqgoYHdsiwqbgavdrefZC8Qnw5BwYPUV5JBXqTVgZiLA0pbFCcy2/
M0N5iOwFHCQH2rp/i4NFAgedenq68lghelL8HwsgaQJ76b6XDWgscNACalb4Wp3qY90tm8FH6x52
5FZwNGQ/PFTnwNEOpAdIkti1gaif0N7OEswdMGgtciJ/zSpt0ji6Ho8WxkvvZQCY6Jzly0J1GgAo
CfmUXn0W+nO1i3bPFC9i780sezPNnsUePHsFzdQh92rNgkdblgQBqIeoEr+6V1HMEmqm0IK3o/Ag
0Ze1WoiVKfZAgwAThmlmYXpSnE+txPMivNZMUQQaFByv2fKqUAHMWQWB0aaZIk5ynXnizwCIjBO8
GM8X4LNWPXL0TlEUHiJsQUtkxTDk7zMWL6hXmq2R8F36fH+yK/O80J/TWz3F7KOKE1GgkRVgC/A5
ooMXMjhLeaT/ZdVVR5QoulvQHA1PBKLI+soRcOrd4IkChIBETli8icARh9mbQPSsCB8B5rTSUelE
NUsDakQDsFwNTxQmGlCZU6ofHlVBF6mPjjjVZMCRgO8fAY4HIAJOPvIcdYZPK5t5dPllD0TSSbeU
BH1ETWsUHK8vhD6i1RJPr7V6Ru3uqAqhQIyap5nKEzVnCEi3UJuz4bF+J0147gHmyDbXAZBGn58K
zpnwjEA0629XwOPxi0b+dio0V8HjhSiqJOnC9tZJYCGvL4HmanjQ3z8alHQgLEcBdTk0d4EnClFE
wWYcSz0AsKizW68+aSvda0sHw3aGytDBMNRPO1l3P650UR/UE79bP/0kfdox3qF99aLvPvB8+PH+
OGB+2slIDyhPx//ktlV6tmd7tmd7tmd7tmd7to/c/hNgAE1AHCZl4qAGAAAAAElFTkSuQmCC' transform='matrix(1 0 0 1 845 182)'>
    </image>
    <g>
      <path fill='#FFFFFF' d='M902.17,280.19c-13.04,0-23.64-10.6-23.64-23.64V228c0-13.04,10.6-23.64,23.64-23.64h28.54
           c13.04,0,23.64,10.6,23.64,23.64v28.54c0,13.04-10.6,23.64-23.64,23.64H902.17z' />
      <path fill='#DEE3E9' d='M930.72,204.72c12.84,0,23.28,10.44,23.28,23.28v28.54c0,12.84-10.44,23.28-23.28,23.28h-28.54
           c-12.84,0-23.28-10.44-23.28-23.28V228c0-12.84,10.44-23.28,23.28-23.28H930.72 M930.72,204h-28.54c-13.25,0-24,10.75-24,24
           v28.54c0,13.25,10.75,24,24,24h28.54c13.25,0,24-10.75,24-24V228C954.72,214.75,943.97,204,930.72,204L930.72,204z' />
    </g>
  </g>`;

  const svg = `<svg version='1.1' id='anim1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px'
     width='950px' viewBox='80 0 930 996' enable-background='new 0 0 1051 996' xml:space='preserve'>
<g>
<g>
  <defs>
     <path id='SVGID_1_' d='M882.74,461.08h-12v-0.72h12V461.08z M860.74,461.08h-12v-0.72h12V461.08z M838.74,461.08h-12v-0.72h12
        V461.08z M816.74,461.08h-12v-0.72h12V461.08z M794.74,461.08h-12v-0.72h12V461.08z M772.74,461.08h-12v-0.72h12V461.08z
         M750.74,461.08h-12v-0.72h12V461.08z M728.74,461.08h-12v-0.72h12V461.08z M706.74,461.08h-12v-0.72h12V461.08z M684.74,461.08
        h-3.9v-0.72h3.9V461.08z M892.74,461.08l0-0.72c3.96-0.02,7.87-1.04,11.31-2.95l0.35,0.63
        C900.85,460,896.82,461.05,892.74,461.08z M912.01,451.44l-0.57-0.44c2.39-3.15,3.96-6.88,4.54-10.77l0.71,0.11
        C916.09,444.35,914.47,448.19,912.01,451.44z M916.96,430.3h-0.72v-12h0.72V430.3z M916.96,408.3h-0.72v-12h0.72V408.3z
         M916.96,386.3h-0.72v-12h0.72V386.3z M916.96,364.3h-0.72v-12h0.72V364.3z M916.96,342.3h-0.72v-12h0.72V342.3z M916.96,320.3
        h-0.72v-12h0.72V320.3z M916.96,298.3h-0.72v-12h0.72V298.3z M916.96,276.3h-0.72v-12h0.72V276.3z M916.96,254.3h-0.72v-12h0.72
        V254.3z M916.96,232.3h-0.72v-12h0.72V232.3z M916.96,210.3h-0.72v-12h0.72V210.3z M916.96,188.3h-0.72v-12h0.72V188.3z
         M916.96,166.3h-0.72v-12h0.72V166.3z M916.96,144.3h-0.72v-0.1c0-3.93-0.98-7.82-2.84-11.25l0.63-0.34
        c1.92,3.54,2.93,7.54,2.93,11.59V144.3z M907.07,125.5c-3.13-2.42-6.84-4.03-10.73-4.65l0.11-0.71
        c4.01,0.64,7.83,2.29,11.06,4.79L907.07,125.5z M886.41,120.56h-12v-0.72h12V120.56z M864.41,120.56h-12v-0.72h12V120.56z
         M842.41,120.56h-12v-0.72h12V120.56z M820.41,120.56h-12v-0.72h12V120.56z M798.41,120.56h-12v-0.72h12V120.56z M776.41,120.56
        h-12v-0.72h12V120.56z' />
  </defs>
  <clipPath id='SVGID_2_'>
     <use xlink:href='#SVGID_1_' overflow='visible' />
  </clipPath>
  <g clip-path='url(#SVGID_2_)'>
     <path id='l2A1' fill='none' stroke='${accentColor}' stroke-width='2' stroke-miterlimit='10' d='M764.41,120.2H892.6
        c13.25,0,24,10.75,24,24v292.52c0,13.25-10.75,24-24,24H680.84' />
  </g>
</g>
  <g>
  <defs>
     <path id='SVGID_3_' d='M474.91,120.55h-12v-0.72h12V120.55z M452.91,120.55h-12v-0.72h12V120.55z M430.91,120.55h-12v-0.72h12
        V120.55z M408.91,120.55h-4.27v-0.72h4.27V120.55z' />
  </defs>
    <clipPath id='SVGID_4_'>
     <use xlink:href='#SVGID_3_' overflow='visible' />
  </clipPath>
    <g clip-path='url(#SVGID_4_)'>

        <line id='l1A1' fill='none' stroke='${accentColor}' stroke-width='2' stroke-miterlimit='10' x1='404.63' y1='120.19' x2='474.91' y2='120.19' />
  </g>
</g>
  <path id='lineA1All2' fill='none' stroke='${accentColor}' stroke-width='1.5' stroke-miterlimit='10' d='M760.59,120.5l128.54-0.33
  c17.15-0.07,27.3,8.4,27.85,28.57l-0.39,283.08c0.43,19.44-9.39,30.12-32.97,29.05l-204.42-0.13' />

  <line id='lineA1All1' fill='none' stroke='${accentColor}' stroke-width='1.5' stroke-miterlimit='10' x1='395.96' y1='120.43' x2='488.94' y2='120.39' />
  <g id='w5A1'>
  <path fill='${accentColor}' d='M679.17,458.1c0,0.16,0.01,0.32,0.01,0.49c0,1.45-0.03,2.9-0.08,4.34c1.28-0.08,2.29-1.12,2.29-2.42
     C681.39,459.24,680.41,458.21,679.17,458.1z' />
    <g id='elipsA1'>
     <g>

           <circle opacity='0.05' fill='none' stroke='${accentColor}' stroke-width='0.72' stroke-miterlimit='10' cx='540.52' cy='460.51' r='399' />
     </g>
      <g>

           <circle opacity='0.08' fill='none' stroke='${accentColor}' stroke-width='0.72' stroke-miterlimit='10' cx='540.52' cy='460.51' r='330.33' />
     </g>
      <g>
        <defs>
           <filter id='Adobe_OpacityMaskFilter' filterUnits='userSpaceOnUse' x='263.02' y='191.93' width='555' height='533.3'>
              <feColorMatrix type='matrix' values='1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 1 0' />
           </filter>
        </defs>
        <mask maskUnits='userSpaceOnUse' x='263.02' y='191.93' width='555' height='533.3' id='SVGID_5_'>
           <g filter='url(#Adobe_OpacityMaskFilter)'>
              <radialGradient id='SVGID_6_' cx='539.2557' cy='458.1944' r='284.6112' gradientUnits='userSpaceOnUse'>
                 <stop offset='0' style='stop-color:#FFFFFF' />
                <stop offset='1' style='stop-color:#000000' />
              </radialGradient>
             <rect x='254.64' y='173.58' fill='url(#SVGID_6_)' width='569.22' height='569.22' />
           </g>
        </mask>
        <ellipse opacity='0.44' mask='url(#SVGID_5_)' fill='${accentColor}' cx='540.52' cy='458.58' rx='277.5' ry='266.65' />
     </g>
      <g>
        <defs>
           <filter id='Adobe_OpacityMaskFilter_1_' filterUnits='userSpaceOnUse' x='310.52' y='237.57' width='460' height='442.02'>
              <feFlood style='flood-color:white;flood-opacity:1' result='back' />
             <feBlend in='SourceGraphic' in2='back' mode='normal' />
           </filter>
        </defs>
        <mask maskUnits='userSpaceOnUse' x='310.52' y='237.57' width='460' height='442.02' id='SVGID_7_'>
           <g filter='url(#Adobe_OpacityMaskFilter_1_)'>
              <radialGradient id='SVGID_8_' cx='539.2792' cy='454.7908' r='238.8701' gradientUnits='userSpaceOnUse'>
                 <stop offset='0' style='stop-color:#FFFFFF' />
                <stop offset='1' style='stop-color:#000000' />
              </radialGradient>
             <rect x='300.41' y='215.92' fill='url(#SVGID_8_)' width='477.74' height='477.74' />
           </g>
        </mask>
        <ellipse opacity='0.44' mask='url(#SVGID_7_)' fill='${accentColor}' cx='540.52' cy='458.58' rx='230' ry='221.01' />
     </g>
      <g>
        <defs>
           <filter id='Adobe_OpacityMaskFilter_2_' filterUnits='userSpaceOnUse' x='358.02' y='283.22' width='365' height='350.73'>
              <feFlood style='flood-color:white;flood-opacity:1' result='back' />
             <feBlend in='SourceGraphic' in2='back' mode='normal' />
           </filter>
        </defs>
        <mask maskUnits='userSpaceOnUse' x='358.02' y='283.22' width='365' height='350.73' id='SVGID_9_'>
           <g filter='url(#Adobe_OpacityMaskFilter_2_)'>
           </g>
        </mask>
        <ellipse opacity='0.05' mask='url(#SVGID_9_)' fill='${accentColor}' cx='540.52' cy='458.58' rx='182.5' ry='175.37' />
     </g>
  </g>
    <defs>
     <filter id='Adobe_OpacityMaskFilter_3_' filterUnits='userSpaceOnUse' x='401.87' y='325.35' width='277.31' height='266.47'>
        <feColorMatrix type='matrix' values='1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 1 0' />
     </filter>
  </defs>
    <mask maskUnits='userSpaceOnUse' x='401.87' y='325.35' width='277.31' height='266.47' id='SVGID_10_'>
     <g filter='url(#Adobe_OpacityMaskFilter_3_)'>
        <radialGradient id='SVGID_11_' cx='540.2471' cy='460.5822' r='595.9792' gradientUnits='userSpaceOnUse'>
           <stop offset='0' style='stop-color:#FFFFFF' />
          <stop offset='1' style='stop-color:#000000' />
        </radialGradient>
       <rect x='-55.73' y='-135.4' fill='url(#SVGID_11_)' width='1191.96' height='1191.96' />
     </g>
  </mask>
    <ellipse mask='url(#SVGID_10_)' fill='#FFFFFF' cx='540.52' cy='458.58' rx='138.65' ry='133.23' />
    <g>
     <path fill='#212B36' d='M525.99,367.32c-7,1.38-19.88,6-27.5,10c-21.25,11-37.63,31.13-45.13,55.51
        c-4.25,13.5-4.25,37.75,0,51.26c10.13,32.75,34.38,55.63,67.51,63.88c13.88,3.5,36.25,2.88,49.51-1.38
        c4.88-1.5,9.25-3.13,9.75-3.75c0.63-0.5-1.88-6.63-5.38-13.75c-5.13-10.25-6.75-12.5-8.5-11.88c-5.5,2.13-17.75,4.38-24,4.38
        c-26.88,0-53.88-20.63-60.76-46.76c-2.5-9.5-2.25-25.25,0.75-34.75c11.25-36.63,53.76-55.01,88.14-38
        c16.38,8.13,28,21.5,33.13,38c3.25,10.5,3.38,26.13,0.13,36.25l-2.25,7.5l13,6c7.75,3.5,13.38,5.5,14,4.75
        c4.13-4.38,8.13-27.75,7.25-41.63c-2.5-38.75-26.75-69.76-64.63-82.63c-6.38-2.25-11.63-4.13-25-4.38
        C536.74,365.69,527.74,367.07,525.99,367.32z' />
      <ellipse fill='#fa541c' cx='543.11' cy='458.45' rx='31.25' ry='31.25' />
      <path id='cursor2A1' fill='#212B36' d='M571.24,489.33c5,10.13,31,65.38,36.38,76.88c2.88,6.38,5.63,11.5,6.25,11.63
        c0.5,0,0.88-1.5,1-3.5c0-1.88,1.88-11,4-20.25l4-16.75l20.63-4.63l20.63-4.5l-6.88-3.13c-3.75-1.75-25.13-11.63-47.38-22.13
        c-22.25-10.38-40.63-18.88-40.75-18.88C568.87,484.08,569.87,486.46,571.24,489.33z' />
  </g>
    <g>
     <g id='btnLightningA1'>
        <g>
           <path fill='${accentColor}' d='M510.38,655.74h-51.14c-3.31,0-6-2.69-6-6v-15.54c0-3.31,2.69-6,6-6h51.14c3.31,0,6,2.69,6,6v15.54
              C516.38,653.05,513.7,655.74,510.38,655.74z' />
          <g>
              <path fill='#FFFFFF' d='M463.8,636.43h4.36c2.51,0,3.96,1.55,3.96,3.75c0,2.21-1.48,3.73-4.02,3.73h-1.97v3.59h-2.34V636.43z
                  M467.73,642.03c1.34,0,1.99-0.76,1.99-1.85c0-1.1-0.65-1.84-2-1.84h-1.58v3.69H467.73z' />
            <path fill='#FFFFFF' d='M475.99,647.49h-2.34v-11.06h2.34V647.49z' />
            <path fill='#FFFFFF' d='M482.34,640.2h0.09l2.24-3.77h2.64l-3.38,5.53l3.45,5.53h-2.69l-2.27-3.78h-0.09l-2.27,3.78h-2.68
                 l3.46-5.53l-3.4-5.53h2.65L482.34,640.2z' />
            <path fill='#FFFFFF' d='M488.77,636.43h7.45v1.93h-5.12v2.64h4.73v1.93h-4.73v2.64h5.14v1.93h-7.48V636.43z' />
            <path fill='#FFFFFF' d='M498.09,636.43h2.34v9.13h4.74v1.93h-7.08V636.43z' />
           </g>
        </g>
       <g>
           <path fill='${accentColor}' d='M621.83,655.74h-32.88c-3.31,0-6-2.69-6-6v-15.54c0-3.31,2.69-6,6-6h32.88c3.31,0,6,2.69,6,6v15.54
              C627.83,653.05,625.14,655.74,621.83,655.74z' />
         <g>
              <path fill='#FFFFFF' d='M592.61,647.49l3.82-11.06h3.01l3.81,11.06h-2.51l-0.82-2.52h-3.99l-0.82,2.52H592.61z M599.34,643.14
                 l-1.36-4.19h-0.09l-1.36,4.19H599.34z' />
           <path fill='#FFFFFF' d='M604.58,636.43h4.36c2.51,0,3.96,1.55,3.96,3.75c0,2.21-1.48,3.73-4.02,3.73h-1.97v3.59h-2.34V636.43z
                  M608.51,642.03c1.34,0,1.99-0.76,1.99-1.85c0-1.1-0.65-1.84-2-1.84h-1.58v3.69H608.51z' />
           <path fill='#FFFFFF' d='M616.77,647.49h-2.34v-11.06h2.34V647.49z' />
           </g>
        </g>
     </g>
      <g>
        <path id='lightning2A1' fill='#212B36' d='M547.91,647h-17.09l26.58-28.48l-5.7,17.09' />
        <path id='lightning1A1' fill='#212B36' d='M550.7,635.61h17.09l-26.58,28.48l5.7-17.09' />
     </g>
  </g>
</g>
  <g id='w1A1'>
  <circle fill='${accentColor}' cx='402.66' cy='120.19' r='2.43' />
    <g>

        <image overflow='visible' opacity='0.22' width='342' height='312' xlink:href='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAVYAAAE4CAYAAAAAUdupAAAACXBIWXMAAAsSAAALEgHS3X78AAAA
GXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAFn9JREFUeNrs3YmO4ki3ReGwofv9
n7crwVe/VOi6o2M44Qky61sSwjgZnWKx48TglAAAAAAAwB/E5BA4NsAOFofg58uDDAGSJiICBfDT
hDt5j0QOfDPRfbxopx/4vkgQ+LNk/XGinb7xe5l+wGcGSPO4x36MYKdv8vrTSZ+FZIHPkuly0PO+
VbLTB7/udNB+YgW+h1iXg/a/XbDTh73edPA+MgW+j2SXg/e9TbDTh7zOdPDtIz4jAQPXJtPS3/be
fotgpze/xhSUZfR+e9MssQLnijXSrF+Cf1sGnuNSuU5veu5p4/bo47Z8TkIF3tfc720vGx93qWCn
i593i0SngFxHEi15Au8X7haZLgHJjgr2FLlOFz5nRJ6v7Yhct4qWWIH3inWPSJeB+75NsNMFzxVJ
pj1xRrYjkh393AQMXNP0j0p02SncS+Q6nfw80SZ+vl263btPtGxArMB7xNrajki0drsm2C3p9RC5
3k866C2h9YTa2z8F5HqEWEkV2C/XkWZ/TZqt/fl9pk4inTr3nY6Q6xHyiAyTisp072UkwUY+P7kC
50q1J831354BuUaS7unJdW9i3SLVmljnwL75IMESK/Aesbaa/L3LVNjXk20tsfaS69tKAZFxqiPp
dC5sz42/9UR7ZFmAZIHtHVajzf6SNJ+/v+/r/c+VFPP7Rt5jS667ZHtkjbXXOdWSaeu6th1Nsblg
iRX4DLGOSPWZCTaX6rS6TwpK9jS5bhXrtEOqtWQ6V2739o2k1z1lAQDbhLpVqs/C9jOT61qq00qk
ecqNlAdqrhuW6xaxHiHVmixHLq0UOyJYYgXOFWsksT4HpPq/y6Pw/X6uvsu5YKNlgUPqrUcPt4pK
Nd++ZdLs3Z4DCTYi155QyRYYLw2MjgCoSbUm1FcSfVRk+yy8/rPxHqeOXIdlOyrWXlqNSnWuiDN6
nT8uF3ZNsmlQrsQKjIk1Mla11/TvCfV1ey3Xl1QflRSby3UaKAe8JbH2OqlaUl0LM9++Vfbngo3K
NQVLAqQKHJNYR0sALaE+fl9umVTX9dVH4Lv7rNxnqaTXTal1RKzR9VJTRa4lqZbEea/I9VaR661R
EphTfAgWmQLHSXYkra7rqY+GVB8rqa4veUrtCXadfEufY3d6PXKCQGQEwNxIpWup3gdEm8u1JVad
WMDnSLWXVNdi/crk+pXq5b7W+5zSv4drtT7P5tQaFWsvrfbGqvakes+kWts+Qq6JXIHTpTqaVntS
va22X1L9SvWSX0v0z+z+S8V5b5kgUFuOb7QMcA9ebg3J9koCr+2U4nVWcgXGpFoSa2skQF5frTX9
b1lKnQvb0cS63m4l112pNSLWkbSaCkKdGmk1l+dfndu1BNsakrVlTCuxAueJtdZhVRLr1+/v+lf2
fY8ItbUQyxxIrm+feZU2lAJqUl1f/9UQbESu0ToruQL7pVpqdudCK41ZfRTKAD2pzoUSwEi5Ysl8
sKT+SIGwcPeMY42snxqtr+YyzS8jcn29ztRIrSkZdgWcJdWUttVXR6Ta67QaWTHr8NTaE+s0sG9P
p1Uu1b8bgn3dN+/caqXWdXJN6bwhVySMnybLLY/vjQp4VhJrLtav9N++lJJY06BYnysnLBtT62Gl
gOi5q1rDrHqlgLVU/8723QuCzcVaayr0UuvRggX+VBm3SgHPRmJ9VMT6v8uvhlhHZPoS6Xphllyu
0dTaFO3eUQG9tQFqawLkcv2rIde/O6WBdTlg7tRhjGUFzpVrr/PqGRDrqwzwa+WKX4Gk2io13Aoy
bdVady2Cfd9ZBhgZw1qbYVUqBayl+nelPFBLrNEOLGIFzhNr6bqWWPMxq+va6jqtjjT/SwJfe+HZ
8NZSSa+HTxCoybTUnC4N0O9J9d6QakmueWdWLbXWJgqkNL7SFeGCQOP3ia4PkNdXax1WU6f5X5ts
cG/INS8RtDqyhsoBW2depU5ynVN/Guu9UQqoCbbWmdUqB0TWDIhKk1hBrPvFuqR2fbXVWRVt+udl
gJJcS1JNqdyRdeqygT2h9mZctQTbq7PWUmtrJlYpsaa0bW1WAONSTYOJ9SvFRgBEhm6tnfBI8X6X
peO8Q2Ze1aQTrbdGU2trHGueYEc6sI5MrAC2JdaU+jXWl1S/Nkr1kZUUanKdUrzWuon7YJO3NyGg
l1ajgm3VXEujA7aKVWIFzpNqTay1NQLmLFmWap7RckKppHBrPP9UCYundV5FZiRFlg+Mjg5oTWst
jWu9B0sBe07RAuCYckAu13zx6q/U76wqyfSvQjkhd80jlYeBPlN/dEBKA6tgHVVj7Qk1UhIYWT/g
r4PESq7A8VIdEeu6E2m9uEpK/+2dz+uouUzvKba+QKvMMAUS6uFrBdQENDKW9XaAXEuzsKLjWJUB
gOtTa60ZX2r+p0IpoZZU7+nfw7RyqUbWGaiVOE9fj7U3hXUkrc4dud47om2t10qswPcS61quo83/
XKiRpBo5m/NQOo2KtddxFSkNbF2UpXaKlr1iHV2LlWiB8eZ/VK7rc069LqWe/1fz/57aU19rKbV3
PryeYGsnFtydWKOD5kt1iqnxASKjBSInFbwNHsyU4sOsiBU4R6xzIammSgngvpJrPjMrWksdKQNE
XHjoONaeTEcTa6080CoVlEoGEbEaEQC8txSQyzVf6KQk1Xxaai7XmjdaQu11XE0bfPCfRHs/4EBO
weTam+4aleqtU05oLXQ9klgBHCvXXKzPoFgfqXza+9p2ra46WltNaePIgPtBB3LLqle9Dq7IBINe
8o10XBErcG1qfab28KzX3P5b+vc415Jco87oDbmsOeLSc171ygM9oU6DQr0FhNqL/ymdd+YAAP8v
xqmy/ZLU3BDWXJFrywlb6qm9pLqLrcsGRmoSvfNg1RLslPpDJKK/TFvOHABgf+u1JNjSyIBcyOuZ
UL3vefQ6MsRqOtIFezuven+LjnntHZyIPKO1FGUA4P3kU0nz06VEgtSU+mNUIycUTZ2wePhwqz2y
jdQ0IpIclXDvF0piBT4n3c5ZYo1IsXVuvUhCLYW+ltMOP0vrkWm298FGL61foSl4UMkVOJZl4DsV
LRdGmv+R5n3kb5eVAiJnMp2Cop1OutTej7QKXJ9CS5JdUnu6aESEozJtDQU9RahHJtapUwpoCTp6
MEZ+gSLrAUiswHWptTYtdCQ4HdHS3SPSoTO3XlEKqH2okcLx6IDeKbCPUIFzU2svsS4DDmkl0C0t
1JEywTDzRlkenXK3Ti3b08wnVeC6skDv+7s1VI0M+Yy0rD+mFDCSXFMwsabBgxv951rRCji/BFBq
Qo80pSMzJSMiPvo7PXWS9nBiPesXbG9ijZYbpoHHAzgmTPVaq5E0GQllkcTaKwUcyvwhB7uXGCP1
1J5AdVwB7wtQZ6THaD/N5d/z+cCDd9Rjj5AoYQLfS8DTYCmg54e3tk7nDzmoVwmdfIHP/n5HmvLT
Re9j82PnDU90pPEvH7gL4I+R+Nv8MZ/wAUdrH71mwcjrAvhzZDpSUjzrdYqvN1/84gBwpEciIwQi
o4wO9dd81QvtjPQAcIY/jpjuenopQIoF8F2ketprzt/kgBE0gG/D7BAA+EMT7reeeQUAf1QLllgB
gFgBgFgBgFgBAD9LrIZWASBWAACxAvg5fFwrl1gBgFgBgFgBgFgBAMQKAMQKAMQKACBWACBWACBW
AACxAgCxAgCxAgCxAgCIFQCIFQCIFQBArABArABArAAAYgUAYgUAYgUAECsAECsAECsAECsAgFgB
gFgBgFgBAMQKAMQKAMQKACBWACBWACBWAACxAgCxAgCxAgCxAgCIFQCIFQCIFQBArABArABArAAA
YgUAYgUAYgUAECsAECsAECsAECsAgFgBgFgBgFgBAMQKAMQKAMQKACBWACBWACBWACBWAACxAgCx
AgCxAgCIFQCIFQCIFQBArABArABArAAAYgUAYgUAYgUAYgUAECsAECsAECsAgFgBgFgBgFgBAMQK
AMQKAMQKACBWACBWACBWACBWAACxAgCxAgCxAgCIFQCIFQCIFQBArABArABArAAAYgUAYgUAYgUA
YgUAECsAECsAECsAgFgBgFgBgFgBAMQKAMQKAMQKAMQKACBWACBWACBWAACxAgCxAgCxAgCIFQCI
FQCIFQBArABArABArABArAAAYgUAYgUAYgUAECsAECsAECsAgFgBgFgBgFgBAMQKAMQKAMQKAMQK
ACBWACBWACBWAACxAgCxAgCxAgCIFQCIFQCIFQBArAB+AAuxAoDE6tcHACRWACBWACBWAACxAgCx
AgCxAsDJLOkbjByaHUgABP19xEqIAK4U5ce8plIAABwcBufv8gsAgNg6zlg2PPcpLev5hAOy9Y1G
D1C66uAA+HZN/6Xgg+WA5xt6/HzRh6194N4bJ0sAW1u4b/PHvOGNHJkOlzcfFOIGPve7tVSur/LI
5sfOH3jgl50HGcD3b863vvfLp4ek+YSDcrRUl4ZUW7WUpSNmqRW4RpiRMDT6vVwq3/klbevE+hix
jhzkZfA+S9rXCabzC3iPUEeFGOn47oWiSCC79Pt9f+OBb3VmRUoAS+Afut43/b69vv7o5gTwg9Lr
SC/9UvBDzRsjJYKIMz4+sY400yNN/jRwMEeaEgCuKQlEpLc3sS6dFu/IqKTNLdv56CcMptDWARiV
a+m5luA/TgkAuC5U9ZLmMphYl85jUsALrWD31lJAdChE9IP3Dmorsebb02rfVLjfJL0ClyTWSE//
iBtS8LGtsDeSjodau/eDDlw0DS4dKS5B2fYknEs1Z6oIFsDxJYBoEk0dN4x8/3sSHm21DoWve/AJ
p8L2SDNg64Hae6lJVGIFrk+rke/sM9t+Nu73bDx2a2fXIdxPPrBbEmjtYD0rf4+WEQC8V67R7/6z
INiWA55Bl/T6XvZ0bJ0m1t4bTwfItHXg59/XU/ZeXvtGUjeA8xNrLyw9gy7YGrxGSpiXiLVXKx1J
qbksn439z0pTYS3V5+q9zJWyRqtjC8AxQk2NJviW7/lzQLYRwaYU6wi/JLHmNddWYh2Rau8g9y5T
RZBTdilJVbkAeE9iPevSKxOcWi68n3hAl0683yPRx+80Oq9uR8WaJ1RpFThfrK3E2vqeP7Lb+fYj
INhoeaBWCniLWKOdVLVevNLBXB/UR2H/rSDUR+W9zQWpTgX5Ajg/raaKVB+FS0mwj4pUHwG5PlN/
OFY6QrD3gw5e7U21hlDUhPqsyPSxSqqPTJhTRfbzqulfSqw1oRItsN0F0cTaE+v/Ll+Nvz06qXZL
aSDacbXsEWtrsZIpjY9PizTxawf39vt6/n09VcS6/L7verQAsQLvEWvpOppYvyrXLeFGa6/R5BpJ
sEtErL2Vn6aBpLoMHsieVNdynQtCXUt1XYNVZwXeWwZInaCVf+fXl1yuoyk2UhJIne1QUh0tBZRm
X43UVpfUr6OWDuwtu7yS6txo/q+lOhekSqzAtXKNdFy1xFoT7VcnuT4OSKyXThAoDbmq/SqNdFK9
DtS9INZITfX1Wuv7R0sB5Aqcl1hLHVdLwQUvUf5a+eBX5bqXYreWBXZzP/hgRqVa+5W6V9LqXGn+
l2o2c/p3bXWWWIFvK9aWVNe3ex1dR3RkXSLWrTOrInXUXwWhtqR6XyXVW/r3ONdcrhIrcJ1UU4p1
Zpdari9p/vN7ey3XXwXJjibXqEiHZ2VtWd2qJqFoffVZEOotE+tLrjWhltLxPf138sAssQIfk1i3
dF69BPpPdl2Taq/u2pNqZMGW1JPsPSDTmmCnND68qpRSH1lS7Qkxb/4/fn+OR6N0ILEC75NqOkCs
uVRLl69GWWBdcuiNDggn07NKAbXRAa8aZ2Qg8Gv4VE2KpZX/1893X8m1VD44IrGSLhCTzVGJNa+z
rhNrJL2un+eZttVZN3PEGQSmgV+keZVS11KdU7+TKhWe+5El1nljYiVW4HPE+qgk1rVY/2kk1q9K
Ym3VWVOgLBBOsVvHsa6FEykHvKS33v4KJMspINVSrbYm1cjsKxIF9sk2MuuqN471kYn1K5Baf6X9
ddbeGWFDPzL3oExr+2q11udKaqUVqeaCXFtC7Yl1LdRbQNa1MgCpAufJNTJpqDQBoNSJ9auTWh+d
1Do6pfXSUkBLrmvB9uRak15NqH+lf499bY15HU2sBAu8J7E+U3n66q9OWSAfijVSCuhNb90k3Pvg
wWuVA2rJdS4cwFaKbDX/87pqPkNrnVinwnVKxw+5ImH8dFEeIdjWMqKlJQK/Gqn1q1IC6NVZ81EB
e8oAhyfWpdFUn7KUur5uCbU1RbUm1l5a3btWAGEC2wTcOu1JawnRVmqtJdev1O+8Gl0Ae/ePz9Zl
A/Mnr53AL19Z6hGQaqrUZPKk2kqrrTKAUgBwrlx7i7CUThRYS60tuX41BBtd7apVAthcd71vOIhT
ozSQ11Kico1KNTISIDLUqpVWSRU4Tq6RM7S2FmdqybW13Uut0bGrQ6MBRsQamYW1Tq0pO3ipINJH
5/V6Y91KSwr2pNqrsRIrcJ1YR5YUrY0U2DqlNXom101pdUtijaTW2q/TFHjenlRvqTy8KjoawBhW
4Bq5Rs/YXFqkKXJGgch2r8a6fv3oD8ZpYk0dqU6FxJpSf5ZTr8Pqlv69JsCcxmur1goA3iPX3njW
Vq21d8qW2r6R+mo0rZ4286qVWtcvvF4vIJfsqFhvlbQamcJKqsDnyHUktbYS7J7Ts+RJ9fBJAket
FdCK0s/g80QSa2121cikAMOsgHNlOloOiCyI/+zIs7aSVXQ0QOmzbB7PunWCQC7VqfNGnoED35Pq
oyPUaFolVuB8sfYWY0kFyUXO4vxsCDR6GuzRdQIuT6y99Lrefg4k1vVsrfWpVh4BoUZHAjj1NXBN
Yk0pdvLRyOmcamk0eo6r6DoBu2Zf7RnHWkqttXprSa6tZsH61NW3lVhzkfakGkmqRAqcK9rRkkBp
AsFzUKIlmY5MY708sUbk2uKZCa12cOcsrfak2psua4gV8FliTR2xRlLs0tnXW9A6Na43pdWjSwFp
UK5TRbD52QdqQt0r1ahQiRfYJprIqVpSRaxLR7C97YhQT5HqXmm0kl/eBC/dniu358a+EakmYgU+
Tqy9xNoqD/QS7ehprXtSfYtYR+WaAulybtyOpNOjpEqmwDGSHa23po5YI7e3SPWwtHpmKSBaFlgy
IS6ZQNd/fw4ItSdTYgU+R6yjKXarSC+R6lEC6Y0FnQYTbCTZTp1yw57mP6kCx5cGInXNkRQbFWmk
2X+oVI+UyBa5poAgp+B98u2aWMkVuF6qLZFFkmtUtr3Hp4ZcP1KsI3KNCnZkO5pQdVgB7xdr6qTH
Udn2mvqRlHqYVM8QSGQ209TZF023EZmasgp8roBHywOjAu2l5MOFeqZkRuQ6UjKISHkkQQP4HLHu
EW0K7Ds9pV4lmiMEO5pKNf2B71ka2CLZLdunS/UKoUzBv03Bv00Dz7Hl8xEscK5Q9ybYPfc7XahX
i2Qa+Nve23s+G7EC14h1tGm+JeG+RapXi2TLoPw9+0gT+H7yPVK2lwv1nbLZI8Gj0inJAt+nRDAi
17cK9cXtjQf8qI4lkgT+jBLB6HMu7/rQnyKl6YL3TMDA95Ps0an3jxLrEe+HOIE/U7wfIdPvJKPJ
sQF+pAh/wuv/GHmQHUDiROVzAPipAiUkxwMgRAAAAAAAgOP5PwEGABxYNZkKWyyNAAAAAElFTkSu
QmCC' transform='matrix(1 0 0 1 95 14)'>
     </image>
      <g>
        <path fill='#FFFFFF' d='M146.29,281.24c-9.73,0-17.64-7.91-17.64-17.64V54.93c0-9.73,7.91-17.64,17.64-17.64h238.96
           c9.73,0,17.64,7.91,17.64,17.64V263.6c0,9.73-7.91,17.64-17.64,17.64H146.29z' />
        <path fill='#DEE3E9' d='M385.25,37.65c9.53,0,17.28,7.75,17.28,17.28V263.6c0,9.53-7.75,17.28-17.28,17.28H146.29
           c-9.53,0-17.28-7.75-17.28-17.28V54.93c0-9.53,7.75-17.28,17.28-17.28H385.25 M385.25,36.93H146.29c-9.94,0-18,8.06-18,18V263.6
           c0,9.94,8.06,18,18,18h238.96c9.94,0,18-8.06,18-18V54.93C403.25,44.99,395.19,36.93,385.25,36.93L385.25,36.93z' />
     </g>
  </g>
    <path fill='#F4F6F8' d='M174.85,91.72H158.3c-5.52,0-10-4.48-10-10V65.18c0-5.52,4.48-10,10-10h16.54c5.52,0,10,4.48,10,10v16.54
     C184.85,87.25,180.37,91.72,174.85,91.72z' />
    <path fill='#F4F6F8' d='M217.47,91.66h-16.54c-5.52,0-10-4.48-10-10V65.12c0-5.52,4.48-10,10-10h16.54c5.52,0,10,4.48,10,10v16.54
     C227.47,87.18,222.99,91.66,217.47,91.66z' />
    <g>
     <path fill='#ECEEF0' d='M164.17,138c-7.84,0-14.23-6.38-14.23-14.23v-2.12c0-7.84,6.38-14.23,14.23-14.23h203.2
        c7.84,0,14.23,6.38,14.23,14.23v2.12c0,7.84-6.38,14.23-14.23,14.23H164.17z' />
      <path fill='#DEE3E9' d='M367.37,107.79c7.65,0,13.87,6.22,13.87,13.87v2.12c0,7.65-6.22,13.87-13.87,13.87h-203.2
        c-7.65,0-13.87-6.22-13.87-13.87v-2.12c0-7.65,6.22-13.87,13.87-13.87H367.37 M367.37,107.07h-203.2
        c-8.06,0-14.59,6.53-14.59,14.59v2.12c0,8.06,6.53,14.59,14.59,14.59h203.2c8.06,0,14.59-6.53,14.59-14.59v-2.12
        C381.96,113.6,375.43,107.07,367.37,107.07L367.37,107.07z' />
  </g>
    <g>
     <path fill='#ECEEF0' d='M369.96,216.14H161.58c-6.63,0-12-5.37-12-12v-48.52c0-6.63,5.37-12,12-12h208.37c6.63,0,12,5.37,12,12
        v48.52C381.96,210.77,376.58,216.14,369.96,216.14z' />
      <path fill='#DEE3E9' d='M369.96,143.62h-6v0.72h6V143.62L369.96,143.62L369.96,143.62z M357.96,143.62h-6v0.72h6V143.62
        L357.96,143.62z M345.96,143.62h-6v0.72h6V143.62L345.96,143.62z M333.96,143.62h-6v0.72h6V143.62L333.96,143.62z M321.96,143.62
        h-6v0.72h6V143.62L321.96,143.62z M309.96,143.62h-6v0.72h6V143.62L309.96,143.62z M297.96,143.62h-6v0.72h6V143.62
        L297.96,143.62z M285.96,143.62h-6v0.72h6V143.62L285.96,143.62z M273.96,143.62h-6v0.72h6V143.62L273.96,143.62z M261.96,143.62
        h-6v0.72h6V143.62L261.96,143.62z M249.96,143.62h-6v0.72h6V143.62L249.96,143.62z M237.96,143.62h-6v0.72h6V143.62
        L237.96,143.62z M225.96,143.62h-6v0.72h6V143.62L225.96,143.62z M213.96,143.62h-6v0.72h6V143.62L213.96,143.62z M201.96,143.62
        h-6v0.72h6V143.62L201.96,143.62z M189.96,143.62h-6v0.72h6V143.62L189.96,143.62z M177.96,143.62h-6v0.72h6V143.62
        L177.96,143.62z M165.96,143.62h-4.37c-0.01,0-0.01,0-0.02,0c0,0,0,0,0,0c-0.01,0-0.01,0-0.02,0c0,0,0,0,0,0
        c-0.01,0-0.01,0-0.02,0c0,0,0,0,0,0c-0.01,0-0.01,0-0.02,0c0,0,0,0,0,0c-0.01,0-0.01,0-0.02,0c0,0,0,0,0,0c-0.01,0-0.01,0-0.02,0
        c0,0,0,0,0,0c-0.01,0-0.01,0-0.02,0c0,0,0,0,0,0c-0.01,0-0.01,0-0.02,0c0,0,0,0,0,0c-0.01,0-0.01,0-0.02,0c0,0,0,0,0,0
        c-0.01,0-0.01,0-0.02,0c0,0,0,0,0,0c-0.01,0-0.01,0-0.02,0c0,0,0,0,0,0c-0.01,0-0.02,0-0.04,0c0,0,0,0,0,0c-0.01,0-0.01,0-0.02,0
        c0,0,0,0,0,0c-0.01,0-0.01,0-0.02,0c0,0,0,0,0,0c-0.01,0-0.02,0-0.04,0c0,0,0,0,0,0c-0.01,0-0.03,0-0.04,0c0,0,0,0,0,0
        c-0.29,0.01-0.57,0.03-0.86,0.06c0,0,0,0,0,0c-0.01,0-0.02,0-0.04,0c0,0,0,0,0,0c-0.01,0-0.02,0-0.03,0c0,0,0,0,0,0
        c-0.01,0-0.01,0-0.02,0c0,0,0,0,0,0c-0.01,0-0.01,0-0.02,0c0,0,0,0-0.01,0c0,0-0.01,0-0.01,0c0,0,0,0-0.01,0c0,0-0.01,0-0.01,0
        c0,0,0,0-0.01,0c0,0-0.01,0-0.01,0c0,0-0.01,0-0.01,0c0,0-0.01,0-0.01,0c0,0-0.01,0-0.01,0c0,0,0,0-0.01,0c-0.01,0-0.01,0-0.02,0
        c0,0,0,0,0,0c-0.06,0.01-0.12,0.01-0.17,0.02l0.1,0.71c0.5-0.07,1.01-0.1,1.52-0.1h4.37V143.62L165.96,143.62z M154.46,145.96
        c-1.47,1.08-2.68,2.49-3.52,4.12l0,0c-0.01,0.02-0.02,0.03-0.03,0.05v0c-0.01,0.01-0.01,0.02-0.02,0.03c0,0,0,0,0,0
        c-0.01,0.01-0.01,0.02-0.02,0.03c0,0,0,0,0,0c-0.01,0.01-0.01,0.02-0.02,0.03c0,0,0,0,0,0c-0.01,0.01-0.01,0.02-0.02,0.03
        c0,0,0,0,0,0c0,0.01-0.01,0.01-0.01,0.02c0,0,0,0,0,0c0,0,0,0.01-0.01,0.01c0,0,0,0,0,0c0,0.01,0,0.01-0.01,0.01c0,0,0,0,0,0
        c0,0,0,0.01-0.01,0.01c0,0,0,0,0,0c0,0,0,0.01-0.01,0.01c0,0,0,0,0,0c0,0,0,0.01-0.01,0.01c0,0,0,0,0,0c0,0,0,0.01-0.01,0.01
        c0,0,0,0,0,0c0,0,0,0.01-0.01,0.01c0,0,0,0,0,0c0,0,0,0.01-0.01,0.01c0,0,0,0,0,0c0,0,0,0.01-0.01,0.01c0,0,0,0,0,0
        c0,0,0,0.01-0.01,0.01c0,0,0,0,0,0c0,0,0,0.01-0.01,0.01c0,0,0,0,0,0c0,0,0,0.01-0.01,0.01c0,0,0,0,0,0c0,0,0,0.01-0.01,0.01
        c0,0,0,0,0,0c0,0,0,0.01-0.01,0.01c0,0,0,0,0,0c0,0,0,0.01-0.01,0.01c0,0,0,0,0,0c0,0,0,0.01,0,0.01c0,0,0,0,0,0
        c0,0,0,0.01-0.01,0.01c0,0,0,0,0,0.01c0,0,0,0,0,0l0.65,0.3c0.79-1.71,2.02-3.2,3.54-4.32L154.46,145.96L154.46,145.96z
         M150.3,156.39h-0.72v6h0.72V156.39L150.3,156.39z M150.3,168.39h-0.72v6h0.72V168.39L150.3,168.39z M150.3,180.39h-0.72v6h0.72
        V180.39L150.3,180.39z M150.3,192.39h-0.72v6h0.72V192.39L150.3,192.39z M150.3,204.38l-0.72,0.01c0.01,0.54,0.06,1.08,0.14,1.6
        c0,0,0,0,0,0c0,0.01,0,0.02,0.01,0.03c0,0,0,0,0,0c0,0.01,0,0.01,0,0.02c0,0,0,0,0,0c0,0,0,0.01,0,0.01c0,0,0,0,0,0
        c0,0.01,0,0.01,0,0.02c0,0,0,0,0,0c0,0.01,0,0.02,0.01,0.03c0,0,0,0,0,0c0,0.01,0,0.01,0,0.02c0,0,0,0,0,0c0,0,0,0.01,0,0.01
        c0,0,0,0,0,0.01c0,0.01,0,0.01,0,0.02c0,0,0,0,0,0.01c0,0,0,0.01,0,0.01c0,0,0,0.01,0,0.01c0,0,0,0.01,0,0.01c0,0,0,0,0,0.01
        c0,0,0,0.01,0,0.01c0,0,0,0.01,0,0.01c0,0,0,0.01,0,0.01c0,0,0,0.01,0,0.01c0,0,0,0.01,0,0.01c0,0,0,0.01,0,0.01
        c0,0,0,0.01,0,0.01c0,0,0,0.01,0,0.01c0,0,0,0.01,0,0.01c0,0,0,0.01,0,0.01c0,0,0,0,0,0.01c0,0,0,0.01,0,0.01c0,0,0,0,0,0.01
        c0,0,0,0.01,0,0.01c0,0,0,0,0,0.01c0,0,0,0.01,0,0.01c0,0,0,0,0,0.01c0,0,0,0.01,0,0.01c0,0,0,0,0,0c0,0,0,0.01,0,0.01
        c0,0,0,0,0,0.01c0,0,0,0.01,0,0.01c0,0,0,0,0,0.01c0,0,0,0.01,0,0.01c0,0,0,0,0,0c0,0,0,0.01,0,0.01c0,0,0,0,0,0
        c0,0,0,0.01,0,0.01c0,0,0,0,0,0c0,0,0,0.01,0,0.02c0,0,0,0,0,0c0,0,0,0.01,0,0.02c0,0,0,0,0,0c0,0,0,0.01,0,0.01c0,0,0,0,0,0
        c0,0.01,0,0.01,0,0.02c0,0,0,0,0,0c0,0,0,0.01,0,0.02c0,0,0,0,0,0c0,0.01,0,0.01,0,0.02c0,0,0,0,0,0c0,0.01,0,0.01,0,0.02
        c0,0,0,0,0,0c0,0.01,0,0.01,0,0.02c0,0,0,0,0,0c0,0.01,0,0.01,0,0.02c0,0,0,0,0,0c0,0.01,0.01,0.02,0.01,0.04c0,0,0,0,0,0
        c0,0.01,0.01,0.02,0.01,0.04c0,0,0,0,0,0c0.27,1.21,0.71,2.35,1.31,3.39l0.62-0.36C150.86,208.13,150.34,206.27,150.3,204.38
        L150.3,204.38z M155.69,213.76l-0.38,0.61c0.63,0.39,1.3,0.72,2.01,0.99c0,0,0,0,0,0c0.02,0.01,0.03,0.01,0.05,0.02c0,0,0,0,0,0
        c0.01,0,0.02,0.01,0.03,0.01c0,0,0,0,0,0c0.01,0,0.02,0.01,0.03,0.01c0,0,0,0,0,0c0.01,0,0.01,0,0.02,0.01c0,0,0,0,0,0
        c0.01,0,0.02,0.01,0.03,0.01c0,0,0,0,0,0c0.01,0,0.02,0.01,0.03,0.01c0,0,0,0,0,0c0.01,0,0.01,0,0.02,0.01c0,0,0,0,0,0
        c0.01,0,0.01,0,0.02,0.01c0,0,0,0,0,0c0.01,0,0.01,0,0.02,0.01c0,0,0,0,0,0c0.01,0,0.02,0.01,0.03,0.01c0,0,0,0,0,0
        c0.01,0,0.02,0.01,0.03,0.01c0,0,0,0,0,0c0.01,0,0.01,0,0.02,0.01c0,0,0,0,0,0c0.01,0,0.01,0,0.02,0.01c0,0,0,0,0,0
        c0.01,0,0.01,0,0.02,0.01c0,0,0,0,0,0c0.01,0,0.02,0.01,0.03,0.01c0,0,0,0,0,0c0.01,0,0.01,0,0.02,0.01c0,0,0,0,0,0
        c0.01,0,0.01,0,0.01,0.01c0,0,0,0,0,0c0.01,0,0.01,0,0.02,0.01c0,0,0,0,0,0c0.01,0,0.01,0,0.02,0.01c0,0,0,0,0,0
        c0.01,0,0.01,0,0.02,0.01c0,0,0,0,0,0c0.01,0,0.01,0,0.02,0.01c0,0,0,0,0,0c0.01,0,0.01,0,0.02,0.01c0,0,0,0,0,0
        c0.01,0,0.01,0,0.02,0.01c0,0,0,0,0,0c0,0,0.01,0,0.01,0c0,0,0,0,0,0c0.01,0,0.01,0,0.02,0c0,0,0,0,0,0c0.01,0,0.01,0,0.02,0
        c0,0,0,0,0,0c0.01,0,0.01,0,0.02,0c0,0,0,0,0,0c0,0,0.01,0,0.02,0c0,0,0,0,0,0c0,0,0.01,0,0.01,0c0,0,0,0,0,0
        c0.01,0,0.01,0,0.02,0c0,0,0,0,0,0c0,0,0.01,0,0.01,0c0,0,0,0,0,0c0,0,0.01,0,0.01,0c0,0,0,0,0,0c0,0,0.01,0,0.01,0c0,0,0,0,0,0
        c0,0,0.01,0,0.01,0c0,0,0,0,0,0c0,0,0.01,0,0.01,0c0,0,0,0,0,0c0,0,0.01,0,0.01,0c0,0,0,0,0,0c0,0,0.01,0,0.01,0c0,0,0,0,0,0
        c0,0,0.01,0,0.01,0c0,0,0,0,0,0c0,0,0.01,0,0.01,0c0,0,0,0,0,0c0.01,0,0.01,0,0.01,0c0,0,0,0,0,0c0,0,0.01,0,0.01,0c0,0,0,0,0,0
        c0,0,0.01,0,0.01,0c0,0,0,0,0,0c0,0,0.01,0,0.01,0c0,0,0,0,0,0c0,0,0.01,0,0.01,0c0,0,0,0,0.01,0c0,0,0.01,0,0.01,0c0,0,0,0,0,0
        c0,0,0.01,0,0.01,0c0,0,0,0,0.01,0c0,0,0.01,0,0.01,0c0,0,0,0,0,0c0,0,0.01,0,0.01,0c0,0,0,0,0,0c0,0,0.01,0,0.01,0c0,0,0,0,0,0
        c0,0,0.01,0,0.01,0c0,0,0,0,0,0c0,0,0.01,0,0.01,0c0,0,0,0,0.01,0c0,0,0.01,0,0.01,0c0,0,0,0,0,0c0,0,0.01,0,0.01,0
        c0,0,0,0,0.01,0c0,0,0.01,0,0.01,0c0,0,0,0,0.01,0c0,0,0.01,0,0.01,0c0,0,0,0,0.01,0c0,0,0.01,0,0.01,0c0,0,0,0,0.01,0
        c0,0,0.01,0,0.01,0c0,0,0,0,0.01,0c0,0,0.01,0,0.01,0c0,0,0,0,0.01,0c0,0,0.01,0,0.01,0c0,0,0,0,0.01,0c0,0,0.01,0,0.01,0
        c0,0,0,0,0.01,0c0,0,0.01,0,0.01,0c0,0,0,0,0.01,0c0,0,0.01,0,0.01,0c0,0,0,0,0.01,0c0,0,0.01,0,0.01,0c0,0,0,0,0.01,0
        c0,0,0.01,0,0.01,0s0.01,0,0.01,0c0,0,0.01,0,0.01,0c0,0,0,0,0.01,0c0,0,0.01,0,0.01,0c0,0,0,0,0.01,0c0,0,0.01,0,0.01,0
        c0,0,0,0,0.01,0c0,0,0.01,0,0.01,0c0,0,0,0,0.01,0c0,0,0.01,0,0.01,0c0,0,0,0,0.01,0c0,0,0.01,0,0.01,0c0,0,0,0,0.01,0
        c0,0,0.01,0,0.01,0c0,0,0.01,0,0.01,0c0,0,0.01,0,0.01,0c0,0,0,0,0.01,0c0,0,0.01,0,0.01,0c0,0,0,0,0.01,0c0,0,0.01,0,0.01,0
        c0,0,0,0,0.01,0c0,0,0.01,0,0.01,0c0,0,0,0,0.01,0c0,0,0.01,0,0.01,0c0,0,0.01,0,0.01,0c0,0,0.01,0,0.01,0c0,0,0,0,0.01,0
        c0,0,0.01,0,0.01,0c0,0,0.01,0,0.01,0c0,0,0.01,0,0.01,0c0,0,0,0,0.01,0c0,0,0.01,0,0.01,0c0,0,0,0,0.01,0c0,0,0.01,0,0.01,0
        c0,0,0,0,0.01,0c0,0,0.01,0,0.01,0c0,0,0,0,0.01,0c0,0,0.01,0,0.01,0c0,0,0.01,0,0.01,0c0,0,0.01,0,0.01,0c0,0,0,0,0.01,0
        c0,0,0.01,0,0.01,0c0,0,0.01,0,0.01,0c0,0,0.01,0,0.01,0c0,0,0,0,0.01,0c0,0,0.01,0,0.01,0c0,0,0.01,0,0.01,0c0,0,0.01,0,0.01,0
        c0,0,0.01,0,0.01,0c0,0,0.01,0,0.01,0c0,0,0.01,0,0.01,0c0,0,0.01,0,0.01,0c0,0,0.01,0,0.01,0c0,0,0.01,0,0.01,0c0,0,0,0,0.01,0
        c0,0,0.01,0,0.01,0c0,0,0.01,0,0.01,0c0,0,0.01,0,0.01,0c0,0,0.01,0,0.01,0c0,0,0.01,0,0.01,0c0,0,0.01,0,0.01,0c0,0,0,0,0.01,0
        c0,0,0.01,0,0.01,0c0,0,0,0,0.01,0c0,0,0.01,0,0.01,0c0,0,0.01,0,0.01,0c0,0,0.01,0,0.01,0c0,0,0.01,0,0.01,0c0,0,0.01,0,0.01,0
        c0,0,0.01,0,0.01,0c0,0,0.01,0,0.01,0c0,0,0.01,0,0.01,0c0,0,0.01,0,0.01,0c0,0,0.01,0,0.01,0c0,0,0.01,0,0.01,0
        c0,0,0.01,0,0.01,0c0,0,0.01,0,0.01,0c0,0,0.01,0,0.01,0c0,0,0.01,0,0.01,0c0,0,0.01,0,0.01,0c0,0,0.01,0,0.01,0
        c0,0,0.01,0,0.01,0c0,0,0.01,0,0.01,0c0,0,0.01,0,0.01,0c0,0,0,0,0.01,0c0,0,0.01,0,0.01,0c0,0,0.01,0,0.01,0c0,0,0.01,0,0.01,0
        c0,0,0.01,0,0.01,0c0,0,0.01,0,0.01,0c0,0,0.01,0,0.01,0c0,0,0.01,0,0.01,0c0,0,0.01,0,0.01,0c0,0,0.01,0,0.01,0
        c0,0,0.01,0,0.01,0c0,0,0.01,0,0.01,0c0,0,0.01,0,0.01,0c0,0,0.01,0,0.01,0c0,0,0.01,0,0.01,0c0,0,0.01,0,0.01,0
        c0,0,0.01,0,0.01,0c0,0,0.01,0,0.01,0c0,0,0.01,0,0.01,0c0,0,0.01,0,0.01,0c0,0,0.01,0,0.01,0c0,0,0.01,0,0.01,0
        c0,0,0.01,0,0.01,0c0,0,0.01,0,0.01,0c0,0,0.01,0,0.01,0c0,0,0,0,0.01,0c0,0,0.01,0,0.01,0c0,0,0.01,0,0.01,0c0,0,0.01,0,0.01,0
        c0,0,0.01,0,0.01,0c0,0,0.01,0,0.01,0c0,0,0.01,0,0.01,0c0,0,0.01,0,0.01,0c0,0,0.01,0,0.01,0c0,0,0.01,0,0.01,0
        c0,0,0.01,0,0.01,0c0,0,0.01,0,0.01,0c0,0,0.01,0,0.01,0c0,0,0.01,0,0.01,0c0,0,0.01,0,0.01,0c0,0,0.01,0,0.01,0
        c0,0,0.01,0,0.01,0c0,0,0.01,0,0.01,0c0,0,0.01,0,0.01,0c0,0,0.01,0,0.01,0c0,0,0.01,0,0.01,0c0,0,0.01,0,0.01,0
        c0,0,0.01,0,0.01,0c0,0,0.01,0,0.01,0c0,0,0.01,0,0.01,0c0,0,0.01,0,0.01,0c0,0,0.01,0,0.01,0c0,0,0.01,0,0.01,0c0,0,0,0,0.01,0
        c0,0,0.01,0,0.01,0c0,0,0.01,0,0.01,0c0,0,0.01,0,0.01,0c0,0,0.01,0,0.01,0c0,0,0.01,0,0.01,0c0,0,0,0,0.01,0c0,0,0.01,0,0.01,0
        c0,0,0.01,0,0.01,0c0,0,0.01,0,0.01,0c0,0,0.01,0,0.01,0c0,0,0.01,0,0.01,0c0,0,0.01,0,0.01,0c0,0,0.01,0,0.01,0
        c0,0,0.01,0,0.01,0c0,0,0.01,0,0.01,0c0,0,0,0,0.01,0c0,0,0.01,0,0.01,0c0,0,0.01,0,0.01,0c0,0,0.01,0,0.01,0c0,0,0,0,0.01,0
        c0,0,0.01,0,0.01,0c0,0,0.01,0,0.01,0c0,0,0.01,0,0.01,0s0,0,0.01,0c0,0,0.01,0,0.01,0c0,0,0.01,0,0.01,0c0,0,0.01,0,0.01,0
        c0,0,0.01,0,0.01,0c0,0,0.01,0,0.01,0c0,0,0.01,0,0.01,0c0,0,0.01,0,0.01,0c0,0,0.01,0,0.01,0c0,0,0.01,0,0.01,0
        c0,0,0.01,0,0.01,0c0,0,0.01,0,0.01,0c0,0,0.01,0,0.01,0c0,0,0.01,0,0.01,0c0,0,0.01,0,0.01,0c0,0,0.01,0,0.01,0
        c0,0,0.01,0,0.01,0c0,0,0.01,0,0.01,0c0,0,0.01,0,0.01,0c0,0,0.01,0,0.01,0c0,0,0,0,0.01,0c0,0,0.01,0,0.01,0c0,0,0.01,0,0.01,0
        c0,0,0.01,0,0.01,0c0,0,0,0,0.01,0c0,0,0.01,0,0.01,0c0,0,0,0,0.01,0c0,0,0.01,0,0.01,0c0,0,0,0,0.01,0c0,0,0.01,0,0.01,0
        c0,0,0,0,0.01,0c0,0,0.01,0,0.01,0c0,0,0.01,0,0.01,0c0,0,0.01,0,0.01,0c0,0,0.01,0,0.01,0c0,0,0.01,0,0.01,0c0,0,0.01,0,0.01,0
        c0,0,0.01,0,0.01,0c0,0,0,0,0.01,0c0,0,0.01,0,0.01,0c0,0,0.01,0,0.01,0c0,0,0.01,0,0.01,0c0,0,0.01,0,0.01,0c0,0,0.01,0,0.01,0
        c0,0,0.01,0,0.01,0s0.01,0,0.01,0c0,0,0.01,0,0.01,0c0,0,0.01,0,0.01,0c0,0,0.01,0,0.01,0c0,0,0.01,0,0.01,0c0,0,0.01,0,0.01,0
        c0,0,0.01,0,0.01,0c0,0,0.01,0,0.01,0c0,0,0.01,0,0.01,0c0,0,0.01,0,0.01,0c0,0,0.01,0,0.01,0c0,0,0.01,0,0.01,0
        c0,0,0.01,0,0.01,0c0,0,0.01,0,0.01,0c0,0,0.01,0,0.01,0c0,0,0.01,0,0.01,0c0,0,0.01,0,0.01,0c0,0,0.01,0,0.01,0
        c0,0,0.01,0,0.01,0c0,0,0.01,0,0.01,0c0,0,0.01,0,0.01,0c0,0,0.01,0,0.01,0c0,0,0.01,0,0.01,0c0,0,0.01,0,0.01,0
        c0,0,0.01,0,0.01,0c0,0,0.01,0,0.01,0c0,0,0.01,0,0.01,0c0,0,0.01,0,0.01,0c0,0,0.01,0,0.01,0c0,0,0.01,0,0.01,0
        c0,0,0.01,0,0.01,0c0,0,0.01,0,0.01,0c0,0,0.01,0,0.01,0c0,0,0.01,0,0.01,0c0,0,0.01,0,0.01,0c0,0,0.01,0,0.01,0
        c0,0,0.01,0,0.01,0c0,0,0.01,0,0.01,0c0,0,0.01,0,0.01,0c0,0,0.01,0,0.01,0c0,0,0.01,0,0.01,0c0,0,0.01,0,0.01,0
        c0,0,0.01,0,0.01,0c0,0,0.01,0,0.01,0c0,0,0.01,0,0.01,0c0,0,0.01,0,0.01,0c0,0,0.01,0,0.01,0c0,0,0.01,0,0.01,0
        c0,0,0.01,0,0.01,0c0,0,0.01,0,0.01,0c0,0,0,0,0,0l0.04-0.72C159.13,215.32,157.29,214.75,155.69,213.76L155.69,213.76z
         M172.98,215.42h-6v0.72h6V215.42L172.98,215.42z M184.98,215.42h-6v0.72h6V215.42L184.98,215.42z M196.98,215.42h-6v0.72h6
        V215.42L196.98,215.42z M208.98,215.42h-6v0.72h6V215.42L208.98,215.42z M220.98,215.42h-6v0.72h6V215.42L220.98,215.42z
         M232.98,215.42h-6v0.72h6V215.42L232.98,215.42z M244.98,215.42h-6v0.72h6V215.42L244.98,215.42z M256.98,215.42h-6v0.72h6
        V215.42L256.98,215.42z M268.98,215.42h-6v0.72h6V215.42L268.98,215.42z M280.98,215.42h-6v0.72h6V215.42L280.98,215.42z
         M292.98,215.42h-6v0.72h6V215.42L292.98,215.42z M304.98,215.42h-6v0.72h6V215.42L304.98,215.42z M316.98,215.42h-6v0.72h6
        V215.42L316.98,215.42z M328.98,215.42h-6v0.72h6V215.42L328.98,215.42z M340.98,215.42h-6v0.72h6V215.42L340.98,215.42z
         M352.98,215.42h-6v0.72h6V215.42L352.98,215.42z M364.98,215.42h-6v0.72h6V215.42L364.98,215.42z M376.19,213.55
        c-1.57,1.04-3.39,1.68-5.27,1.84l0.06,0.72c0.01,0,0.02,0,0.02,0c0,0,0,0,0,0c0.01,0,0.02,0,0.04,0c0,0,0,0,0,0
        c0.01,0,0.02,0,0.04,0l0,0c0.06-0.01,0.13-0.01,0.19-0.02h0c1.47-0.16,2.86-0.59,4.13-1.23l0,0c0.03-0.01,0.05-0.03,0.08-0.04
        c0,0,0,0,0,0c0.03-0.01,0.05-0.03,0.08-0.04c0,0,0,0,0,0c0.01-0.01,0.02-0.01,0.03-0.02c0,0,0,0,0,0
        c0.01-0.01,0.02-0.01,0.03-0.02c0,0,0,0,0,0c0.01,0,0.01-0.01,0.02-0.01c0,0,0,0,0,0c0,0,0.01,0,0.01-0.01c0,0,0,0,0,0
        c0.01,0,0.01-0.01,0.02-0.01c0,0,0,0,0,0c0.01-0.01,0.02-0.01,0.03-0.02c0,0,0,0,0,0c0.01-0.01,0.02-0.01,0.03-0.02c0,0,0,0,0,0
        c0.01,0,0.01-0.01,0.02-0.01c0,0,0,0,0,0c0,0,0.01,0,0.01,0c0,0,0,0,0,0c0,0,0.01-0.01,0.01-0.01c0,0,0,0,0,0c0,0,0.01,0,0.01,0
        c0,0,0,0,0,0c0,0,0.01-0.01,0.01-0.01c0,0,0,0,0,0c0,0,0.01,0,0.01-0.01c0,0,0,0,0.01,0c0,0,0,0,0.01,0c0,0,0,0,0.01,0
        c0,0,0.01,0,0.01-0.01c0,0,0,0,0.01,0c0,0,0,0,0.01,0c0,0,0.01,0,0.01,0c0,0,0.01,0,0.01-0.01c0,0,0,0,0.01,0c0,0,0,0,0.01,0
        c0,0,0.01,0,0.01-0.01c0,0,0,0,0.01,0c0,0,0,0,0.01,0c0,0,0,0,0.01,0c0,0,0.01,0,0.01-0.01c0,0,0,0,0.01,0c0,0,0.01,0,0.01,0
        c0,0,0,0,0.01,0c0,0,0.01,0,0.01-0.01c0,0,0,0,0.01,0c0,0,0.01,0,0.01-0.01c0,0,0,0,0.01,0c0,0,0.01,0,0.01,0c0,0,0,0,0.01,0
        c0,0,0.01,0,0.01-0.01c0,0,0,0,0.01,0c0,0,0.01,0,0.01-0.01c0,0,0,0,0,0c0,0,0.01,0,0.01-0.01c0,0,0,0,0.01,0
        c0,0,0.01,0,0.01-0.01c0,0,0,0,0,0c0,0,0.01,0,0.01-0.01c0,0,0,0,0,0c0,0,0.01-0.01,0.01-0.01c0,0,0,0,0,0c0,0,0.01,0,0.01-0.01
        c0,0,0,0,0,0c0,0,0.01-0.01,0.01-0.01c0,0,0,0,0,0c0,0,0.01,0,0.01-0.01c0,0,0,0,0,0c0,0,0.01-0.01,0.01-0.01c0,0,0,0,0,0
        c0,0,0.01,0,0.01-0.01c0,0,0,0,0,0c0,0,0.01-0.01,0.01-0.01c0,0,0,0,0,0c0,0,0.01-0.01,0.01-0.01c0,0,0,0,0,0
        c0,0,0.01,0,0.01-0.01c0,0,0,0,0,0c0,0,0.01-0.01,0.01-0.01c0,0,0,0,0,0c0,0,0.01-0.01,0.01-0.01c0,0,0,0,0,0
        c0,0,0.01-0.01,0.01-0.01c0,0,0,0,0,0c0.01-0.01,0.02-0.01,0.03-0.02c0,0,0,0,0,0c0.01-0.01,0.02-0.01,0.03-0.02c0,0,0,0,0,0
        c0,0,0.01-0.01,0.01-0.01c0,0,0,0,0,0c0,0,0.01-0.01,0.01-0.01c0,0,0,0,0,0c0.01,0,0.01-0.01,0.01-0.01c0,0,0,0,0,0
        c0.01-0.01,0.02-0.01,0.03-0.02c0,0,0,0,0,0c0.06-0.04,0.12-0.08,0.18-0.12L376.19,213.55L376.19,213.55z M381.96,203.97h-0.72
        v0.17c0,1.83-0.45,3.65-1.3,5.26l0.64,0.34c0.7-1.32,1.16-2.8,1.32-4.35c0,0,0,0,0,0c0-0.02,0-0.04,0.01-0.06c0,0,0,0,0,0
        c0-0.01,0-0.02,0-0.04c0,0,0,0,0,0c0-0.01,0-0.01,0-0.02c0,0,0,0,0,0c0-0.01,0-0.02,0-0.04c0,0,0,0,0,0c0-0.01,0-0.01,0-0.02
        c0,0,0,0,0,0c0-0.01,0-0.02,0-0.04c0,0,0,0,0,0c0-0.01,0-0.01,0-0.02v0c0-0.01,0-0.01,0-0.02c0,0,0,0,0,0c0-0.01,0-0.01,0-0.02
        c0,0,0,0,0,0c0-0.01,0-0.02,0-0.04c0,0,0,0,0,0c0-0.01,0-0.01,0-0.02c0,0,0,0,0,0c0-0.01,0-0.02,0-0.04c0,0,0,0,0,0
        c0-0.01,0-0.01,0-0.02c0,0,0,0,0,0c0-0.01,0-0.02,0-0.04c0,0,0,0,0,0c0-0.01,0-0.01,0-0.02c0,0,0,0,0,0c0-0.01,0-0.01,0-0.02
        c0,0,0,0,0,0c0-0.01,0-0.01,0-0.02c0,0,0,0,0,0c0-0.01,0-0.02,0-0.04c0,0,0,0,0,0c0-0.01,0-0.01,0-0.02c0,0,0,0,0,0
        c0-0.01,0-0.02,0-0.04c0,0,0,0,0,0c0-0.01,0-0.01,0-0.02c0,0,0,0,0,0c0-0.01,0-0.01,0-0.02l0,0c0-0.01,0-0.01,0-0.02c0,0,0,0,0,0
        c0-0.01,0-0.01,0-0.02c0,0,0,0,0,0c0-0.01,0-0.01,0-0.02c0,0,0,0,0,0c0-0.01,0-0.01,0-0.02c0,0,0,0,0,0c0-0.01,0-0.01,0-0.02
        c0,0,0,0,0,0c0-0.01,0-0.01,0-0.02c0,0,0,0,0,0c0-0.01,0-0.01,0-0.02c0,0,0,0,0,0c0-0.01,0-0.01,0-0.02c0,0,0,0,0,0
        c0-0.01,0-0.01,0-0.02c0,0,0,0,0,0c0-0.01,0-0.01,0-0.02c0,0,0,0,0,0c0-0.01,0-0.02,0-0.04c0,0,0,0,0,0c0-0.01,0-0.01,0-0.02
        c0,0,0,0,0,0c0-0.01,0-0.01,0-0.02c0,0,0,0,0,0c0-0.01,0-0.01,0-0.02c0,0,0,0,0,0c0-0.01,0-0.01,0-0.02c0,0,0,0,0,0
        c0-0.01,0-0.01,0-0.02c0,0,0,0,0,0c0-0.01,0-0.01,0-0.02c0,0,0,0,0,0c0-0.01,0-0.01,0-0.02c0,0,0,0,0,0c0-0.01,0-0.01,0-0.02
        c0,0,0,0,0,0c0-0.01,0-0.01,0-0.02c0,0,0,0,0,0c0-0.01,0-0.01,0-0.02l0,0c0-0.01,0-0.01,0-0.02c0,0,0,0,0,0c0-0.01,0-0.01,0-0.02
        c0,0,0,0,0,0c0-0.01,0-0.01,0-0.02c0,0,0,0,0,0c0-0.01,0-0.01,0-0.02c0,0,0,0,0,0c0-0.01,0-0.01,0-0.02c0,0,0,0,0,0
        c0-0.01,0-0.01,0-0.02c0,0,0,0,0,0c0-0.01,0-0.01,0-0.02c0,0,0,0,0,0c0-0.01,0-0.01,0-0.02c0,0,0,0,0,0c0-0.01,0-0.01,0-0.02
        c0,0,0,0,0,0c0-0.01,0-0.01,0-0.02c0,0,0,0,0,0c0-0.01,0-0.01,0-0.02l0,0V203.97L381.96,203.97z M381.96,191.97h-0.72v6h0.72
        V191.97L381.96,191.97z M381.96,179.97h-0.72v6h0.72V179.97L381.96,179.97z M381.96,167.97h-0.72v6h0.72V167.97L381.96,167.97z
         M381.96,155.97h-0.72v6h0.72V155.97L381.96,155.97z M376.73,145.72l-0.41,0.59c1.56,1.07,2.83,2.52,3.69,4.19l0.64-0.33
        c-0.46-0.9-1.03-1.74-1.7-2.5l0,0c-0.02-0.02-0.03-0.03-0.05-0.05c0,0,0,0,0,0c-0.02-0.02-0.04-0.05-0.06-0.07c0,0,0,0,0,0
        c-0.01-0.01-0.01-0.02-0.02-0.02c0,0,0,0,0,0c-0.01-0.01-0.01-0.02-0.02-0.02c0,0,0,0,0,0c-0.01-0.01-0.01-0.02-0.02-0.02
        c0,0,0,0,0,0c0,0-0.01-0.01-0.01-0.01c0,0,0,0,0,0c0,0,0,0-0.01-0.01c0,0,0,0,0,0c0,0-0.01-0.01-0.01-0.01c0,0,0,0,0,0
        c0,0,0,0-0.01-0.01c0,0,0,0,0,0c0,0-0.01-0.01-0.01-0.01c0,0,0,0,0,0c0,0,0,0-0.01-0.01c0,0,0,0,0,0c0,0-0.01-0.01-0.01-0.01
        c0,0,0,0,0,0c0,0,0,0-0.01-0.01c0,0,0,0,0-0.01c0,0-0.01-0.01-0.01-0.01c0,0,0,0,0,0c0,0,0,0-0.01-0.01c0,0,0,0-0.01-0.01
        c0,0,0-0.01-0.01-0.01c0,0,0,0,0,0c0,0,0,0-0.01-0.01c0,0-0.01-0.01-0.01-0.01c0,0,0,0-0.01-0.01c0,0,0,0-0.01-0.01
        c0,0,0,0-0.01-0.01c0,0,0-0.01-0.01-0.01c0,0,0,0-0.01-0.01c0,0,0,0-0.01-0.01c0,0,0,0-0.01-0.01c0,0,0,0-0.01-0.01
        c0,0,0,0-0.01-0.01c0,0,0,0-0.01-0.01c0,0,0,0-0.01-0.01c0,0,0,0-0.01-0.01c0,0,0,0-0.01-0.01c0,0-0.01-0.01-0.01-0.01
        c0,0,0,0-0.01-0.01c0,0,0,0-0.01-0.01c0,0,0,0,0,0c0,0-0.01-0.01-0.01-0.01c0,0,0,0,0,0c0,0,0,0-0.01-0.01c0,0,0,0,0,0
        c0,0-0.01-0.01-0.01-0.01c0,0,0,0,0,0c0,0,0,0-0.01-0.01c0,0,0,0,0,0c0,0-0.01-0.01-0.01-0.01c0,0,0,0,0,0
        c0,0-0.01-0.01-0.01-0.01c0,0,0,0,0,0c0,0-0.01-0.01-0.01-0.01c0,0,0,0,0,0c0,0-0.01-0.01-0.01-0.01c0,0,0,0,0,0
        c0,0-0.01-0.01-0.01-0.01c0,0,0,0,0,0c0,0-0.01-0.01-0.01-0.01c0,0,0,0,0,0c0,0-0.01-0.01-0.01-0.01c0,0,0,0,0,0
        c0,0-0.01-0.01-0.01-0.01c0,0,0,0,0,0c0,0-0.01-0.01-0.01-0.01c0,0,0,0,0,0c0,0-0.01-0.01-0.01-0.01c0,0,0,0,0,0
        c0,0-0.01-0.01-0.01-0.01c0,0,0,0,0,0c0,0-0.01-0.01-0.01-0.01c0,0,0,0,0,0c0,0-0.01-0.01-0.01-0.01c0,0,0,0,0,0
        c0,0-0.01-0.01-0.01-0.01c0,0,0,0,0,0c0,0-0.01-0.01-0.01-0.01c0,0,0,0,0,0c0,0-0.01-0.01-0.01-0.01c0,0,0,0,0,0
        c0,0-0.01-0.01-0.01-0.01c0,0,0,0,0,0c0,0-0.01-0.01-0.01-0.01c0,0,0,0,0,0c0,0-0.01-0.01-0.01-0.01c0,0,0,0,0,0
        c0,0-0.01-0.01-0.01-0.01c0,0,0,0,0,0c0,0-0.01-0.01-0.01-0.01c0,0,0,0,0,0c0,0-0.01-0.01-0.01-0.01c0,0,0,0,0,0
        c0,0-0.01-0.01-0.01-0.01c0,0,0,0,0,0c0,0-0.01-0.01-0.01-0.01c0,0,0,0,0,0c0,0-0.01-0.01-0.01-0.01c0,0,0,0,0,0
        c0,0-0.01-0.01-0.01-0.01c0,0,0,0,0,0c0,0-0.01-0.01-0.01-0.01c0,0,0,0,0,0c0,0-0.01-0.01-0.01-0.01c0,0,0,0,0,0
        c0,0-0.01-0.01-0.01-0.01c0,0,0,0,0,0c0,0-0.01-0.01-0.01-0.01c0,0,0,0,0,0c0,0-0.01-0.01-0.01-0.01c0,0,0,0,0,0
        c0,0-0.01-0.01-0.01-0.01l0,0c0,0-0.01-0.01-0.01-0.01c0,0,0,0,0,0c0,0-0.01-0.01-0.01-0.01c0,0,0,0,0,0c0,0-0.01-0.01-0.01-0.01
        c0,0,0,0,0,0c0,0-0.01-0.01-0.01-0.01c0,0,0,0,0,0c0,0-0.01-0.01-0.01-0.01c0,0,0,0,0,0c0,0-0.01-0.01-0.01-0.01c0,0,0,0,0,0
        c0,0-0.01-0.01-0.01-0.01c0,0,0,0,0,0c0,0-0.01-0.01-0.01-0.01c0,0,0,0,0,0c0,0-0.01-0.01-0.01-0.01c0,0,0,0,0,0
        c0,0-0.01-0.01-0.01-0.01c0,0,0,0,0,0c-0.01-0.01-0.02-0.01-0.02-0.02c0,0,0,0,0,0c-0.01-0.01-0.02-0.01-0.02-0.02c0,0,0,0,0,0
        c0,0-0.01-0.01-0.01-0.01c0,0,0,0,0,0c0,0-0.01-0.01-0.01-0.01c0,0,0,0,0,0c0,0-0.01-0.01-0.01-0.01c0,0,0,0,0,0
        c0,0-0.01-0.01-0.01-0.01c0,0,0,0,0,0c0,0-0.01-0.01-0.01-0.01c0,0,0,0,0,0c0,0-0.01-0.01-0.01-0.01c0,0,0,0,0,0
        c0,0-0.01-0.01-0.01-0.01c0,0,0,0,0,0c0,0-0.01-0.01-0.01-0.01c0,0,0,0,0,0c0,0-0.01-0.01-0.01-0.01c0,0,0,0,0,0
        c0,0-0.01-0.01-0.01-0.01c0,0,0,0,0,0c0,0-0.01-0.01-0.01-0.01c0,0,0,0,0,0c0,0-0.01-0.01-0.01-0.01c0,0,0,0,0,0
        c0,0-0.01-0.01-0.01-0.01c0,0,0,0,0,0c0,0-0.01-0.01-0.01-0.01c0,0,0,0,0,0c0,0-0.01-0.01-0.01-0.01c0,0,0,0,0,0
        c0,0-0.01-0.01-0.01-0.01c0,0,0,0,0,0c0,0-0.01,0-0.01-0.01c0,0,0,0,0,0c0,0-0.01-0.01-0.01-0.01c0,0,0,0,0,0
        c0,0-0.01,0-0.01-0.01c0,0,0,0,0,0c0,0-0.01-0.01-0.01-0.01c0,0,0,0-0.01,0c0,0,0,0-0.01-0.01c0,0,0,0-0.01-0.01
        c0,0-0.01,0-0.01-0.01c0,0,0,0-0.01,0c0,0-0.01,0-0.01-0.01c0,0-0.01,0-0.01-0.01c0,0,0,0-0.01-0.01c0,0,0,0-0.01,0
        c0,0-0.01,0-0.01-0.01c0,0-0.01,0-0.01-0.01c0,0,0,0-0.01-0.01c0,0,0,0-0.01-0.01c0,0-0.01,0-0.01-0.01c0,0,0,0-0.01-0.01
        c0,0,0,0-0.01-0.01c0,0,0,0-0.01-0.01c0,0-0.01-0.01-0.01-0.01c0,0,0,0-0.01,0c0,0,0,0-0.01,0c0,0,0,0-0.01,0
        c0,0-0.01-0.01-0.01-0.01c0,0,0,0-0.01-0.01c0,0,0,0-0.01,0c0,0-0.01-0.01-0.01-0.01c0,0,0,0,0,0c0,0,0,0-0.01-0.01c0,0,0,0,0,0
        c0,0-0.01-0.01-0.01-0.01c0,0,0,0,0,0c0,0-0.01,0-0.01-0.01c0,0,0,0,0,0c0,0-0.01-0.01-0.01-0.01c0,0,0,0,0,0
        c0,0-0.01,0-0.01-0.01c0,0,0,0,0,0c-0.01,0-0.01-0.01-0.02-0.01c0,0,0,0,0,0c-0.01-0.01-0.02-0.01-0.03-0.02c0,0,0,0,0,0
        c-0.01-0.01-0.02-0.01-0.03-0.02c0,0,0,0,0,0C377.1,145.97,376.92,145.84,376.73,145.72L376.73,145.72z M369.96,143.62v0.72
        c0.38,0,0.76,0.02,1.13,0.06l0.07-0.72c-0.2-0.02-0.41-0.04-0.61-0.05c0,0,0,0,0,0c-0.01,0-0.01,0-0.02,0c0,0,0,0,0,0
        c-0.01,0-0.01,0-0.02,0c0,0,0,0,0,0c-0.01,0-0.01,0-0.02,0c0,0,0,0,0,0c0,0-0.01,0-0.01,0c0,0,0,0,0,0c0,0-0.01,0-0.01,0
        c0,0,0,0,0,0c0,0-0.01,0-0.01,0c0,0,0,0,0,0c0,0-0.01,0-0.01,0c0,0,0,0-0.01,0c0,0-0.01,0-0.01,0c0,0,0,0-0.01,0
        c0,0-0.01,0-0.01,0c0,0,0,0-0.01,0c0,0-0.01,0-0.01,0c0,0,0,0-0.01,0c0,0-0.01,0-0.01,0c0,0,0,0-0.01,0c0,0-0.01,0-0.01,0
        c0,0,0,0-0.01,0c0,0-0.01,0-0.01,0c0,0-0.01,0-0.01,0c0,0-0.01,0-0.01,0c0,0-0.01,0-0.01,0c0,0-0.01,0-0.01,0c0,0-0.01,0-0.01,0
        c0,0-0.01,0-0.01,0c0,0-0.01,0-0.01,0c0,0-0.01,0-0.01,0c0,0-0.01,0-0.01,0c0,0-0.01,0-0.01,0c0,0-0.01,0-0.01,0
        c0,0-0.01,0-0.01,0c0,0-0.01,0-0.01,0c0,0-0.01,0-0.01,0c0,0-0.01,0-0.01,0c0,0-0.01,0-0.01,0c0,0-0.01,0-0.01,0
        c0,0-0.01,0-0.01,0c0,0-0.01,0-0.01,0c0,0-0.01,0-0.01,0s-0.01,0-0.01,0c0,0-0.01,0-0.01,0c0,0-0.01,0-0.01,0c0,0-0.01,0-0.01,0
        c0,0-0.01,0-0.01,0c0,0-0.01,0-0.01,0c0,0-0.01,0-0.01,0c0,0-0.01,0-0.01,0c0,0-0.01,0-0.01,0c0,0-0.01,0-0.01,0
        c0,0-0.01,0-0.01,0c0,0-0.01,0-0.01,0c0,0-0.01,0-0.01,0c0,0-0.01,0-0.01,0C369.97,143.62,369.96,143.62,369.96,143.62
        L369.96,143.62z' />
  </g>
    <path fill='#ECEEF0' d='M378.54,233H153c-1.89,0-3.42-1.53-3.42-3.42v-0.5c0-1.89,1.53-3.42,3.42-3.42h225.53
     c1.89,0,3.42,1.53,3.42,3.42v0.5C381.96,231.47,380.43,233,378.54,233z' />
    <path fill='#ECEEF0' d='M378.54,248.8H153c-1.89,0-3.42-1.53-3.42-3.42v-0.5c0-1.89,1.53-3.42,3.42-3.42h225.53
     c1.89,0,3.42,1.53,3.42,3.42v0.5C381.96,247.27,380.43,248.8,378.54,248.8z' />
    <path fill='#ECEEF0' d='M346.41,264.59H153c-1.89,0-3.42-1.53-3.42-3.42v-0.5c0-1.89,1.53-3.42,3.42-3.42h193.41
     c1.89,0,3.42,1.53,3.42,3.42v0.5C349.83,263.06,348.3,264.59,346.41,264.59z' />

    <g id='g17_00000099639585137878634580000005037944694165732774_' transform='matrix(1.3333333,0,0,-1.3333333,-133.33333,799.99999)'>
     <g id='g19_00000026856781696682147940000007107829321334865314_'>
        <g>
           <defs>
              <rect id='SVGID_24_' x='209.82' y='529.92' width='30.09' height='30.09' />
           </defs>
          <clipPath id='SVGID_12_'>
              <use xlink:href='#SVGID_24_' overflow='visible' />
           </clipPath>
          <g id='g21_00000065035190406144963040000002303539956452578482_' clip-path='url(#SVGID_12_)'>
              <g id='g27_00000078021606236540106660000005902277631592484285_' transform='translate(600,350)'>
                 <path id='path29_00000080911037642835388200000015257113392230419357_' fill='#0866FF' d='M-367.07,194.96
                    c0,4.45-3.61,8.06-8.06,8.06s-8.06-3.61-8.06-8.06c0-3.78,2.6-6.95,6.11-7.82v5.36h-1.66v2.46h1.66v1.06
                    c0,2.74,1.24,4.01,3.93,4.01c0.51,0,1.39-0.1,1.75-0.2v-2.23c-0.19,0.02-0.52,0.03-0.93,0.03c-1.32,0-1.83-0.5-1.83-1.8
                    v-0.87h2.63l-0.45-2.46h-2.18v-5.54C-370.17,187.45-367.07,190.84-367.07,194.96' />
              </g>
            <g id='g31_00000163768415079046479420000000128396019481948332_' transform='translate(447.9175,273.6036)'>
                 <path id='path33_00000061473466682718372940000015681087922222158976_' fill='#FFFFFF' d='M-219.89,268.9l0.45,2.46h-2.63
                    v0.87c0,1.3,0.51,1.8,1.83,1.8c0.41,0,0.74-0.01,0.93-0.03v2.23c-0.36,0.1-1.24,0.2-1.75,0.2c-2.69,0-3.93-1.27-3.93-4.01
                    v-1.06h-1.66v-2.46h1.66v-5.36c0.62-0.15,1.28-0.24,1.95-0.24c0.33,0,0.66,0.02,0.98,0.06v5.54H-219.89z' />
              </g>
           </g>
        </g>
     </g>
  </g>
    <g>
     <path fill='#FF004F' d='M213.2,70.54c1.32,0.95,2.95,1.5,4.7,1.5v-3.37c-0.33,0-0.66-0.03-0.99-0.1v2.65
        c-1.75,0-3.37-0.56-4.7-1.5v6.88c0,3.44-2.79,6.23-6.23,6.23c-1.28,0-2.48-0.39-3.47-1.05c1.13,1.16,2.71,1.87,4.46,1.87
        c3.44,0,6.23-2.79,6.23-6.23L213.2,70.54L213.2,70.54z M214.42,67.14c-0.68-0.74-1.12-1.69-1.22-2.75v-0.43h-0.94
        C212.5,65.3,213.31,66.44,214.42,67.14L214.42,67.14z M204.69,79.13c-0.38-0.5-0.58-1.1-0.58-1.72c0-1.57,1.28-2.85,2.85-2.85
        c0.29,0,0.59,0.04,0.86,0.13v-3.45c-0.33-0.04-0.66-0.06-0.99-0.06v2.68c-0.28-0.09-0.57-0.13-0.87-0.13
        c-1.57,0-2.85,1.28-2.85,2.85C203.12,77.7,203.76,78.66,204.69,79.13z' />
      <path d='M212.22,69.72c1.32,0.95,2.95,1.5,4.7,1.5v-2.65c-0.98-0.21-1.84-0.72-2.49-1.43c-1.11-0.69-1.92-1.84-2.15-3.18h-2.46
        v13.46c-0.01,1.57-1.28,2.84-2.85,2.84c-0.93,0-1.75-0.44-2.27-1.12c-0.93-0.47-1.57-1.43-1.57-2.55c0-1.57,1.28-2.85,2.85-2.85
        c0.3,0,0.59,0.05,0.87,0.13v-2.68c-3.38,0.07-6.1,2.83-6.1,6.23c0,1.7,0.68,3.23,1.78,4.35c0.99,0.67,2.19,1.05,3.47,1.05
        c3.44,0,6.23-2.79,6.23-6.23L212.22,69.72L212.22,69.72z' />
      <path fill='#00F2EA' d='M216.92,68.57v-0.72c-0.88,0-1.75-0.25-2.49-0.71C215.08,67.86,215.96,68.36,216.92,68.57z M212.27,63.95
        c-0.02-0.13-0.04-0.26-0.05-0.39v-0.43h-3.39v13.46c-0.01,1.57-1.28,2.84-2.85,2.84c-0.46,0-0.9-0.11-1.28-0.3
        c0.52,0.68,1.34,1.12,2.27,1.12c1.57,0,2.85-1.27,2.85-2.84V63.95H212.27z M206.84,71.19v-0.76c-0.28-0.04-0.57-0.06-0.86-0.06
        c-3.44,0-6.23,2.79-6.23,6.23c0,2.16,1.1,4.06,2.76,5.18c-1.1-1.12-1.78-2.66-1.78-4.35C200.74,74.02,203.46,71.26,206.84,71.19z
        ' />
  </g>
    <g>
     <path d='M236.26,186.07v-5.13c0-0.05-0.01-0.08-0.02-0.09l-3.8-7.14c-0.01-0.02-0.02-0.05-0.02-0.09c0-0.08,0.05-0.13,0.15-0.13
        h1.5c0.11,0,0.18,0.04,0.22,0.13l2.79,5.35c0.01,0.02,0.03,0.04,0.06,0.04c0.02,0,0.04-0.01,0.05-0.04l2.79-5.35
        c0.04-0.08,0.11-0.13,0.22-0.13h1.51c0.06,0,0.11,0.02,0.14,0.06c0.03,0.04,0.03,0.09-0.01,0.16l-3.81,7.14
        c-0.01,0.01-0.02,0.04-0.02,0.09v5.13c0,0.12-0.06,0.18-0.18,0.18h-1.39C236.32,186.25,236.26,186.19,236.26,186.07z' />
      <path d='M244.88,185.73c-0.67-0.46-1.14-1.09-1.41-1.91c-0.22-0.66-0.33-1.4-0.33-2.23c0-0.86,0.1-1.59,0.31-2.19
        c0.27-0.8,0.74-1.43,1.41-1.89c0.67-0.46,1.47-0.68,2.39-0.68c0.88,0,1.64,0.23,2.29,0.68c0.65,0.46,1.12,1.08,1.4,1.87
        c0.22,0.62,0.33,1.35,0.33,2.19c0,0.85-0.11,1.6-0.33,2.24c-0.27,0.81-0.73,1.45-1.39,1.91c-0.66,0.46-1.43,0.68-2.32,0.68
        C246.33,186.42,245.55,186.19,244.88,185.73z M248.51,184.49c0.36-0.27,0.63-0.66,0.78-1.16c0.13-0.46,0.2-1.03,0.2-1.72
        c0-0.68-0.06-1.25-0.18-1.7c-0.16-0.5-0.42-0.88-0.78-1.16c-0.37-0.27-0.81-0.41-1.33-0.41c-0.5,0-0.93,0.14-1.3,0.41
        c-0.37,0.27-0.63,0.66-0.78,1.16c-0.13,0.43-0.2,0.99-0.2,1.7c0,0.71,0.07,1.28,0.2,1.72c0.15,0.5,0.4,0.88,0.78,1.16
        s0.82,0.41,1.34,0.41C247.72,184.9,248.14,184.76,248.51,184.49z' />
      <path d='M260.19,176.98h1.37c0.12,0,0.18,0.06,0.18,0.18v8.92c0,0.12-0.06,0.18-0.18,0.18h-1.37c-0.12,0-0.18-0.06-0.18-0.18
        v-0.68c0-0.02-0.01-0.04-0.03-0.05c-0.02-0.01-0.04,0-0.06,0.03c-0.49,0.67-1.23,1-2.24,1c-0.6,0-1.15-0.12-1.65-0.35
        c-0.5-0.23-0.91-0.57-1.21-1.02c-0.3-0.45-0.46-1-0.46-1.64v-6.22c0-0.12,0.06-0.18,0.18-0.18h1.37c0.12,0,0.18,0.06,0.18,0.18
        v5.68c0,0.63,0.17,1.13,0.5,1.5c0.33,0.37,0.81,0.55,1.41,0.55c0.61,0,1.09-0.19,1.46-0.56c0.36-0.37,0.55-0.87,0.55-1.49v-5.68
        C260.01,177.04,260.07,176.98,260.19,176.98z' />
      <path d='M270.34,177.13c0.08,0.04,0.12,0.11,0.09,0.22l-0.29,1.37c-0.01,0.11-0.09,0.15-0.22,0.11
        c-0.21-0.08-0.45-0.13-0.73-0.13l-0.26,0.02c-0.57,0.02-1.04,0.22-1.41,0.59c-0.37,0.37-0.56,0.85-0.56,1.45v5.33
        c0,0.12-0.06,0.18-0.18,0.18h-1.39c-0.12,0-0.18-0.06-0.18-0.18v-8.92c0-0.12,0.06-0.18,0.18-0.18h1.39
        c0.12,0,0.18,0.06,0.18,0.18v0.97c0,0.04,0.01,0.06,0.03,0.06c0.02,0.01,0.03,0,0.05-0.03c0.55-0.85,1.3-1.28,2.24-1.28
        C269.67,176.89,270.02,176.97,270.34,177.13z' />
      <path d='M285.21,186.12l-0.66-2.14c-0.01-0.04-0.04-0.05-0.07-0.05h-5.13c-0.04,0-0.06,0.02-0.07,0.05l-0.66,2.14
        c-0.04,0.1-0.11,0.15-0.22,0.15h-1.48c-0.13,0-0.18-0.07-0.15-0.2l4-12.43c0.04-0.1,0.11-0.15,0.22-0.15h1.83
        c0.11,0,0.18,0.05,0.22,0.15l4.03,12.43l0.02,0.07c0,0.09-0.05,0.13-0.16,0.13h-1.5C285.32,186.27,285.24,186.22,285.21,186.12z
         M279.74,182.52c0.02,0.02,0.04,0.03,0.06,0.03h4.2c0.02,0,0.05-0.01,0.06-0.03c0.02-0.02,0.02-0.04,0.01-0.06l-2.12-6.77
        c-0.01-0.02-0.03-0.04-0.05-0.04c-0.02,0-0.04,0.01-0.05,0.04l-2.12,6.77C279.72,182.48,279.72,182.5,279.74,182.52z' />
      <path d='M295.56,173.49h1.39c0.12,0,0.18,0.06,0.18,0.18v12.41c0,0.12-0.06,0.18-0.18,0.18h-1.39c-0.12,0-0.18-0.06-0.18-0.18
        v-0.6c0-0.02-0.01-0.04-0.03-0.05c-0.02-0.01-0.04-0.01-0.06,0.02c-0.27,0.3-0.58,0.54-0.95,0.71c-0.36,0.17-0.76,0.26-1.19,0.26
        c-0.88,0-1.6-0.22-2.18-0.65c-0.58-0.43-1-1.04-1.27-1.83c-0.23-0.64-0.35-1.42-0.35-2.34c0-0.85,0.1-1.59,0.29-2.21
        c0.26-0.8,0.68-1.43,1.26-1.88s1.33-0.68,2.24-0.68c0.41,0,0.81,0.08,1.18,0.25c0.37,0.16,0.69,0.4,0.96,0.7
        c0.02,0.02,0.05,0.03,0.06,0.02c0.02-0.01,0.03-0.03,0.03-0.05v-4.07C295.37,173.56,295.43,173.49,295.56,173.49z M295.34,181.62
        c0-1.02-0.15-1.8-0.46-2.34c-0.35-0.62-0.87-0.93-1.55-0.93c-0.73,0-1.27,0.29-1.62,0.88c-0.39,0.61-0.58,1.41-0.58,2.41
        c0,0.94,0.15,1.69,0.46,2.26c0.35,0.67,0.93,1,1.73,1c0.71,0,1.24-0.33,1.61-0.99C295.2,183.4,295.34,182.64,295.34,181.62z' />
  </g>
    <g id='liveA1M'>
     <path fill='#D4F7E4' d='M345.83,84.1h29.85c2.76,0,5-2.24,5-5V67.8c0-2.76-2.24-5-5-5h-29.85c-2.76,0-5,2.24-5,5v11.3
        C340.83,81.86,343.07,84.1,345.83,84.1z' />
      <g>
        <path fill='#009F68' d='M347.84,69.03h1.87v7.3h3.79v1.54h-5.66V69.03z' />
        <path fill='#009F68' d='M356.61,77.88h-1.87v-8.84h1.87V77.88z' />
        <path fill='#009F68' d='M361.89,75.75h0.08l2.14-6.72h2.07l-3.05,8.84h-2.41l-3.05-8.84h2.08L361.89,75.75z' />
        <path fill='#009F68' d='M367.25,69.03h5.96v1.54h-4.09v2.11h3.78v1.54h-3.78v2.11h4.11v1.54h-5.98V69.03z' />
     </g>
  </g>
    <path d='M362.1,125.77c-0.9,0.75-2.06,1.21-3.32,1.21c-2.86,0-5.18-2.32-5.18-5.18c0-2.86,2.32-5.18,5.18-5.18
     c2.86,0,5.18,2.32,5.18,5.18c0,1.26-0.45,2.42-1.21,3.32l2.89,2.89c0.09,0.09,0.13,0.21,0.13,0.32c0,0.36-0.33,0.46-0.46,0.46
     c-0.12,0-0.23-0.04-0.32-0.13L362.1,125.77z M358.78,117.54c-2.35,0-4.26,1.91-4.26,4.26c0,2.35,1.91,4.26,4.26,4.26
     c2.35,0,4.26-1.91,4.26-4.26S361.13,117.54,358.78,117.54z' />
</g>
  <g id='w2A1'>
  <circle fill='${accentColor}' cx='762.78' cy='120.19' r='2.43' />
    <g>

        <image overflow='visible' opacity='0.22' width='342' height='312' xlink:href='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAVYAAAE4CAYAAAAAUdupAAAACXBIWXMAAAsSAAALEgHS3X78AAAA
GXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAFtlJREFUeNrs3Ylu47qWRmFKyTnv
/7y3YqvR6Bity+KwKVOyU/UtwLDL8ciCln9uDkoJAAAAAAD8RSyaQNsAT7Bpgj9fHmQIkDQRESiA
P024i89J5MAPE93bi3b5Az8XCQJ/l6zfTrTLD/4syx/wnQHinPu8t5Ds8oM+w3LCdyFZ4L1kuk16
zZcKdnnj914OPG/5Qd8fINb447eDr/8SwS5v+J5L4PHLE9+HTIH3lOwWvP+IZC8V7PIm77U8+e9n
ZUvAwHXd/Wck2pPqWwh2efF7tIS5HHjcqHSJFbherJHEuQXFGX3cpYI9Wx6RFFkTY0+yI3Jd3qhN
gL9dsNFUugXvOyrY0+S6vOB1o5Ic+dsVCRbAPOkekek2Sbyny3W58DWjCXR/vQQeM5JyiRV4L6mO
inQbeOzLBDtbML102BNq777oY2eKlYSB87r+ozKN3B4R7SlynSmNqFRH5Vm7LyrbiGiJFThHrKNS
bd2O3ld7zYhgp8h1ljSOSLUny8il9vyRMgGhAucJtldL7Ym0JNHWZSTNnibXGfJoSTWX2ahM1ydE
25Nr5PuTKzBHqpGkGhXp/YBkL5Xr5wVSjXTzW0JdDwg3UhogVuB1Yo1KtSTRZUC0tc+17P6+FB67
PCPXZ8R6RKq57NaAUNeAbNcUr78eKQuQLDCW5KIDVCPp9F4Q6/373/fC83qfryfXl5QCehP7R9Np
6bp2O5JqjwxqEStwjVhLcr1X/l26rt3eGpJtlQtq3++QbI8m1uhKp2hXPxdo6d890dYEO6ssAGBc
vFGpRkRa+veyS5tLIcHev70QLQukGSWBI2IdWVXVS6gtgUYvtRTbSrDECpwv1khdtSbWkkR7l2Un
1bST6khZYEpJ4HNCQ450/SMi/ajcLv17VK55eh35oQAQ7yaP1lVHRHrLbi+F4zplku3VXVtyHZbt
qFgjddWWVEtiLYn0I7tduu4Jdn87NZJrS6DECoyLtVZf7Q1S5XK9VYS67m4vmWBTIbnW0mtJnm+T
WFvSbdVUPzoyzS814fbk2isJ1ARKqsAxuY6KtSfV2/fl4/t63cl13Uk1v865N4773iyBIeGOiDW6
vV+k+98SaPQSSa/7z9BLrWQKzJVsVKpbo7t/K9z+yuS6P65vgc95rxzv26z0OmuBQGRKVU+qn4O3
8wQ7klxTQ64EC8xLraWpTfeOWHOR5pc1E2zp+I7IdRkscYRFGxXryE7/NaHWpPpZuK7dPiLXWmol
V+B8qabBtNqS6tdOrF+765JUW9IsPb628uqy6VYtoaZgYv2oyLR1yUVbKg08Xn+pyFVqBd5DrK0S
wEOuX5lUP7IywFcnrfY2wk7pv1dzTUutz85jjWyMsgbk+rj8U7ldk2yv5qocALyvWB9SKw1U3b6P
8ZpUS8Ep+nm3hpCnzBSIiHX0jKitFVQ1oe5F+k92uybZmlzz1BrZS4BcgblS3UssDabVWyGplo7z
iFRbA2f587dOeSCcWp+ZxxpNq736akmq+aWVYI+k1hRMrQQLxITaS6v7bncu11pdtSXV9UmpbplM
t0ywQ13/UbGO7rT/TH11L9J/G4LdS7aVWmvdhTOnXBEw/lRhjj53tL66l+vX7vpx+TWQVkc2xo6m
1tPmsaaGiKJptVdf3Uv13+y+z4JgI6lVKQC4vhRQS669eatf38f047ol1IhU75X79p7q7RUwvF/r
0QUCvTMAjAxYlUoBe7n+2ykN9FJrb9er2akV+NsF2xu8Kg1clQasHqWAX5Ve6EhKvWfPr6XW1gyB
6fNYjwi3J9daYv0nk+q/lfJAbSArklgjy1rJFZgj1WhirdVWe3XVmrhLl71Y74XUukwoizTFGuka
90670ioHtGqsuVhLcu2VA2qpNaXxzVjIFjh2KpZIjTWftzoyCyAq1cfr7jdjWTuptTeIVU2wR1Ze
jZwIcA0m1l4poFUSiIg1MiuAWIHzxJrS77MCcrF+VtJqLQiVhHoLSHXdybXmr+jUq1NKASNnCJgh
1/39+RSso4l1VK4AYlIdSay3QFrdp8TeHq75az3uWxtyjaTR6SuvIqdj6e2/2qqxtuax5uWBSGJt
LW09mlgBjCfWlMamWkVrqpF67cMJtxSbgrmkCXuyfg52eVsbW6dOSSBfIPDZEOxn6g9k9RJrbfVV
SrYOBK5OrCkg1lGpRga+8sut4IV7qm/kcmixwJElrUunJNArA6wFodb2DKil1nza1eisgF45AMA5
ibVVCthvXt0bqKpJ9bMg2Na2oveGGyJ11qJsn93dqnUm1JZg10p6raXW3u3SdoKjS1rJFXiNWHsD
zS2h/pPqm7ZEzjZSW5HZSqjT9wpopdgjMwMicm3tevVPIfXuGzKP/K0yAKkC58k1nxmwXyDQ6v7X
plLlQt2v2GrtL7B2ZJon1kPM2I91ZGOW1uyAVmmglWRLA1ilRiRW4P3EWjq9SrT7vxdqbapW9MSj
LcmettF1b2pSb3lrrxTQEmxpgKt2XyuxjuzFSrTAmESjcl0zSdbEVhLqo6ufH/+tAavWGUUiO/T1
TjIYFmtvr9VWco2m1aOSHTllSy/2EytwvVj3K53W9Pupq0tS/dw9rlRHjabUkXJAzQehmuvRbQNb
e5qOLhho7X5VE+1HQ6pH9gkgUuC8UkBNrFvqr6h6yPWjkFhrm9wf7fqPno3g6VJARLqlD1haJNCb
JdASbi7f0hSuyBzWSGIFME+u+VzWJf3/aaj3j/1Iv+9IVTveI67oSbU3gHVoZsCs3a1qibX0IXty
XTvC/Kg8ptSoeYMSK/B6se6lulTkek+/L0VthanS2Eq069/rzV6aWCPlgaOlgWi5oBf5o1OtCBaY
J9Wlcnsv1ccxub+9T6wflcTa6vIv6dhg1XQPzDhL61G5Lg0Z9lJt7/mt01/3vgOA5wNWSbD5zIBc
yPvt/JbG8R6dRjWyN8Ay0wXPLBBoLQ0dvfQapCfN2uOOzggAcI2E10y2uVyX1B/VXxtBa+R8dymN
rcC6rBQwMh1rDZQJetJcA4+XWIH3JN9wOtqjbYWvaCpdOt7a/+20BQJHugJLGtsN6+gE3l7jmWoF
XMc2cExtqV8iHO2R9jZd6g1gXVYKOJLujpQGRlLtMtBQ0ipwbfe+JNkttZeLPhOiervXRbr+Uznj
DAK1D9ySXhoUZPQxrTQtsQLXpNbWaaVHjuteYBq574jrwstaPy9o5N6vSErjXfjogJTECrwutfYS
6xZ0xjPhrPf8SEgcZj0gxxmPHRVkJM6nwV8lUgWuKwtEe7xLwBNLwDmtQHdabfWKxNpqpF69ozXY
ldKxzRN67022wJwSQKkLPdKVjqyUjK71n3lML52kPZxYz/oFi9RDo4l1tGh9NJEDmNNbjaTJSCiL
jqksVx3z65s09jNCjCZOA1fA6wLUGekxMtj1kuN8ndh4Zz336HmqCBP4OQJeBksBR4/3S3qn65s0
6sj9M3/9yBe49vg+8hrRge1XBsCmWEe77Gc2PukB+JHBab3wi4xMlSBXAEcD3uzwFw2c08U6a2XD
UaEa1Qf+bFlGvRHdserUELe+WYwmRwCvkvm0gLZqVwB/iCDf5j3Xk99UAgXw1/ETEitBA5jtgx+/
8goAJFYAALECALECALECAIgVAIj1/zC1CgCxAoAwRqwAQKwAQKwAQKwAAGIFAGIFAGIFABArABAr
ABArAIBYAYBYAYBYAQDECgDECgDECgDECgAgVgAgVgAgVgAAsQIAsQIAsQIAiBUAiBUAiBUAQKwA
QKwAQKwAQKwAAGIFAGIFAGIFABArABArABArAIBYAYBYAYBYAYBYAQDECgDECgDECgAgVgAgVgAg
VgAAsQIAsQIAsQIAiBUAiBUAiBUAiBUAQKwAQKwAQKwAAGIFAGIFAGIFABArABArABArAIBYAYBY
AYBYAYBYAQDECgDECgDECgAgVgAgVgAgVgAAsQIAsQIAsQIAiBUAiBUAiBUAiBUAQKwAQKwAQKwA
AGIFAGIFAGIFABArABArABArABArAIBYAYBYAYBYAQDECgDECgDECgAgVgAgVgAgVgAAsQIAsQIA
sQIAsQIAiBUAiBUAiBUAQKwAQKwAQKwAAGIFAGIFAGIFABArABArABArABArAIBYAYBYAYBYAQDE
CgDECgDECgAgVgAgVgAgVgAAsQIAsQIAsQIAsQIAiBUAiBUAiBUAQKwAQKwAQKwAAGIFAGIFAGIF
AGIFABArABArABArAIBYAYBYAYBYAQDECgDECgDECgAgVgAgVgCosxHrD2wkAJBYAYBYAYBYAQDE
CgDECgDECgAns6UfMHNo1ZAACPrniJUQASgFFMT4il8RAPgJ7tjOTqzSKYCfKNe9u6Z5bD3hi9U+
3Nb54NsTDUTswJ8hxiPHfu81tsBrT/XHeuDFZ0osImIAGPXDdtH7FN9vfXHD/MSuA4Brj62t85rb
ux3f64sabuSXZutckybwZ8p3qxz328T3OOW560WNtz35RVpyjdZmt464AZwjzG3g+DzqjJEu++nH
/PoGDb5VBDnauFvjV+4tGhv4C4Qa7UlunWO3J+bRQfJLj+/PCxs50pXfJpYC8ucv37f318oHwDVh
6sisn2gpICrko6n47RNrK/a37jtSClCDBd6rJNDzwEhvteeN7YTjPCzjdfYLNr5sJPZHGmsbfI9W
eUAJALi2txo9NkeP/V5vdwv46a1KAdHice/Xaev8cm2BtJv/Le/27+9L2f0Azu2ZRsoDR8LVFvBG
L1RtHUlPLwUcGWnv/Tptjft6j4kKOdI1kFiB6xNrtIc5ckkHHj/jR2JKYq0N+kQb9Ujj3b8v+e1o
47VSqcQKXJdWoyl0G/TDVvDCkTLBW5UCRqJ1VKq1xtoqgr1/p+/7rqtfS9MLkQIvkevj3/fgMX8f
cEDt9VLHQ6f0Xj+faLAt8LeeSO8dWbau98/ZS3V/vb9/KaTuSAIHMC+xbsFjvHSc3ytCbQWx+0Ap
YsRz08Wayygyh6z3S3PvNGCk4deKVNfCZ98KZQCCBeYKNTVC1khwagWxlhtqz6191pTqg2eXlgJG
ayb3jkxrcv3fy63xtzypPsgTa75IICV1VuCViTVyvN8GXNArI84e1DpFrL1SwZGBqVID37LbH7t/
P4R5q3yONRNrnk4lVeB6sZaO+1vh+lb5W+8yMthdStYvF+vI5N2t8+t0C/5q3b6FuRYEm0t1L9dE
rsDLpJpSv/R3axz7t0bgatVhj8r1sGSfHbwaWSkRSaf7Brs1Euu6u+SJdduVAfZyHUmsRAscE+mR
xHorHPuRS69k0Bv0yr2VGj8Ike8cFuvIvNVaUo2M8NUa9ev7Mz5uf3xf51Ldv+/HTqj7Aay9VIkV
uEas0cSaH/eP66/GfSXRRgbA72l8V73oaaWqYu3t/LQMlgV6JYDe5SHUvVS/0u9107ST6kcqzxZQ
ZwVeVwqIJNZcpLX7bg3BlmQ7Mkc28n2mlAJq6+2f6frfOkl1L9THZa0Idf++eV01fw6xAu8j1lsl
sY5eIqWCe1CoT88SeKbGugwk1S37dapJ9WvX/f8KSjVlr/+xS6oluSZyBS6Xakr1GQG1xPrr+/K1
u/4aLBHcK+k1OuXqsgUCvbrD0TLAXqi/ClJt1VXz91gDYiVV4GeItXQpyXb//JJQI9OwUpowp/Uz
2FBLoDwQEWorqX5kKfVXJsi1kZLv39/l8dofBamuBamSK3CuVFOnJ9srBUSk+qsg169GOaBUrux9
h6EU+3lApnsR1TZWqSXUj9QfnCol1aWRlredVB/P+Sgk3NGZAQDmJdYjg1e1tPqfgmi/OuWAaJ21
t2VpSLKfgw22DCbWrVMCyJPqr0A3PlX+Yz4zqX6k+uCVxAq8PrG2vFBKrP8pSLUk1sh0rCP11XB5
4KyVV63UuqbyvNRaLXUpfLH8NT+zEsDsxEq6QEwuMxPrVyOptgRbmop1JLUe5sh0q9L9ke0BS79O
X+n3OmptkCoVXm/fcB8Fsc6qsRIrcI5Y85VTJbF+BaX6q5NYRwayUnpilsDnkw2b7xa1b7SlklYf
l6+OUEtSbXUdPhvJt7URy0KiwHThtvZk7q286pUDamWBWkkgugNWpKzxdGLdKnJbOr9I+1387wNy
rdVBUyX95mn1qyDV2msTK/B+Yr2n30f1f6XyQFat3tpKrY/Xj2zMEknq28zEWmvUXhlgX1/N5RoR
ar7A4J9dUq3NeY3MY7VXAHCOVFOha93aN6S1+uroDIFerXVkLuspg1elXffzkkApuT52ntpf9xJq
/p9Sa/zaCq21k4RnzgogYfzpopyRWrdOas3ns+bTrlqC/U8qLxaICLV2EsKn2uLI7la1+1Iq11iX
3e1bR3Sp0/3PG76UVvOBq0h9dSFNYJqAW6ek7+1019sz4Ffqr8rqlQNGplwNlwGOlAJGUmsu1zy1
9qRWk+rn7hJJq6M1VkIFnpNrqxQQrbOWZgjU5PpVKQVEZgRET5k9lFxnbcKSUnsgqyXXaFptrdja
S/WjkVYNXgHXyrVXDoik1l56jUy1Gt3h6tRSQE2iKZhaH42X0u87/feSam/+60cnrUbrq8QKvE6s
vUGs0n6sLcneUn+6VWT5aiutHt4rICLaXmq9Z7K6p/ZgUWRRwX6FVamuWpLq6M5WxAo8L9eROmtr
gPqe6pte1zZeGdkzYHpaHRHrSGotNea9IK9b4DnPSFUZAHhPuZak2pohcAum2NqZBUY2Xnk6rR5N
rLXUumXlgNSQamp0D0pi/RiQajStkipwrVxHU2tEsr3Ts9wHygFPT7M6ItZeai3NFnhsOl0SbEr9
ExDmWw6WBqpaSXXtSNU0K2C+TKOJtXcqp5Zc70GZ1qR6T+NLWS/Z3SpPra3G3Z/Ur/YFPhpyXdN/
r96KdP+j+wOYbgXMF2tvM5ZUSI65+G7BFFs6W8DIooDWAoHTE2sptbZKAnlqLck1b+z92VU/MrHu
pXpL5UUArZkAI6utiBWYl1hTao++92YJ1M5E0kumvXmrrRLAU+e++jyhgZdBueZfcN0Jdv+cNf2+
18ByUKrqq8B1oh0tCUTO8jwi015anZJSnxVrL7X26q337O9rQ7BrIZlGu/6mWAHvLdaUYjMFtoA4
a+n03hFq7fMdTqszE2ut3roU5Lo0BJs3cC2ZRoQ6ayYA6QLHRBORVyu1tgS7DYg0spH11OQ6c0lr
rRxQKw3kwsvluuzk2uryrw2ZzigBECswT6wtqaaKCHui3YIy7Un1me86LbH2Ti64BD/UXrJ7uS5Z
Cr6nsVrq6EwAMgXOkWwvufYGtu6D/46efbUl/6fS6wyBtFJgSW61ZFm6rIHHRIVKrMB7ijWl2KyB
Z0V6iVTPEmtErkckuwS7/D2R6v4D7yHXlvAiSTYi0q0j9Nrnf7lYR+SaGhJsCTZNTKjLxW0D/O1S
7Ym1l1yjsu09v/dZpkh1tjwik+2XwHVPtikdr6UuL2obgFjr90UT7MjtIyl1ilTPkMeIXI+IduQ6
kqQBvE7A0dprOijQkZQ6TapnSuYMwaaBv5Eq8LPE+oxoU+C+S4R6hWii6/Bb0jz69yPfkXSB15UG
jkj2yO3TpXqFTJaBv0VlOVpDXd6sTYC/WajPJthnHneJVK+UyFHBHvn3s9+PWIHzxTraNT+ScC8X
6qskMjoxP5pAzU0F/gz5zpTt5UJ9tWyOrnyatX8qyQI/p0QwIteXCvXBx4sbfcbAEkkCf0eJYPQ1
t1d96XeR0lWJk4SBnyXZ2an3rxIraQLYXvTcv0Ks7/T5yBp4X5lt79owP1EcZAeQOEn5HgD+RIES
kjYBCBEAAAAAAGA+/yPAAKojTtenEqudAAAAAElFTkSuQmCC' transform='matrix(1 0 0 1 455 14)'>
     </image>
      <g>
        <path fill='#FFFFFF' d='M506.43,281.24c-9.73,0-17.64-7.91-17.64-17.64V54.93c0-9.73,7.91-17.64,17.64-17.64h238.96
           c9.73,0,17.64,7.91,17.64,17.64V263.6c0,9.73-7.91,17.64-17.64,17.64H506.43z' />
        <path fill='#DEE3E9' d='M745.39,37.65c9.53,0,17.28,7.75,17.28,17.28V263.6c0,9.53-7.75,17.28-17.28,17.28H506.43
           c-9.53,0-17.28-7.75-17.28-17.28V54.93c0-9.53,7.75-17.28,17.28-17.28H745.39 M745.39,36.93H506.43c-9.94,0-18,8.06-18,18V263.6
           c0,9.94,8.06,18,18,18h238.96c9.94,0,18-8.06,18-18V54.93C763.39,44.99,755.33,36.93,745.39,36.93L745.39,36.93z' />
     </g>
  </g>
    <path fill='#ECEEF0' d='M763.21,78.34H488.26V54.93c0-9.94,8.06-18,18-18h238.96c9.94,0,18,8.06,18,18V78.34z' />
    <g>
     <path fill='#ECEEF0' d='M518.51,195.28c-6.42,0-11.64-5.22-11.64-11.64v-60.58c0-6.42,5.22-11.64,11.64-11.64h60.58
        c6.42,0,11.64,5.22,11.64,11.64v60.58c0,6.42-5.22,11.64-11.64,11.64H518.51z' />
      <path fill='#DEE3E9' d='M579.09,111.78c6.22,0,11.28,5.06,11.28,11.28v60.58c0,6.22-5.06,11.28-11.28,11.28h-60.58
        c-6.22,0-11.28-5.06-11.28-11.28v-60.58c0-6.22,5.06-11.28,11.28-11.28H579.09 M579.09,111.06h-60.58c-6.63,0-12,5.37-12,12
        v60.58c0,6.63,5.37,12,12,12h60.58c6.63,0,12-5.37,12-12v-60.58C591.09,116.44,585.71,111.06,579.09,111.06L579.09,111.06z' />
  </g>
    <path fill='#ECEEF0' d='M741.54,126.38H611.02c-1.89,0-3.42-1.53-3.42-3.42v-0.5c0-1.89,1.53-3.42,3.42-3.42h130.52
     c1.89,0,3.42,1.53,3.42,3.42v0.5C744.96,124.85,743.43,126.38,741.54,126.38z' />
    <path fill='#ECEEF0' d='M741.54,142.79H611.02c-1.89,0-3.42-1.53-3.42-3.42v-0.5c0-1.89,1.53-3.42,3.42-3.42h130.52
     c1.89,0,3.42,1.53,3.42,3.42v0.5C744.96,141.26,743.43,142.79,741.54,142.79z' />
    <path fill='#ECEEF0' d='M741.54,159.19H611.02c-1.89,0-3.42-1.53-3.42-3.42v-0.5c0-1.89,1.53-3.42,3.42-3.42h130.52
     c1.89,0,3.42,1.53,3.42,3.42v0.5C744.96,157.66,743.43,159.19,741.54,159.19z' />
    <path fill='#ECEEF0' d='M741.54,175.6H611.02c-1.89,0-3.42-1.53-3.42-3.42v-0.5c0-1.89,1.53-3.42,3.42-3.42h130.52
     c1.89,0,3.42,1.53,3.42,3.42v0.5C744.96,174.07,743.43,175.6,741.54,175.6z' />
    <path fill='#ECEEF0' d='M717.17,192.61H611.02c-1.89,0-3.42-1.53-3.42-3.42v-0.5c0-1.89,1.53-3.42,3.42-3.42h106.15
     c1.89,0,3.42,1.53,3.42,3.42v0.5C720.59,191.08,719.06,192.61,717.17,192.61z' />
    <g id='btmA1'>
     <g>
       <g>
           <path fill='${accentColor}' d='M689.7,257.27H562.11c-4.42,0-8-3.58-8-8v-24.21c0-4.42,3.58-8,8-8H689.7c4.42,0,8,3.58,8,8v24.21
              C697.7,253.69,694.12,257.27,689.7,257.27z' />
        </g>
     </g>
      <g>
        <path fill='#FFFFFF' d='M602.31,237.04c1.03,0.44,1.55,1.25,1.55,2.44c0,0.9-0.3,1.59-0.89,2.07s-1.37,0.73-2.32,0.73h-3.64
           c-0.1,0-0.15-0.05-0.15-0.15v-9.93c0-0.1,0.05-0.15,0.15-0.15h3.52c1.01,0,1.8,0.23,2.36,0.69c0.56,0.46,0.84,1.14,0.84,2.02
           c0,1.05-0.47,1.78-1.42,2.18C602.26,236.98,602.26,237.01,602.31,237.04z M598.27,233.33v3.08c0,0.04,0.02,0.06,0.06,0.06h2.2
           c0.56,0,1.01-0.14,1.34-0.42c0.33-0.28,0.49-0.67,0.49-1.15c0-0.5-0.16-0.89-0.49-1.18c-0.33-0.29-0.77-0.44-1.34-0.44h-2.2
           C598.29,233.27,598.27,233.29,598.27,233.33z M601.97,240.59c0.33-0.31,0.49-0.72,0.49-1.25s-0.17-0.94-0.5-1.25
           c-0.33-0.31-0.78-0.46-1.36-0.46h-2.28c-0.04,0-0.06,0.02-0.06,0.06v3.3c0,0.04,0.02,0.06,0.06,0.06h2.32
           C601.2,241.05,601.64,240.9,601.97,240.59z' />
        <path fill='#FFFFFF' d='M610.94,234.85h1.09c0.1,0,0.15,0.05,0.15,0.15v7.14c0,0.1-0.05,0.15-0.15,0.15h-1.09
           c-0.1,0-0.15-0.05-0.15-0.15v-0.54c0-0.02-0.01-0.03-0.02-0.04s-0.03,0-0.05,0.02c-0.39,0.54-0.99,0.8-1.8,0.8
           c-0.48,0-0.92-0.09-1.32-0.28c-0.4-0.18-0.73-0.46-0.97-0.82s-0.36-0.8-0.36-1.31v-4.98c0-0.1,0.05-0.15,0.15-0.15h1.09
           c0.1,0,0.15,0.05,0.15,0.15v4.54c0,0.51,0.13,0.91,0.4,1.2s0.65,0.44,1.13,0.44c0.49,0,0.88-0.15,1.17-0.45s0.44-0.69,0.44-1.19
           v-4.54C610.79,234.89,610.84,234.85,610.94,234.85z' />
        <path fill='#FFFFFF' d='M614.63,245.04v-0.86c0-0.1,0.05-0.15,0.15-0.15h0.03c0.39-0.01,0.7-0.06,0.92-0.15
           c0.22-0.09,0.41-0.25,0.55-0.49c0.15-0.24,0.29-0.6,0.42-1.07c0.01-0.01,0.01-0.04,0-0.09l-2.44-7.23l-0.01-0.06
           c0-0.07,0.04-0.1,0.13-0.1h1.18c0.09,0,0.15,0.04,0.18,0.12l1.65,5.5c0.01,0.02,0.02,0.03,0.04,0.03s0.03-0.01,0.04-0.03
           l1.64-5.5c0.03-0.08,0.09-0.12,0.17-0.12h1.15c0.11,0,0.15,0.05,0.12,0.16l-2.66,7.78c-0.22,0.67-0.45,1.17-0.67,1.5
           c-0.22,0.33-0.52,0.56-0.88,0.69c-0.36,0.14-0.87,0.2-1.52,0.2h-0.1C614.67,245.18,614.63,245.13,614.63,245.04z' />
        <path fill='#FFFFFF' d='M632.62,232.06h1.11c0.1,0,0.15,0.05,0.15,0.15v9.93c0,0.1-0.05,0.15-0.15,0.15h-1.07
           c-0.07,0-0.13-0.03-0.17-0.1l-4.57-7.46c-0.01-0.03-0.02-0.04-0.04-0.04s-0.03,0.02-0.03,0.05l0.01,7.4
           c0,0.1-0.05,0.15-0.15,0.15h-1.11c-0.1,0-0.15-0.05-0.15-0.15v-9.93c0-0.1,0.05-0.15,0.15-0.15h1.08c0.07,0,0.13,0.03,0.18,0.1
           l4.56,7.46c0.01,0.03,0.02,0.04,0.04,0.04s0.03-0.02,0.03-0.05l-0.01-7.4C632.47,232.11,632.52,232.06,632.62,232.06z' />
        <path fill='#FFFFFF' d='M637.99,241.85c-0.54-0.37-0.91-0.87-1.12-1.53c-0.18-0.53-0.26-1.12-0.26-1.78
           c0-0.69,0.08-1.28,0.25-1.75c0.21-0.64,0.59-1.15,1.12-1.51c0.54-0.37,1.17-0.55,1.91-0.55c0.7,0,1.31,0.18,1.83,0.55
           c0.52,0.36,0.89,0.86,1.12,1.5c0.18,0.5,0.26,1.08,0.26,1.75c0,0.68-0.09,1.28-0.26,1.8c-0.21,0.65-0.58,1.16-1.11,1.53
           c-0.53,0.36-1.14,0.55-1.85,0.55C639.15,242.39,638.53,242.21,637.99,241.85z M640.9,240.85c0.29-0.22,0.5-0.53,0.63-0.93
           c0.11-0.37,0.16-0.83,0.16-1.37c0-0.54-0.05-1-0.15-1.36c-0.13-0.4-0.34-0.71-0.63-0.93c-0.29-0.22-0.65-0.33-1.07-0.33
           c-0.4,0-0.74,0.11-1.04,0.33c-0.29,0.22-0.5,0.53-0.63,0.93c-0.11,0.34-0.16,0.79-0.16,1.36c0,0.56,0.05,1.02,0.16,1.37
           c0.12,0.4,0.32,0.71,0.62,0.93c0.3,0.22,0.65,0.33,1.07,0.33C640.26,241.18,640.6,241.07,640.9,240.85z' />
        <path fill='#FFFFFF' d='M646.82,242.16l-2.1-7.15l-0.01-0.04c0-0.08,0.05-0.12,0.15-0.12h1.14c0.09,0,0.14,0.04,0.16,0.13
           l1.39,5.3c0.01,0.02,0.02,0.03,0.04,0.03s0.03-0.01,0.04-0.03l1.42-5.3c0.02-0.09,0.07-0.13,0.16-0.13h1.01
           c0.09,0,0.14,0.04,0.16,0.13l1.45,5.29c0.01,0.02,0.02,0.03,0.04,0.03c0.01,0,0.03-0.01,0.04-0.03l1.45-5.29
           c0.02-0.09,0.07-0.13,0.16-0.13l1.12,0.01c0.05,0,0.08,0.01,0.11,0.04c0.02,0.03,0.03,0.07,0.02,0.12l-2.12,7.14
           c-0.03,0.08-0.08,0.12-0.16,0.12h-1.14c-0.08,0-0.13-0.04-0.16-0.12l-1.43-4.93c-0.01-0.02-0.02-0.03-0.04-0.03
           s-0.03,0.01-0.04,0.03l-1.36,4.92c-0.02,0.09-0.07,0.13-0.16,0.13h-1.15C646.91,242.28,646.85,242.24,646.82,242.16z' />
     </g>
  </g>
    <g>
     <path d='M584.5,67.47V66.4c0-0.12,0.06-0.18,0.18-0.18h0.04c0.49-0.01,0.87-0.07,1.15-0.18c0.28-0.11,0.51-0.31,0.69-0.61
        c0.18-0.3,0.36-0.75,0.53-1.34c0.01-0.01,0.01-0.05,0-0.11l-3.05-9.03l-0.02-0.07c0-0.08,0.05-0.13,0.16-0.13h1.48
        c0.11,0,0.18,0.05,0.22,0.15l2.06,6.88c0.01,0.02,0.03,0.04,0.05,0.04s0.04-0.01,0.05-0.04l2.04-6.88
        c0.04-0.1,0.11-0.15,0.22-0.15h1.44c0.13,0,0.18,0.07,0.15,0.2l-3.32,9.73c-0.28,0.84-0.56,1.46-0.84,1.87
        c-0.28,0.41-0.65,0.7-1.1,0.87s-1.08,0.26-1.9,0.26h-0.13C584.54,67.66,584.5,67.59,584.5,67.47z' />
      <path d='M595.54,63.49c-0.67-0.46-1.14-1.09-1.41-1.91c-0.22-0.66-0.33-1.4-0.33-2.23c0-0.86,0.1-1.59,0.31-2.19
        c0.27-0.8,0.74-1.43,1.41-1.89c0.67-0.46,1.47-0.68,2.39-0.68c0.88,0,1.64,0.23,2.29,0.68c0.65,0.46,1.12,1.08,1.4,1.87
        c0.22,0.62,0.33,1.35,0.33,2.19c0,0.85-0.11,1.6-0.33,2.24c-0.27,0.81-0.73,1.45-1.39,1.91c-0.66,0.46-1.43,0.68-2.32,0.68
        C596.99,64.17,596.21,63.94,595.54,63.49z M599.17,62.24c0.36-0.27,0.63-0.66,0.78-1.16c0.13-0.46,0.2-1.03,0.2-1.72
        c0-0.68-0.06-1.25-0.18-1.7c-0.16-0.5-0.42-0.88-0.78-1.16c-0.37-0.27-0.81-0.41-1.33-0.41c-0.5,0-0.93,0.14-1.3,0.41
        c-0.37,0.27-0.63,0.66-0.78,1.16c-0.13,0.43-0.2,0.99-0.2,1.7c0,0.71,0.07,1.28,0.2,1.72c0.15,0.5,0.4,0.88,0.78,1.16
        s0.82,0.41,1.34,0.41C598.38,62.66,598.8,62.52,599.17,62.24z' />
      <path d='M610.85,54.73h1.37c0.12,0,0.18,0.06,0.18,0.18v8.92c0,0.12-0.06,0.18-0.18,0.18h-1.37c-0.12,0-0.18-0.06-0.18-0.18
        v-0.68c0-0.02-0.01-0.04-0.03-0.05c-0.02-0.01-0.04,0-0.06,0.03c-0.49,0.67-1.23,1-2.24,1c-0.6,0-1.15-0.12-1.65-0.35
        c-0.5-0.23-0.91-0.57-1.21-1.02c-0.3-0.45-0.46-1-0.46-1.64v-6.22c0-0.12,0.06-0.18,0.18-0.18h1.37c0.12,0,0.18,0.06,0.18,0.18
        v5.68c0,0.63,0.17,1.13,0.5,1.5c0.33,0.37,0.81,0.55,1.41,0.55c0.61,0,1.09-0.19,1.46-0.56c0.36-0.37,0.55-0.87,0.55-1.49v-5.68
        C610.67,54.8,610.73,54.73,610.85,54.73z' />
      <path d='M620.99,54.88c0.08,0.04,0.12,0.11,0.09,0.22l-0.29,1.37c-0.01,0.11-0.09,0.15-0.22,0.11c-0.21-0.08-0.45-0.13-0.73-0.13
        l-0.26,0.02c-0.57,0.02-1.04,0.22-1.41,0.59c-0.37,0.37-0.56,0.85-0.56,1.45v5.33c0,0.12-0.06,0.18-0.18,0.18h-1.39
        c-0.12,0-0.18-0.06-0.18-0.18v-8.92c0-0.12,0.06-0.18,0.18-0.18h1.39c0.12,0,0.18,0.06,0.18,0.18v0.97
        c0,0.04,0.01,0.06,0.03,0.06c0.02,0.01,0.03,0,0.05-0.03c0.55-0.85,1.3-1.28,2.24-1.28C620.33,54.64,620.68,54.72,620.99,54.88z'
      />
      <path d='M624.8,63.8c-0.55-0.22-0.98-0.52-1.29-0.9c-0.3-0.38-0.46-0.81-0.46-1.27v-0.2c0-0.12,0.06-0.18,0.18-0.18h1.31
        c0.12,0,0.18,0.06,0.18,0.18v0.09c0,0.34,0.19,0.64,0.56,0.89s0.84,0.38,1.41,0.38c0.55,0,0.99-0.12,1.33-0.36
        c0.34-0.24,0.51-0.54,0.51-0.9c0-0.35-0.16-0.62-0.47-0.79c-0.32-0.18-0.83-0.36-1.53-0.56c-0.69-0.19-1.27-0.39-1.74-0.59
        c-0.47-0.2-0.86-0.49-1.19-0.86c-0.32-0.37-0.48-0.85-0.48-1.43c0-0.81,0.32-1.46,0.96-1.94c0.64-0.48,1.49-0.72,2.55-0.72
        c0.71,0,1.33,0.11,1.87,0.34c0.54,0.23,0.96,0.54,1.25,0.94c0.29,0.4,0.44,0.85,0.44,1.35v0.05c0,0.12-0.06,0.18-0.18,0.18h-1.28
        c-0.12,0-0.18-0.06-0.18-0.18v-0.05c0-0.35-0.17-0.65-0.52-0.89c-0.35-0.24-0.82-0.36-1.41-0.36c-0.54,0-0.97,0.1-1.3,0.31
        c-0.33,0.21-0.5,0.49-0.5,0.84c0,0.35,0.16,0.62,0.48,0.8s0.84,0.38,1.56,0.58c0.71,0.19,1.29,0.39,1.76,0.57
        c0.47,0.19,0.87,0.47,1.2,0.84c0.33,0.37,0.49,0.85,0.49,1.45c0,0.83-0.33,1.48-0.98,1.97c-0.65,0.49-1.52,0.73-2.6,0.73
        C626,64.13,625.35,64.02,624.8,63.8z' />
      <path d='M633.48,52.95c-0.22-0.22-0.33-0.5-0.33-0.84c0-0.34,0.11-0.62,0.33-0.84c0.22-0.22,0.5-0.33,0.84-0.33
        c0.34,0,0.62,0.11,0.84,0.33c0.22,0.22,0.33,0.5,0.33,0.84c0,0.34-0.11,0.62-0.33,0.84c-0.22,0.22-0.5,0.33-0.84,0.33
        C633.98,53.27,633.7,53.17,633.48,52.95z M633.4,63.82v-8.91c0-0.12,0.06-0.18,0.18-0.18h1.39c0.12,0,0.18,0.06,0.18,0.18v8.91
        c0,0.12-0.06,0.18-0.18,0.18h-1.39C633.47,64.01,633.4,63.94,633.4,63.82z' />
      <path d='M643.22,56.18h-1.97c-0.05,0-0.07,0.02-0.07,0.07v4.84c0,0.51,0.12,0.88,0.35,1.1c0.23,0.23,0.59,0.34,1.08,0.34h0.53
        c0.12,0,0.18,0.06,0.18,0.18v1.13c0,0.12-0.06,0.18-0.18,0.18c-0.39,0.02-0.67,0.04-0.84,0.04c-0.92,0-1.62-0.17-2.09-0.5
        c-0.47-0.33-0.7-0.95-0.7-1.85v-5.46c0-0.05-0.02-0.07-0.07-0.07h-1.11c-0.12,0-0.18-0.06-0.18-0.18v-1.08
        c0-0.12,0.06-0.18,0.18-0.18h1.11c0.05,0,0.07-0.02,0.07-0.07v-2.15c0-0.12,0.06-0.18,0.18-0.18H641c0.12,0,0.18,0.06,0.18,0.18
        v2.15c0,0.05,0.02,0.07,0.07,0.07h1.97c0.12,0,0.18,0.06,0.18,0.18v1.08C643.41,56.12,643.34,56.18,643.22,56.18z' />
      <path d='M653.83,59.9c0,0.12-0.06,0.18-0.18,0.18h-5.82c-0.05,0-0.07,0.02-0.07,0.07c0,0.41,0.04,0.73,0.11,0.95
        c0.13,0.49,0.4,0.87,0.81,1.14s0.92,0.41,1.54,0.41c0.9,0,1.59-0.37,2.08-1.11c0.06-0.12,0.14-0.14,0.24-0.05l1.04,0.69
        c0.08,0.07,0.1,0.15,0.05,0.24c-0.34,0.55-0.82,0.98-1.45,1.3c-0.63,0.32-1.34,0.47-2.13,0.47c-0.89,0-1.64-0.19-2.24-0.58
        c-0.61-0.39-1.06-0.94-1.37-1.66c-0.29-0.72-0.44-1.59-0.44-2.61c0-0.89,0.07-1.56,0.2-2.03c0.22-0.84,0.66-1.5,1.31-1.99
        c0.66-0.49,1.45-0.73,2.39-0.73c1.23,0,2.15,0.31,2.76,0.92c0.61,0.61,0.98,1.52,1.11,2.71
        C653.82,58.72,653.84,59.28,653.83,59.9z M648.61,56.5c-0.34,0.26-0.57,0.61-0.69,1.05c-0.08,0.27-0.14,0.61-0.16,1.02
        c0,0.05,0.02,0.07,0.07,0.07H652c0.05,0,0.07-0.02,0.07-0.07c-0.02-0.4-0.06-0.7-0.11-0.89c-0.12-0.47-0.36-0.85-0.72-1.14
        c-0.36-0.29-0.81-0.43-1.34-0.43C649.38,56.1,648.95,56.23,648.61,56.5z' />
      <path d='M656.93,63.7c-0.22-0.22-0.33-0.5-0.33-0.84c0-0.34,0.11-0.62,0.33-0.84c0.22-0.22,0.5-0.33,0.84-0.33
        c0.34,0,0.62,0.11,0.84,0.33c0.22,0.22,0.33,0.5,0.33,0.84c0,0.34-0.11,0.62-0.33,0.84c-0.22,0.22-0.5,0.33-0.84,0.33
        C657.43,64.02,657.15,63.91,656.93,63.7z' />
      <path d='M663.55,63.47c-0.66-0.47-1.11-1.1-1.35-1.91c-0.18-0.56-0.27-1.3-0.27-2.21c0-0.84,0.09-1.58,0.27-2.21
        c0.24-0.78,0.7-1.4,1.36-1.86c0.66-0.46,1.45-0.69,2.36-0.69s1.71,0.23,2.39,0.69c0.68,0.46,1.13,1.05,1.33,1.75
        c0.07,0.19,0.12,0.4,0.15,0.6c0,0.1-0.05,0.16-0.16,0.18l-1.37,0.2h-0.04c-0.09,0-0.15-0.06-0.18-0.16l-0.05-0.29
        c-0.1-0.41-0.33-0.76-0.71-1.04c-0.38-0.28-0.83-0.42-1.37-0.42c-0.52,0-0.97,0.14-1.33,0.42c-0.36,0.28-0.6,0.65-0.71,1.11
        c-0.11,0.44-0.16,1.02-0.16,1.73c0,0.71,0.05,1.28,0.16,1.72c0.12,0.49,0.36,0.87,0.72,1.15c0.36,0.28,0.8,0.42,1.32,0.42
        c0.54,0,0.99-0.14,1.38-0.42c0.38-0.28,0.62-0.64,0.72-1.09c0.01-0.01,0.02-0.03,0.01-0.05c-0.01-0.02,0-0.03,0.01-0.05v-0.07
        c0.04-0.1,0.1-0.13,0.2-0.11l1.37,0.22c0.1,0.02,0.15,0.07,0.15,0.15v0.07c0,0.11-0.03,0.24-0.09,0.4
        c-0.21,0.74-0.65,1.34-1.33,1.79c-0.68,0.45-1.48,0.68-2.39,0.68C665,64.17,664.21,63.94,663.55,63.47z' />
      <path d='M674.08,63.49c-0.67-0.46-1.14-1.09-1.41-1.91c-0.22-0.66-0.33-1.4-0.33-2.23c0-0.86,0.1-1.59,0.31-2.19
        c0.27-0.8,0.74-1.43,1.41-1.89c0.67-0.46,1.47-0.68,2.39-0.68c0.88,0,1.64,0.23,2.29,0.68c0.65,0.46,1.12,1.08,1.4,1.87
        c0.22,0.62,0.33,1.35,0.33,2.19c0,0.85-0.11,1.6-0.33,2.24c-0.27,0.81-0.73,1.45-1.39,1.91c-0.66,0.46-1.43,0.68-2.32,0.68
        C675.54,64.17,674.75,63.94,674.08,63.49z M677.72,62.24c0.36-0.27,0.63-0.66,0.78-1.16c0.13-0.46,0.2-1.03,0.2-1.72
        c0-0.68-0.06-1.25-0.18-1.7c-0.16-0.5-0.42-0.88-0.78-1.16c-0.37-0.27-0.81-0.41-1.33-0.41c-0.5,0-0.93,0.14-1.3,0.41
        c-0.37,0.27-0.63,0.66-0.78,1.16c-0.13,0.43-0.2,0.99-0.2,1.7c0,0.71,0.07,1.28,0.2,1.72c0.15,0.5,0.4,0.88,0.78,1.16
        s0.82,0.41,1.34,0.41C676.92,62.66,677.35,62.52,677.72,62.24z' />
      <path d='M695.53,55.44c0.54,0.55,0.8,1.32,0.8,2.31v6.1c0,0.12-0.06,0.18-0.18,0.18h-1.37c-0.12,0-0.18-0.06-0.18-0.18v-5.73
        c0-0.61-0.17-1.09-0.5-1.45c-0.33-0.36-0.78-0.54-1.34-0.54c-0.56,0-1.02,0.18-1.37,0.53c-0.35,0.35-0.53,0.83-0.53,1.42v5.77
        c0,0.12-0.06,0.18-0.18,0.18h-1.37c-0.12,0-0.18-0.06-0.18-0.18v-5.73c0-0.61-0.17-1.09-0.5-1.45s-0.78-0.54-1.34-0.54
        s-1.01,0.18-1.34,0.53c-0.33,0.35-0.5,0.83-0.5,1.42v5.77c0,0.12-0.06,0.18-0.18,0.18h-1.39c-0.12,0-0.18-0.06-0.18-0.18v-8.92
        c0-0.12,0.06-0.18,0.18-0.18h1.39c0.12,0,0.18,0.06,0.18,0.18v0.62c0,0.02,0.01,0.04,0.03,0.05c0.02,0.01,0.04,0.01,0.06-0.02
        c0.54-0.64,1.31-0.97,2.34-0.97c0.62,0,1.15,0.12,1.59,0.36s0.78,0.59,1.02,1.05c0.04,0.05,0.07,0.05,0.11,0
        c0.29-0.47,0.68-0.83,1.16-1.06c0.48-0.23,1.01-0.35,1.6-0.35C694.26,54.61,694.99,54.88,695.53,55.44z' />
  </g>
    <g>
     <path fill='#55B759' d='M569.49,69.33c-0.65-0.41-1.26-0.78-1.82-1.13c-3.49-2.13-5.79-3.53-5.79-7.18v-10.1h0.86
        c2.75,0,4.18-0.09,6.65-2.2l0.56-0.47l0.56,0.47c2.48,2.11,3.91,2.2,6.65,2.2h0.86v10.1c0,3.65-2.3,5.06-5.79,7.18
        c-0.57,0.35-1.17,0.72-1.82,1.13l-0.46,0.29L569.49,69.33z' />
      <path fill='#FFFFFF' d='M569.95,49.37c2.7,2.3,4.39,2.4,7.21,2.4v9.24c0,3.69-2.57,4.65-7.21,7.58c-4.64-2.93-7.21-3.9-7.21-7.58
        v-9.24C565.56,51.77,567.25,51.67,569.95,49.37 M569.51,59.4l-1.77-1.7l-0.99,0.99l2.77,2.69l4.5-4.58l-0.99-0.99L569.51,59.4
         M569.95,47.11l-1.11,0.95c-2.25,1.91-3.5,1.99-6.1,1.99h-1.72v1.72v9.24c0,4.13,2.6,5.72,6.2,7.92
        c0.56,0.34,1.17,0.71,1.81,1.12l0.92,0.58l0.92-0.58c0.65-0.41,1.25-0.78,1.81-1.12c3.6-2.2,6.2-3.78,6.2-7.92v-9.24v-1.72h-1.72
        c-2.6,0-3.85-0.08-6.1-1.99L569.95,47.11L569.95,47.11z' />
  </g>
    <g id='cursor1A1'>
     <g>
        <path fill='#FFFFFF' d='M693.57,250.03c-0.55,0-1.06,0.17-1.48,0.45c-0.36-1.03-1.33-1.77-2.48-1.77
           c-0.55,0-1.06,0.17-1.48,0.45c-0.36-1.03-1.33-1.77-2.48-1.77c-0.48,0-0.93,0.14-1.32,0.37v-6.31c0-0.7-0.28-1.37-0.77-1.87
           c-0.63-0.63-1.52-0.9-2.4-0.72c-1.22,0.24-2.11,1.37-2.11,2.68v15.31l-1.81-2.06c-1.16-1.33-3.16-1.51-4.53-0.4l-0.46,0.37
           c-0.76,0.61-0.95,1.69-0.44,2.52l4.69,7.61c2,3.46,5.51,5.61,9.16,5.61c5.83,0,10.57-4.74,10.57-10.57v-7.26
           C696.21,251.22,695.02,250.03,693.57,250.03L693.57,250.03z' />
       <path d='M693.57,250.03c-0.55,0-1.06,0.17-1.48,0.45c-0.36-1.03-1.33-1.77-2.48-1.77c-0.55,0-1.06,0.17-1.48,0.45
           c-0.36-1.03-1.33-1.77-2.48-1.77c-0.48,0-0.93,0.14-1.32,0.37v-6.31c0-0.7-0.28-1.37-0.77-1.87c-0.63-0.63-1.52-0.9-2.4-0.72
           c-1.22,0.24-2.11,1.37-2.11,2.68v15.31l-1.81-2.06c-1.16-1.33-3.16-1.51-4.53-0.4l-0.46,0.37c-0.76,0.61-0.95,1.69-0.44,2.52
           l4.69,7.61c2,3.46,5.51,5.61,9.16,5.61c5.83,0,10.57-4.74,10.57-10.57v-7.26C696.21,251.22,695.02,250.03,693.57,250.03
           L693.57,250.03z M685.64,269.18c-3.18,0-6.25-1.9-8.02-4.97l-4.7-7.63c-0.16-0.26-0.1-0.6,0.14-0.79l0.46-0.37
           c0.83-0.66,2.02-0.56,2.72,0.24l2.96,3.39c0.18,0.21,0.47,0.28,0.73,0.18c0.26-0.1,0.43-0.34,0.43-0.62v-17.07
           c0-0.67,0.45-1.27,1.05-1.39c0.45-0.09,0.89,0.04,1.21,0.36c0.25,0.25,0.39,0.58,0.39,0.93v13.21c0,0.36,0.3,0.66,0.66,0.66
           c0.36,0,0.66-0.3,0.66-0.66v-4.62c0-0.73,0.59-1.32,1.32-1.32c0.73,0,1.32,0.59,1.32,1.32v4.62c0,0.36,0.3,0.66,0.66,0.66
           c0.36,0,0.66-0.3,0.66-0.66v-3.3c0-0.73,0.59-1.32,1.32-1.32s1.32,0.59,1.32,1.32v3.3c0,0.36,0.3,0.66,0.66,0.66
           c0.36,0,0.66-0.3,0.66-0.66v-1.98c0-0.73,0.59-1.32,1.32-1.32c0.73,0,1.32,0.59,1.32,1.32v7.26
           C694.89,265.04,690.74,269.18,685.64,269.18L685.64,269.18z' />
     </g>
  </g>
    <g id='clickA1'>
     <path d='M681.68,238.15c0.36,0,0.66-0.3,0.66-0.66v-1.98c0-0.36-0.3-0.66-0.66-0.66c-0.36,0-0.66,0.3-0.66,0.66v1.98
        C681.02,237.85,681.32,238.15,681.68,238.15z' />
      <path d='M685.64,240.79c0,0.36,0.3,0.66,0.66,0.66h1.98c0.36,0,0.66-0.3,0.66-0.66s-0.3-0.66-0.66-0.66h-1.98
        C685.94,240.13,685.64,240.42,685.64,240.79z' />
      <path d='M675.08,241.45h1.98c0.36,0,0.66-0.3,0.66-0.66s-0.3-0.66-0.66-0.66h-1.98c-0.36,0-0.66,0.3-0.66,0.66
        S674.71,241.45,675.08,241.45z' />
      <path d='M677.91,238.61c0.13,0.13,0.3,0.19,0.47,0.19c0.17,0,0.34-0.06,0.47-0.19c0.26-0.26,0.26-0.68,0-0.93l-1.32-1.32
        c-0.26-0.26-0.68-0.26-0.93,0s-0.26,0.68,0,0.93L677.91,238.61L677.91,238.61z' />
      <path d='M684.98,238.81c0.17,0,0.34-0.06,0.47-0.19l1.32-1.32c0.26-0.26,0.26-0.68,0-0.93s-0.68-0.26-0.93,0l-1.32,1.32
        c-0.26,0.26-0.26,0.68,0,0.93C684.64,238.74,684.81,238.81,684.98,238.81L684.98,238.81z' />
  </g>
    <g>
     <path fill='#FFFFFF' d='M544.91,154.13c0.09-0.09,0.14-0.19,0.19-0.28c0.81-1.4,1.62-2.8,2.42-4.2c2.06-3.57,4.12-7.14,6.17-10.7
        c0.02-0.04,0.05-0.08,0.07-0.13c0.19-0.34,0.48-0.55,0.85-0.63c0.33-0.08,0.65-0.05,0.96,0.09c0.25,0.11,0.44,0.3,0.58,0.55
        c0.45,0.79,0.9,1.57,1.36,2.35c2.28,3.94,4.55,7.88,6.83,11.82c2.58,4.47,5.17,8.95,7.75,13.42c0.11,0.19,0.21,0.39,0.24,0.61
        c0.11,0.85-0.58,1.51-1.27,1.51c-0.1,0-0.19,0-0.29,0c-13.8,0-27.61,0-41.41,0c-0.1,0-0.19,0-0.29,0
        c-0.92-0.01-1.61-1.04-1.13-1.94c0.16-0.31,0.34-0.6,0.52-0.9c2.23-3.86,4.46-7.72,6.68-11.57c1.21-2.1,2.42-4.2,3.64-6.3
        c0.02-0.04,0.05-0.08,0.07-0.12c0.31-0.51,0.77-0.74,1.36-0.66c0.42,0.05,0.73,0.28,0.95,0.64c0.18,0.29,0.34,0.58,0.51,0.87
        c1.03,1.78,2.06,3.57,3.09,5.35C544.8,153.98,544.85,154.04,544.91,154.13z M540.01,151.07c-0.06,0.1-0.11,0.16-0.15,0.24
        c-2.35,4.07-4.7,8.15-7.06,12.23c-0.4,0.7-0.81,1.4-1.21,2.1c-0.04,0.07-0.11,0.14-0.08,0.25c0.12,0,0.24,0,0.37,0
        c12.12,0,24.24,0,36.36,0c0.09,0,0.17,0,0.26,0c0.04,0,0.1,0.01,0.13-0.06c-0.03-0.07-0.07-0.14-0.11-0.22
        c-1.72-2.98-3.43-5.95-5.15-8.93c-2.17-3.77-4.35-7.53-6.52-11.3c-0.58-1.01-1.16-2.02-1.75-3.02c-0.04-0.07-0.06-0.16-0.16-0.19
        c-0.05,0.08-0.09,0.15-0.14,0.23c-0.5,0.86-0.99,1.72-1.49,2.57c-2.38,4.12-4.75,8.23-7.13,12.35c-0.05,0.09-0.1,0.18-0.17,0.27
        c-0.55,0.79-1.66,0.78-2.21,0.01c-0.06-0.08-0.1-0.16-0.15-0.25c-0.55-0.96-1.11-1.92-1.66-2.88c-0.61-1.05-1.21-2.1-1.82-3.15
        C540.13,151.25,540.08,151.17,540.01,151.07z' />
  </g>
</g>
  <g id='w6A1'>
  <g id='iconsMoveA1'>
     <g>
        <g>

              <image overflow='visible' opacity='0.22' width='143' height='144' xlink:href='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAI8AAACQCAYAAAAx4zjdAAAACXBIWXMAAAsSAAALEgHS3X78AAAA
GXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAEbhJREFUeNrsnemWozgMhW2g5v2f
tyvg+dM549ZouZJlINVwDicJqQXMx9XiRaU827M927M927M927P9/K0+13bK1p4Gfq7hgeqDG/4n
K+XHwVR/+DnWDweiPfDMP6+rADsLjPbAk3c+9QKYRm9gSwKkPfDkwFAnXNsMeFrS8VtBVG/8/+vA
MfTaRq9/RC1Gjt0CovoB0Hg/jwI1C6aW/PlyiOpNoakTvpt9zW0QmBb4uUshqjeGxvPeA9BV8Fjv
W/D3LgOoXvg/InBUx8/MViLE2UWBaQBIt4Oo3hAaeqyCICGgZQIUURrttQ2ANRq53QoeDzjIK/Je
OhZRo0x4UFCQ9x51mg5QvQAcCSD0vXQMVaosgFDzJIGCfI4oUzTkvxyeOqA2EiTIdx6YzoJHUxLt
mLR7VOkUFdouBgeBIrp7lMgDEBJGe5TlvR8gQFVRlkreT3WYt4vBQfcFPBaBKAsezTxx8FBYqgMo
6/wqA1Mx4LvEbHnAseBZlM+LckyDSTJnERM2Ao4GxqEcQ1XJa8baXZQHBYf7rAHCvS4gTBpIUQVC
4KE38lDA4V4Poh7H7/cUJlR5mnKf2pXwWF0FqJmS4JA+azBZECEARZxlj9pwsBxk7wHoATp+X58G
EQcQB8sQQNtEcAoAjgWNZ5fUyAORFx7EXFHFkGCR9tqBU4gKlUQlupXZGoFmZeBYjc+aEiGOtGW2
vCZLAofCs5PvuM+Vgag3i5wS0ehMAigM1JasOhFwJEhWcmw1fsajQloOSAKoOcHRzFQPxkqgWcjn
akDUq8/CqBACTAig7SbgrMwrfS99J0GkAeRJIEbh0cDZyfs3RPvvc+1fK/NKz7EHRwKo3N1scWBp
4EjQoDunShpEltlCfJ+I6hwCPHu3vz+/CDiVAYg73x6cGvB/3GBtyaojheUWONy+Ce8tkFB4vMqD
dD0cADy7AA8F58Vcx26c59FdLxKJXa48mrmi4bMFzsa8bsJ3GkRe9UEA8oTnGjgSNDsDzksBf1fu
CVUfSWGG1GdLUB0rNF8AcCRYuN0CCTVfHqeZi7QkgCxzxe3rb1BWAo6lklbG+FCuZTh8z0wSot0P
Fjjv/UsBaFMA0sJ41HRp8Fg+j+YgS4qzEnDouUf8Mgu44czzlqQ6FQBmYeCh4Hwx778MmCSA3v+n
MjcCfaKrM7/jVZ1Xpzjc+WvnWxTTuQgmTMownxaqWx2iVUjYSZEVBwt9/yXARU2dZr68fg8SrmvO
suQkvzpwtt+v38J5o9BIn/vfbcLfCKvPlmSqNJOF+DsbA4u0SyqkRV8V9HsieR7ObL1B2gXleTGq
8+0Ax8p4ty7rzP2N0zLMNaA6WrS1CqpD93+Ez5IpsyIvZNgGd43WTbKUh/o7veL0qiOBo6mgZLIk
gAqjQOHIa5uoOlJuZ1WiKQqK9OpRH+6mLM4sMxptWbkdyc/5NpxkC5i+e2IVYEofILYFVKeAqlOF
TsxVUR8K0D8MOJwZQ+ChQEfh8TrMNHu8EbP17VAdzUQegvpQgDzqc2rfFmKuFkV9vgR4NICkyIvz
ec5wmPtXarK2DiCvueLA2RiQFgaexjjOyNgeFaYtwWQVR4jOgbMK4KAA0cgr6vNk9G15lCdqrqSh
HQcB6FAsQjMyzbfpntDyO6sQgnsAkvyehcmZWMqD9m1FHOYXySR7oNFAWRWAGunvakLoHvJ/tsEo
qzpM1+pwmBGAuND9/XdpWkDzd2YpTyOqs5KuiAVQwqaoDueU04f1ICaM+z/T+7a8JgvJLiPhOlUZ
zXRxyoPAM+LzWPBwmWXLx0FGItJxQKugQJL54rLLEjgiUBl9W6ivo/k9lgJxKoQqj2a2POBoylOU
G7064eEUZzf2tfw5mIxTIA6cMtK7PmMAvJVhXgfM2JcjXF+UPE/E3/HC04g5kcyV5hS/4fhickXo
UBRrT5894Z3Ip4FTQdO1KtljFB5UeZBuCWtIhtWzTkPnQzgHK6I6ujCfi9jow7gXbCLkkOpEuie0
lLkGTgXB2ZT+rg2EZzWc5bPgocqzgyaK82u2Dp5NAUib23YYYbvWWcoCNWq2ojMkpDE9lh+EjO/R
4Bk1W5FMc1WcVi4k59RmI7BsQdPlMc/pfVsRkKoDoE1RoDUAjzWKcMTn0ZSHPumH0rMthfZUZTYG
ImvkJLoczWXztqzhqNrcLMQPQsY3e3rULZOVNemvEnOlJQC37pWD5lV847bRSY9TO0YRZ7kE8z7a
NBoUIKRBPSH6iPJQIOgUYaSfamcAQgf5L0VfGKIq+bo05bEcYjSB6M3/rGBIj8zxQiTccpI98BRG
dQ7Qx+kTfJ65akvQZGn30dXXFR2SUUGfZyn6FBwUImm6sdaQa4kPPdVG7nHDGrjFmTSVWkm/VN91
IY24RKZaV1BxrOuGIq6MaMsK6bULssBZgCSjd7px1GRVA6L+eN+nROFZGIB2QF1Qc2WZKalP0tU1
kdmr7lkuzlp3B1nIYC32wk9ZPelWg3JyT3u0+17t/pUCFFlSxmOuSpaznBmqI90UnqVWpFxR9lJz
EXg8eZG+I5ZCtThyYqP+jbaYaHibOenPCxCqStURWUTmaSEOc2U+c1NbaLdEH8IfTp/Q++BkrYKW
Ck8FfZ8INN7VTj0rgWWpTlVAkkDzmHO0myey8pk1ebOdoTzVgKgGzRiysOUCSrK1EurIQt6I09yc
ua8KmnBP5riCznPI71nKvM17YzPXW9YyqZ7OXm+kidbGiK49HVXXkWtNg6cGG7YOAFCMG1MMWGY6
jRUAEiltUAZAme4Yj5gtpNBahHJPLiJykzKCAKt/y4q6JEe6OgGPmiqv4rjMV4bZqgFYqgES2niW
OcqEHemiQb5DHXgroWl1N0TPF7YyM3welHoPCN6bGfmZGebbcyxqhhCFtszsVJ8nw6lEGhE1WUgj
ojcwq/56DTzZHggRHw9Np1ziMGcoktWwnmJvo0ow6zqtB6MmPjRnXttl8KBO84i5qScDlWnush6q
7DTEpfB45BlN4NWrn7qJgKEOr6f0wY9UHm/jfxI0mX7kLa97ueHTGPF5/pbtVtd+R+WxCqu2vxie
9sATb4R214b8G7flA54srijrTwAHLTbLPTTtb4EHvdiIqrRyQv3wxAeiJT1QMx8qGM7lwobsT7SN
XMRA47Xk6xlVWM23a4Hfm6pSy2RQmqNxrKcJbSSrka9QmgZC0YJAtCw1mQGP5597bLmkPs3RqC3g
M8xQjxZoA4+SWKrdHO3ykT6PdYEe5fGag6vMXQMd4Fb0+e/Sz3r8qqb8jdPhacYFNaARkQZCSzMi
ZtFjMqNqYT1AlllvAGDWgxlRIJdzv4F/UFo5PKo83mMaJNo5R84904luAGQNUKQCtM/p4f0Ms+Wx
5SgszQmZ5wnPNMnWA1MGrznaPlMgWiZCE7npdPnYkUZDZD0q7QiQzXBytevnluRF2yfqB7m3LVld
SuApO8DvD6GBrYaUzBc39yrLREXURbqmI+EBywj3U+ChNwIxU5EnTnoCPQ1nzQbgFi3ISO5xCnQU
fbFvaSVUrj1GAErrH9wmmK0RWZYa6QAan65M0R/ngBkpC93AB0WD33owDsff8JqxlG1LBAcBySpa
bx0/FHDoApKl/H+NHCkSy8oiF8PcIjUkPLv0cHkV6DJ4PPmJZjxFXJmh/rX/ntZa4JRHqvpimeMM
1bGq1WgFSHawHTxmHc3uw37QBjSUVf8pEk1YsGjH+goy/QLV/aqjdNhmr0yo02wtbB2tdIw+JBow
GkDNqUKpyuOVc1RxvE8efQJ3QXn6ldV3wSkuRZ/OwkHTnKpTgAdEurZDuFbt5y2YIsC4VGhzmiYr
IvFEUVpDaUXs30vKvZXHKq3YL+OGTAysAyarCKojKUp/Xf2rtktQRRxpa/HNaaG6N3knSfYhQCIV
6nhXjnkVvGi9VGMcnUho9aAXwzRz5bH7Sse78robsBwOgMoILJlJwhowU015+nYFIgpOpC4nsrCl
R3ms2hNSSYCdgeOlgPQClAjxg9B+wdOiLdRpRtSGa0irzgLnt/Rqs5JQfmRJ3ajJOoxrtPZdODYC
EOIHpfeqI5lmxN/ZBSmndce/AXCkm0kXkOTKBqHgWE6yx1mmNdW/hWPf3WdJmaJ+z7CzrMGjAUMz
smj/laY+FJq+CjBSPpr7X73qaIt5Z8KjFailAH0ru6VCFKCjjDvObv9nc5qoCioPGo5z9aO+BWjQ
mpxclT1PDYo6oDoN8O0kldFA+g6aLm+UdUmG2aM6FByuZuZ34euiI+BsJBc0o666x2RJUVYPxi/y
KinRtwGQ5f9c0jGK+D0FVB+r0KpV8ggBR6r22680WpwAoUMuosrzS3gvmTPO90GgsT5Pz/Nwfo+l
Pn2VXynxtwShoaUVV0G5Rmqre+CRuiAQ5dFU6AUozw52VVj5KxOkzak22tL5aK6nL9jK5W2QirxW
TU6kSK3HdEV60SM+zy8AIAkitKsixWRl+jyVgaYfHkHBWUimGKl/rqkOV6CegwdxmkeUx8osI/BI
ChRxnBHlCUMVjba0hBk3JGIvf9bcXEgXQxScXQj5pZJLWnGPbOXZDbNlOc2/GOWJRlxHtslC4EFM
F6I+lYGod16tfioJnK/yZ+lotNZ4RHmsED2iPChAIxBNGY4RMVuS+lRBfQ4Dnig4lup44RkJ1SPw
SOojAcSBY6lOM1Rn2A/K7BgthR/Bx73u4M3T+sgQ1UHMVjRUR80W0rclAYRkoVGIkMkKp/VtSeAU
BqDWAWOBUx3JRlqsXivoykVcpfhLKHqHnSKmiwMI6bLwgpOmOiN5nmqYMc73KeW/kX7o+BlLdSST
hRSt9fZxNWdvuqY+LyHy4iB6gdEWOprQrTAzMszSvKgqPJH9TdqFm1aA5Nu7oV6M4myFL3A7I1RH
8jzIYLBd6H7whOeRPq7hZWdGfZ5mDI04CFQH+IRbTmifEHwZ3RuWvzMz2ooA9FL6sqRBYpEO0dP6
tizfB5kxejAKtRtPdr9TaGiv/CL4O2hdzoxoSxrDZJmvvWADwNDxPJ7scnja8UiGWVrGhJ7EYfg3
iPL043O4bohXsWux1zI+pgedg++ZHaIpkXdMc3Tu+ul5Hs155kA6HMBwJoAWsF+FrohlksnSnPnC
3LwDNF8aSNzno/iHYCBr+JyW5ymg81y6hl0YiDxmoIeIA0YCB1UdaSB8dBGHQ4m8PJMApPlrXlOV
ojaZoTpnvrRxsP1gdORmcE+tBxypaH1JUh7LdHlmjGrz19AZpMjY5RTVyVQepPOULkiAmK+F3ICl
/H9s0OIEx1O0PtKzHpmrzjnU2oRBba76KeBkd09UwIz1iw4cQOP3sz3pFBraQ68Vs0frdxaH8pRi
TwA4in/Bg734V8qwhl6km6ysaEszX5IZk1RIg2hh4KkTwKlB5fEAFFlmpQ2qTclUHc/T5vl9zSRw
O2daJDi07y0z5XWUPfCUEl8R7AA+t+JfGazMMldZPo/VbYE40gdzM+mycIehLgg0FjjelUE001UK
tqagd/Uv7wJOpSRPMc52mJEFkySAmnJz6axPulATB4pXbbLgQcwXskqaBYt3LhayoHm5ymyNmrBi
+CUaGIvjd0sgo+xd3EmLwErBVzSNLCVcHInANBXKrlmJAFQMRcjcRxQnAg9ixmYt0o1OHW4zb/ZM
gFCHuoBAWN+V4svpjCylqylQAZQI+d5jnqaXjZpVLRcFyDJlBYQDVZrqOMcReLSbjM7cHFGZU+qN
zSy1jJiD6nhF3pcBcLzdE14FsiBCgStOtZkCzmx4vACh/lFJACYKD1rrqwWBGlGY02utnlXkPQqR
FwxEZepAe3gKxTWnKmkR062gORsez02rg3ChwGReu9cX8qqVB5pTwDkbnhGIsr67Ch4vCNFhou0u
N/LK/1sHP4+apyyAIiDdHpqr4YlC5AWlTmyHNvBzWbM2251v3p3OIwLIHa6vJR2/DTR3g2eGWtzl
2jJhaJ94oz7h3OqJ/6tNhunW0HwCPFeBcabifBwwnwjPTznvj4blp96E+oDyNPpPu75Wnu3Znu3Z
nu3Znu3Znu2jt38FGADpL3wIBNUehwAAAABJRU5ErkJggg==' transform='matrix(1 0 0 1 372 682)'>
           </image>
          <g>
              <path fill='#FFFFFF' d='M429.05,780.75c-13.04,0-23.64-10.6-23.64-23.64v-28.54c0-13.04,10.6-23.64,23.64-23.64h28.54
                 c13.04,0,23.64,10.6,23.64,23.64v28.54c0,13.04-10.6,23.64-23.64,23.64H429.05z' />
            <path fill='#DEE3E9' d='M457.6,705.28c12.84,0,23.28,10.44,23.28,23.28v28.54c0,12.84-10.44,23.28-23.28,23.28h-28.54
                 c-12.84,0-23.28-10.44-23.28-23.28v-28.54c0-12.84,10.44-23.28,23.28-23.28H457.6 M457.6,704.56h-28.54
                 c-13.25,0-24,10.75-24,24v28.54c0,13.25,10.75,24,24,24h28.54c13.25,0,24-10.75,24-24v-28.54
                 C481.6,715.31,470.85,704.56,457.6,704.56L457.6,704.56z' />
           </g>
        </g>

       <path id='path1903_00000066473335709289276020000004031940774493157820_' fill-rule='evenodd' clip-rule='evenodd' fill='#0A66C2' d='
           M463.23,719.95h-39.01c-1.87,0-3.45,1.48-3.45,3.29v39.19c0,1.82,1.04,3.3,2.91,3.3h39.01c1.87,0,3.86-1.48,3.86-3.3v-39.19
           C466.55,721.42,465.1,719.95,463.23,719.95z M438.21,737.38h6.16v3.14h0.07c0.94-1.69,3.71-3.41,7.15-3.41
           c6.59,0,8.42,3.5,8.42,9.97v12.1h-6.54v-10.91c0-2.9-1.16-5.44-3.86-5.44c-3.29,0-4.85,2.23-4.85,5.88v10.47h-6.54V737.38z
            M427.31,759.18h6.54v-21.8h-6.54V759.18z M434.67,729.76c0,2.26-1.83,4.09-4.09,4.09s-4.09-1.83-4.09-4.09
           c0-2.26,1.83-4.09,4.09-4.09C432.84,725.67,434.67,727.5,434.67,729.76z' />
     </g>
    <g>
        <g>

              <image overflow='visible' opacity='0.22' width='144' height='143' xlink:href='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAJAAAACPCAYAAAAVxlL2AAAACXBIWXMAAAsSAAALEgHS3X78AAAA
GXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAEclJREFUeNrsnYlu47wOhSnZnfd/
3klsXQxwC2g4XA4lekl/GzDqJG1qy58PFy0kerZne7Zne7Zne7Zn++9t5bmu07b2NPRzDQ9YH974
P1UxPxaq8sPPsfwAINoD0PHnVS6+/nbS37UHoPnzKQdeU7kYmPZpIJUPh6YkX9dVALWDgfuRAI0o
ycx7dzBhbfC924JUPgCc2ddXXG8LfDb7+lKQyo3+XwGhKJMwlQvgsSBAP7slSOXG4HjHZQCwOwAk
HUc+98A6FaJy4f8YgaUAEEUUKqMNRhWnBT6LQHcqSOVieBAAEIhGgToDoDbxMwLUJWpUbqA6UUCQ
47sApIHkwdKCQCEgtU8BaFR1rOPo6whImQChyiPBgrw3qkyHQVRO+D5UdSQoigNNASC6A0CWuqA7
AWCdDtF6MTwIFDM7/z8R0xY1XSOK4+27AM33+WpwFHbchHvS7gbQKDzoXp3XKEhnA4SCsyuvi/I5
kpkuZ0BUDgII9XNQUCr4XsS0oWH+jNnSANKA2YXPdwMiz9xZuaQUc7aeDI/0uhrHVYCmKiBVipu0
IwBCVGc3fu7/v5ZehfbuZ/8dEQU6RInWk+HxlIeDUsH3IiBZfpEHUtTviYKzC+8VBSJyQEIhukyB
kO6JCDgaKJHdM22zpgz1exCANHj6fWPn30NEAEgIRFNQHRGFIfB4ivNnX5Rj6z0OYwSiWRMWhceC
Zmew9DDtwv/uzd6pEK3JpisKT3XgWdix9BNVIyQyQyBqAQXiPgsCThWAkY45TChEdBcThmSaCYSH
q80iQLQoYFkwWVEaAabMg8gK2VF4tu7n1kHwfQ0cHus8PYgoW4XWRPXxFEiCZ1EUJ7J7amQpkQeP
1ahevsdylDcFno2BVAWQigOSBRGSI7okCiuK4kgQ1QA0q3Lcv5YUKcOUjSiQZMI4QBY03/tbUNFI
RzCHCFWaMFDrpPrQADhVMVcSMKtynAUREtJ7PlBEfTx43p36vIVz5+28Gee5Kw+GZ8pOD+OLAVNV
QNLA4cBI+6LAJPlFxYFoBiDNhFm+jwbQNzgLU5+3c66kdG00J2Ga1le2TqqP5vOQoT4WPHz/CsDk
+UQWQBEzNmK+NgYRh2fpALKCgQIqYlWUSFKgy8N4xP/xzJcEjfYThUhTodI1cDQfhOZ/PMeZw7My
5VkUeAp4TlyFeA89OfDAQK0J6kNkd5Rq0deiKM4XO/5SIPoS/KPF8IUi4TwppiKS//HU592B8xbU
k58v4tgviln1TNllYTyiOMUxYYsCzJcB0ZeiRJopK0EzFonCIv4PB+jtQF+dm470zFdmyhoYfUFA
rQnq40ViCDySCv3ZfwEwrUJkJvlBaD4oy4RxBdIA+t5fit/jgYzsqBk8PIyfUaIlYMJ6eH4ZMCEq
ZGWmM6MwywfaDPP1cs41As53/qd1ilMFiFrgHrcj80Cjvk8Enl8GRJoKRQFCzViWAnHz9QKjLiRZ
2ZsuTYFKMME4DFAJQFQGILL8n1/CrkEkRWRSNDPqREeGcVgR2He+x1IfCR4LnFV4rzKAmqJCZdaM
rQkmy/oMyQF5SqSBhJgxK59SKDY6sQUcWa3TdGVJQytkp4Hv//4+yZRJO+I4m0BlDmmVwCmGD7Q6
OaBfgBKtigplKNCoH+TlgBahs7QA8HjjiRbluDKINDjSTRhqviIJRCsL/QX6QlyF+r+VorBFCeMJ
SPcXRX1mnGjeXaE5zYji9FAu7PNKfw8NaUpyccofGs0DRWZYRMwY6gtpZkzzgRCAMnvjtU7UN8lD
NaSb6cGzdWZx6wCSvn83TNgpnanoXHcPIsQHspKKkjPNs9KaDxRNIkZ9IFJ64rnZ2pxQvQWSkRtT
IMlEFgUirUc+5Adl+UBF6YWPDONYDVPGoy9UgSrga4yYMGtIB89GL4LpKkCoLsHzZfSljcKjXWta
JpqAJxRVH2kg2Qqq0epkpddAJtrzf6KZaMuM9cpTnGiLDP9pZT/f3fEWeGiQaGzahCFdFV4i0Rr7
7A3p0DpPV6VPTDJhVm/8GQD1meFtwGHemOp8sVTAyjphvbHhaDSW7gON+j+R4RyLkhBcyR7qYWWi
tQiM6JxpPTyE3p2+KMvn6aFZBXiQzLuXDxoK6TPGRGuZaW2inzXjwhrOqo1aXAay0HQCQPymbQF4
NubbcGgWskdianPk0MW20B771Hlh0Tlgi9NLvxiArMbveBHYqPmy4CGwF3wz4FlJHzeEzEypDjje
WkswNJm98dElWjQzNgrRSv5Un6yhrFFfaCdsGEXr4EEUWTNXI0709DaSB4rOzJDssTcrdQHh8mav
Wo04oj6RfjHL72ld4m8R4FkF9fEeuEjkpd3XlK4MLwLzIjMEJCs6i7zn/f5s/kcDh0dQ3BGV1unh
8DTW8an5M9aD4k3tLo6fSrNZ6ZHhHF72dsSUeUBZQz4Xis1MnXGg0flVhUVhlf4do1NZ7/nmKA0C
i6b0EdNlDWNJ94G0DtWZ8B5xtqthGr25YFlL3Glhb2GwUAcRCQD117YZeZ3FSZBGHOhRP+gfhTpq
jURkCTtkiZca8KO8Fc+yAWpgQk4bVloDbRBdIynq+wxHYtmrc0RMGLoGYjUc8gJ+hjjQUSfa8oP6
TTNjO3id6FJ/HjDejNxDo7DRiCwLLM9cja7YOmO+LCWSYONKVMHrtHyaGjRT6QvLr5NqQwbtKEQ1
+Nr6jMDXMyG8FpFp3QFeW0QUaGap41Tl6eU124RR0LkeSUp6i4d7kBQgPTF7vZRw3ahvN5qqmFao
Svmbl6g7enV6LzrMfhLRcgqoIxtddB1V2jKQ40s3YTNPKlr7gigWenqrhlDyNTbwOxrp441RZ7cM
tg8ya+Y0E4Y6yqjEUxAeAlUlkmEuE09hGYQWUQBPKb12igY6t/CBCtCwkVkQyAqwEdij2dcj1LgA
wKC+GtqlVAaVp5wN0Iw9RaR8BFrEvGU8oWVCqaIK5Jm6jPsRhqnSNVsZVBDErkcXpDz6umYgK0Ez
GLnWlPaoB6gKEuZGITqqUcqJ0JRBMCIQog9SWiRaL1aeo6T3LMU5UoW9kQPlDtdYb9KQo35J+QBQ
ZqLacvcHo35Iw376Vm76oP4ogJD655+6tZucQ/uJAFkX1q5olAthOqVM5RUAZd2oRnhZ6jZ5c9qH
goTWhL/Fw1MPbiDrhrZgQ7cDwGgHfUdz2qMFH9AGtrN2jPz+R5kwtIGRWuyRhrvquqLKPgJaVH3b
XRUIvblaHfQWbISITzGjhJQMBykmyVq0Qfv9NtFGU5DVJFgaxWQ4okBeXfQZBZiBqE38rtdmI9A0
R4nbEW1Rg1/YJpSIwEZEnUjkyY046G1S9TzlbMBnzXGWvc9PDybWA74TeXIitR48YLRzkAZ1lYPU
yILJU58GmLKRdmtOBPdRUdjIhXuNQkHokJubdZ1I2I1ch7UCrAUVEnik+Ek1SWWQhmwn7V4Uk/VE
ImC2wGurZKZ17EGFOO7D6rsONlwJNOTI07UHGtNSJa20NZ9+M2O6mnNsLf+iXZ+0VDDSBujDlWbC
1gT5RvM4lhTvpBeqRRqTr8dj+UnWUm4Zy7tQwPxYpcGtB2kPQnSYQ32UEx1RIquRdqFRtYavwbTC
rAqN1M5o4LVYwHjKFXXOLwdoxlneA7BYDVjp30Ut+8/OyDo3w0xZ1209OJvSLki7oUrkPWyHLXEX
eQItaLzl/PmxtvMVUYmBlRHio9dOoHnalWvkx9LPHVCpiHkbSp6uwB8gNccReNAnyNq1qjc76cvJ
SUvcahB5vhCSsEMq7GyA0ljQ7MaDqKkcCk0IrjUIjdS4Xp/NTnYxWuRJ3Ax4+Erwmr+DFBpBfSF0
wXFEVa3dagNPmZFuDwK6h1J8IEnuvRR7BJxtoIH75U6+4dmUc0eLrMyuE03kV+zZBSBQoDZDlbZA
hJbW5THqA2nSj+R3PJB4YVppzeS+3pZXnJavyUOETbvOCN+Rajvf9VP719LxpoCHmrRZ/+fQML4N
wrMZjSotddvD8ya74s3CIjFrvZ4sBYrUDJNgeSvHqCpFI7KpCCwjCkM7+CLwSJWNpcIi1tyohf5e
yFJatzkDINR88WuWasa/lX0z3vfgkUAioHsDhmkdNFcFyEhH/R7eQEv30yqLLZ3LTvnlDjLMV69A
LwbPS4HppYDzBlSogaH9sD+0BsJ3y5FGaplbzmRfvujNwLGW9tWg5QrEV/c6GiAv1/N24Hl1+xs0
b3vQlNEIMNkmDClP7TmTvPbVm4HzcuCxVG+m5KXUV+aZsB10oN8GMC9DhV6ObxT1g6ad6YzO1Ejm
WatCIx1HzdadALIUSDJhf/bf7CeH6WX4QBpIlh/kDdhPrRfm+UFIEs0K1xdDcaJlsa2KNpIf5EEU
jcAQB3pTlOe3cmw52Z5DHR03FRpXvga7MTQ/qIDq0y/pvwnOMlKyKBugmXJPKEDcgebmqYfmt6NG
HCgkNxSdnHCqCdMikH5hbQmkHiKvyo7UzaBBilYtLEkKZJX81hzoTfBtfgcgyjBjKU71GoTFqlbD
4dFkvO9+eFOsVJEXJvOCJQhAWT6Qp0CbkrJ4KX6QBtFoTuiQ2RtZXRlISM8herOfaGkiyzmXzKFW
uRldvb4ByjuqQJYf5CmRFM6P9okN+T8IQJ4fRAZEfdZ3p7/rpm+sO8JSBi3a2pjZ4mUhLQUard7c
EhVoM8J4DaKXkmD0nGh0JkdYidZJ5ZGeVG7G+tccpEgNK36TNiUZ6TnRliMd6UxF+8C0MN7LBWnm
6+WYsGgkdrkJQ/whXvo6shJ9U+D5EtTnDSrQSAWfyBAOLQe0G/1bL2NHHOnRjlQ0YBgGqIFPpaZC
UYg85elV56tTHk19FtKr4oyE8uiMkz1oxt6C0ngZ6i1BfS6dF6YlGPuGJJLrp3tREJrRXgXzVZ3O
1OjgMmQMdAMz0RvQK6/1ib2E7gxPhThIaNb5sEy05Dh7uaFduDkboGxWJMeVZyWsAO+RJiw6/unt
+ERvw3FGB5vdekSiBhYpDnQPDzlPuwUQH3AmjReyIjBk5ftoErEBoXwUImQf8YFS1Gc2kYjkD/rp
NuREPU35eythKMFjdYcgnamzUdjIKEzNsfbei/bGpyYRZ7syrKkxkkONmgcCQuLVSRwi8BwVxkeG
smhDXN8KWNpgsujwjbRVYGfyQIhDTfTvtOMd9Hu0m9B3VywT8Mz2hXmONJIT8ob0ooPrRwaS3aIz
1XOoSVEhAiDqG2IVetsrAE8k/5PVGx+ZWBiZI6bNFxuZEzac98kO4z1TxlVoB27IotwIT3GssB0N
30cVCPGFrGG91pw4ax7ZyHSeNPXJVKBIeL8rJqt/vQjwcCe6gmYrqj4jPhCqQo386c3onPhseC7v
yvDmnPeLHOxA8rB2P/kKHHxwmlRj3ev7Gp3WE4XIW4HDmuaNgIMs9UJZinNEFGZBxMP0fqAZN2va
U9MrCx/nvAGqg3RdeGWVWpIpy1pkwlpnCIm2bjErAwnttRPjy7Fw0BrLI2kmKmKyor4PChBixqKr
klnvRZa6o6NMVxZAXnLRWk5uF25qYyF/EWBCTBVSc50STFjEF0JAQpb2Q5e2Q+CZ3spB3+PVSvV2
Cw4NmBoA52iAomoUWWBU+lsahKfdBaBZiAgAoTqvo/DMlv9ugxARCEV0NVY6G56jAUIhQlUpunvg
HAGQlaGOrA8dXbK3AVCnw5MNUAQiIr9cdRmAjOgYszUDEVFe6QIif3WxtJ72KwCyvhOpIV+cfE0B
f8dTnLMAsm5yRKG876Er4DkKoChEKEgUVBrr++kAgEZ8IwKVJeLjDC8WdSeAZtSIgopSAtBcBRCB
EEQqNtLV8JwB0IwaEQhPBJgzAIqYtigktwHnTIC8/1MmoYr6N0dec0uCaeT4dHjOBGgGpAggRyvO
LEDe6zbwe5eAcxVAUZAyXp99vZGbO/v6UniuBGj0Zs+8d9T1t4nfyyoc3O54A+90HiXp/Yz2yC6h
PeMAt6tv3EL32sqBsJ1homb/tp1wDj9SgWbPq1x4/e2kv20/4UZ92jne5fraRX/7APTh5/vjgPmJ
N6Q8wDyN/1+4tkbP9mzP9mzP9mzP9mzP9iO2/wkwAGzaWFqojvcsAAAAAElFTkSuQmCC' transform='matrix(1 0 0 1 476 672)'>
           </image>
          <g>
              <path fill='#FFFFFF' d='M533.53,770.31c-13.04,0-23.64-10.6-23.64-23.64v-28.54c0-13.04,10.6-23.64,23.64-23.64h28.54
                 c13.04,0,23.64,10.6,23.64,23.64v28.54c0,13.04-10.6,23.64-23.64,23.64H533.53z' />
            <path fill='#DEE3E9' d='M562.08,694.85c12.84,0,23.28,10.44,23.28,23.28v28.54c0,12.84-10.44,23.28-23.28,23.28h-28.54
                 c-12.84,0-23.28-10.44-23.28-23.28v-28.54c0-12.84,10.44-23.28,23.28-23.28H562.08 M562.08,694.13h-28.54
                 c-13.25,0-24,10.75-24,24v28.54c0,13.25,10.75,24,24,24h28.54c13.25,0,24-10.75,24-24v-28.54
                 C586.08,704.87,575.33,694.13,562.08,694.13L562.08,694.13z' />
           </g>
        </g>

      <g id='g17_00000081642381692287341340000002829153743430910108_' transform='matrix(1.3333333,0,0,-1.3333333,-133.33333,799.99999)'>
           <g id='g19_00000117662383590035448360000003133122104301015173_'>
              <g>
                 <defs>
                    <rect id='SVGID_26_' x='476.94' y='16.79' width='67.82' height='67.82' />
                 </defs>
                <clipPath id='SVGID_13_'>
                    <use xlink:href='#SVGID_26_' overflow='visible' />
                 </clipPath>
                <g id='g21_00000126313739236937064780000014838783949596572811_' clip-path='url(#SVGID_13_)'>
                    <g id='g27_00000151502078173822168140000003432155392671506572_' transform='translate(600,350)'>
                       <path id='path29_00000128463202397054882350000007074030862344373381_' fill='#0866FF' d='M-70.98-299.3
                          c0,10.03-8.13,18.17-18.17,18.17s-18.17-8.13-18.17-18.17c0-8.52,5.86-15.67,13.78-17.63v12.08h-3.75v5.55h3.75v2.39
                          c0,6.18,2.8,9.05,8.87,9.05c1.15,0,3.14-0.23,3.95-0.45v-5.03c-0.43,0.05-1.17,0.07-2.1,0.07c-2.98,0-4.13-1.13-4.13-4.06
                          v-1.96h5.93l-1.02-5.55h-4.91v-12.48C-77.95-316.24-70.98-308.59-70.98-299.3' />
                    </g>
                  <g id='g31_00000118358097822397405600000006788115072163067523_' transform='translate(447.9175,273.6036)'>
                       <path id='path33_00000109736663727776835890000002653073052739078316_' fill='#FFFFFF' d='M70.05-228.45l1.02,5.55h-5.93
                          v1.96c0,2.93,1.15,4.06,4.13,4.06c0.93,0,1.67-0.02,2.1-0.07v5.03c-0.81,0.23-2.8,0.45-3.95,0.45
                          c-6.07,0-8.87-2.87-8.87-9.05v-2.39H54.8v-5.55h3.75v-12.08c1.41-0.35,2.88-0.53,4.39-0.53c0.75,0,1.48,0.05,2.2,0.13
                          v12.48H70.05z' />
                    </g>
                 </g>
              </g>
           </g>
        </g>
     </g>
    <g>
        <g>

              <image overflow='visible' opacity='0.22' width='143' height='143' xlink:href='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAI8AAACPCAYAAADDY4iTAAAACXBIWXMAAAsSAAALEgHS3X78AAAA
GXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAEX9JREFUeNrsnYuOq7wOhR1g9vs/
7x4gR0eakfJ7+7LsJNBWIFVtmbYD4WP5kouJnu3Znu3Znu3Znu3ZPn8rz3ldttWnkZ/jf6B6w8b/
VJV8W5jKBx9f+RAg6gPPvGMqN5/7VWDUB57+YykTzqlcBEwd9Lv1gacPhvJm8NRB+18GovJG0BTw
N8oN55690D37boeovNj/LIPf33GuNfC33ve3QlTeAJqS+FyvKo0Gp4JAZD53G0Dl5v+DXHwPJBS0
u+Cpgdc1+b1bICovojYF2Bf5zJVKNAqYCoAUhai+KzxRtbFgKSBQEZhGnH8GHOu5doB1OUTlQnAi
0Gj7kM8gqjZTeVBgtH09UF0KULkRHO/iS+Ag+yIKVCaBwy8mAor2XoMoo0L1leHJgOOpC/JA1elq
eKwLX4H9FQQtokL1FeGxwOEXLALMEgQpqkTZdkB8G1RZ/v84QYAiIE0FaLsQHMQkWdAsCag8iEbD
Y5knfpE5LCUBlHZcpfl7ET5bRgC03QROAcFZjH0ITJq69ZqwLDgWHKfwXJTPIcdXFJiGAbS9CDga
JAu4D1EirkIz4eHqcBrgtM/nz3lwcErzORJ+pxjmqRgAvYTZyoJjgcAfxdiHQtTj/6AhOao2p/Ba
et9efA7SL2weOJradAG1DVSdCDieorSPVYFpcRSpBCHqhQd1hi1QrEdpwCHBf7KO2fKB0gBtg8Gh
IDgIMGsApCxAEbMVMVmWedIeB3utHf/ZmMTF8YXeymyh4CwGOO3zqvzNg8hzpDMARc1VFJqleV0E
iKQtCtAQ9dkmmasoOBowqwDPGoBIAggN30fA44FzNM/rz/PSALQ04ByG438LQKPzPFFwNHVZf45t
dR6WWbOcaCsDzc/JSrJl4OFm6WCvfx+7YLZakBAFmpLfycITHYzFAbLAWQVwtgBM/HeRCKxHebRM
8mnAI4EjQfN7LrugoodzfKdxTtxp7lKfbZDaeH6OB86mgKO9RpUooj4IQL0m6zDA2Zmp2h2fjQIQ
TXGgtw7VIQcaDyQEFumxGiBJfpAEUNbvQeHRfB0Oz968XhulOZj6lMSxFcOEDQndt4GqI4GkqY4G
Dn98BUBaHQe6DDBdSG7HcpJbePafY98beNYGomIoTnGOcXFMWL0ySeh1Q3i94x5AEjBfDJ4vECBP
fbL5HjS/E1GdFqDVMLkFBJkD7QUFXerTG6oXwHTxSEvydzYGzJfxnoNkqc8qJCSz+R5v2IXmKEtO
cgvNrqgm6udoCrgI/k9Vrue0DDMyOc9SHA+cVQDFe2gK5KkP4vd4DYtmlRFf5xeeb0F1CqA6/P+u
hvqUoPpc2j3BL0gEHEl5/iivvxQztjq+D5Jt7jVbvCtCC8254qw/AGl+mqc6GkCLABCa/3Fh2jpN
lqY6i+PraOBsDJY/7PWX4gt5kdeoXA8SbWm5Hc1kfRsqiQJTBZNVFeWxBosNzfNE54d7kRbi63wx
cDSAPPVBI64MPD3K46mOZmI809gO0ViEz0sAEV00JMNyjons4aJWZtgyWwhAUuSFRlyow1wdn0Ny
mE/FZG2gr4OAw//XSv8d43M6KYraY7pGDUNFkoKLA84m+DgaQF8OQFnl6Ym2kDCd53TaiBAFh//+
pgB0CgqkqU/Kcd46TZaX17Eg8vI7fwLma7TyINGW1zUhKc8qwCNlv0nJH0l9ZByY1nSdTqLx8jHM
kflVluogvo8FEPd9rGRhCSqPlpHNJAklhxlJBFowcpDW5jW/UU/leqEdoypkPRlmryfdc5iRcF0L
2bXIKxptjVIeAjpED+XiLgqsHjgHU7EjqEDdpmu2z4Ooj9aXZalQ+ywpj2e2vARhxOfxwvXWXO3C
MUlqI6kXzxO17ScNJuNuwznadPX0qlvRlzdEFHGcvTAeUR4UHu/8olnm9uKvRi+5Zq7aUP/4OUee
JzoE9VkHmK5ueErQ34n6Pl7CUHOita4Kzefx+oqi45izWebFCMctE/XFQvz2eTdulsjUbGSG6fC+
LbRTdAHM1yao0BcA1BZUnh54sk5zOw4ZCcm5qfpi+SGtFz6antCuJ9x5OmLGqDf8tCRN1xoEZ3Pu
RGsQPJJdRtWnHUesgUMB1dnZOe4kj/mOzB7h7ZDqbR/dMRrxeyKZZ2sUoZYgjERaEXi8iGuhf6cN
nwF4uMpsgonyOoEjinvpjNHM+jma34NAtDrgeA3qjQPOjmG2Ms1F6c22uhs2wbfZnZEDEVPlmepp
87bQwddZv8cL5dGpOFLaX4u0RioPkbxcCgJP28WwsejJUh10+jWy8EPKad4CDnIErIwZWw2wrPld
3oxSzd+ZCU9lF4IceA6Kz0uLmKrIzQ87zZkhGZYSRdbeKQBES6ARM8lBNEEoZWCJ/h3aoI0l5tBI
x36QPb0amXKdWUHt0r4txAfqCeE9QCKrZvT2pHt3ZlHS+lVwmtse7lVIIq5JlUHmqCHjzsMR16xl
5aJLxVkrZixBqV6M/zvCZKEdiG3U1UZifNBWz/mi4HiTHW9TnojJKkADeIs5FbAh0fwO0diFPUkA
qFWewsBCTPkCtJNnmoaf44ipN4Vi87o8/6cQtqyctyzdqEWdPL8nclNx5UFWO+tZxGqK4oxIEhbg
b5lVT72FLKOhqHXcvYXdpIUD0PTF6Zz7krxB0KEm3T3sM8yWZ8YyMGUiiAhU2VC9KIlBYvuRG2pR
gMkqDIGKU7JJwmWCnUfMGAVgQgChgL0vARWN3CiWKfSc9Wh6Y0R7XWK2Rsh8to5EpFFQEzrSgfSU
h5Rsc1QxMzfKNGhGma0I5QVwpikow8ii3RFgon1b2VQGBaAhJ7mHVgKKpiBcU3aF2fLuHs+ERCbm
ZYvYoo2MmmQCbxTkZol8HmmfYVnmJQnEyKSi10CI8iDZ76zaUFLByiAQC6BU3o05xWzNUB7UqSxg
g0a/l1GHUVn1jL8YqWR4dZLzNnjQBi8BkDIXkAarTta/ySpOz/dGRJK3wpPxJyLQlAvBoBtgLYF2
LZ+sPL3wlbsaagJgEWWd7r+8Ezwl2djvBM2dSncJYAs92ysp68upy7vB45V2rh8Gj1Tysb7DeS4v
Bkjke58G0ch2uwS+5Q0aR7sz3/2CIxfYq9P+8cqDNlJNmCivyu+rKWidDF/9NHisk4tc/PoiFzOr
nt45V+fGyXyvJtuk3g2PpyYRE6XNzIx+ZiQwNQGN1041CcQtZmwZTWPCllcFNESVMiZxpikZaaqr
c67VuWmm+0lX+zy1Q3lqQLop8LlI49bg70ZuFAsYpEgJAjbSlvC2DQKjArRXsEEjMy+r0eBlgqJk
f6+CylMDwFgqXJOKE0qFLKN/EASsBgBC714CVKunYSP+CnLsKCiRG+zS8H6G2aqgefDKK0Ya2pP6
0Q0bgRd1gK31nKPtg1qDrm2bBE3UbqN1ya1GrY7TSKQvJzLCca6OI4ssgnl23lSos00jgNpuUhet
QU7gGW1Er7KLVn+hJ4+TvSFO4GaJtAPqb3W5JFsSnBKIMgiU4hNoWKsx+TJurVn2lozNqE9NwHOC
N8YJfD5qwt7CbKFK4zWU9t4DR2o0aQHrkUlCbz1mzeyews10Gn/39iGONL0aPLVDvq3GiTyk2QRn
IKIcsTIYkb6KewVAOYDz9IA5r1KgbTAwSCThNcZBen1ODx5uurQlTiyHGV2HGblpvLoR1nl6f6+O
GiMKhGbyU/Bod6TmQ0TsvQXHwRr4ILsAyO9ax4cABF/YqZK8lCzq93hJOq/M0QEAcyjt4KlUNoQf
pjwWMNKdiYTiiE3X7japAXmZoNI8EwOHQzNyHWZ0FXgNCg+YQ1GrAzBtGWBCKrQFGqsYcKFhqSfj
3qOtGrM7MPCwHVkR1YMI7RrwyiZJJbLbZ6kSsqVCWYgy/WPDfJ4KhuRW+cTDaDCp3oJU8Iwf10rY
usgzygd4Bdt24HlXQDoMZYoAM6LbqStJWDqVR5NpXjq6BcdTHg2g1oHuXdjSqzWqVTjWFAd5RAGy
YPLyc5eF6mg2GQVHUh2r7nhRLuRC/13vWCtYli3WZpksrrpSmcjj57zaKsc724fAZDnVSOhO1DGM
d0uojJVpJiBUtRRHgiZad7wt2KqVEJgJjxVRcsX5FsCRoOLg7AH/B4UmHLJvCWB4J2MkLD8BU8Wh
WYPgSDXGR9YYzeZ3DsFUfQsPDlULkeVcZx3mtP+zBU1UCfo9iIMsFSDjACFVgHmN8YPmFKiNhOiW
8rSg/G2A+asAxZXoMHwiqwOVQLN1mc+DdEO0RVR5oVWpskvEVNUGHK3e5qiCbdZ4otPJ8VjK81cB
R4LIggbxe4b0d20DVQf1d1YFnEixMclU/dao0irGFAXEzAIKFYTnAJUnoj6W7xM1X9QDVc+QDOlZ
KtohAbSQXCp6AaMqCdC2yBmvDjNyif1IZWNEeXYBnL+GEkWUJ2qyvKE1MDxaJhlRnKIAdLD+qBae
tQMcXgGYV4+xirb1+jxRX28HTJcFTgSgqUM0eh1mJOoqguIsDTg7YZV4PXBa5ztajyoCz0jlkSKu
vw5EqOnyhm5QL1QjMsxVAahNyJ2sE5NniVH/RgLnS4natCK1Xq4HGZKhVTVGo8xd8Xtaf8dyoHuU
x5vCAycKM0MytH0kgCM5ta36IJVZEHNglY226nFFIy4k0rIy6juoPhZEmumKOMxeUnCK8mhZZQLU
hw+dQMsXav6EpDpa0VupfgMFO0nRMctInsdTn28l8pJyPYcTcUVmmF5S9SaiPhJAPeAcCdXpSRRm
E4QV7I5BIfpWkoSZHnbqNVnZvq2I+pzsDtcAoqDitNBsoOpEHeYS8HfQUQTaOJ1vB6Id8He46mQm
BF6WYUbURzJf0qg/AsGx+sVQ1UFC9ajZItInJR5GZKgNy4h0lkrKo0VaNEp1RmWYLfVplecEHdMK
qA7v3tjILhntZZjR48r2bWnmCxnXow3T6OkUHZLzGdG3panPb5Kwhai9QEen6rTg7OTXG0eju2y0
ZU25OZwbAAHpcPydjM9zWbQVUR9iISwFLo6lOlvTUKvQqar1Z0X8nV54vHHbB+kDxDSQjgA02sQD
T0UvSxKi4bt2Z1rQaLmddoDXITjIWmKwt5hrtG8rA9AOKJI1BLVnLPMteR4r08xT+yd4QZDEIAdm
VfrHoqrTO/UmOmvEGop7BIA5KD7tpvaqzijlkXratQM7gYthZWktpUGd5EzXhAeOZbq84biaKZP2
nYDaoENPb5lurKmPtxJF28tOQEfdwhp+oX/HBC1JcLIzKOpg84XMHD0Im8tuDfoiGjDg/SrlQQAq
ju/TzoA42fMBQoNEWKOn3njqgy5wgEBTAcVBptzcOm8LdaCJ/l2UgJyTbsE4DVgQcCLmqoCJtChA
vauBVNBMTZmrNSvDbJkzMiAiBhsfjaiBYoGD9J95ioPC44XtXt8XuvZOVGnQyOrWAfCe/+PV526H
byz07xThE4QlA06mzGJk8l90WTlvdTBvZTAKOsldKjRipaxseWd+URfjvWeS0FzObHgyKhRZmxCd
ATodnBlJwqgDLSlQYd9tF2U6gyqD+jm9C36j4Xtmcc/oejtTwvJZyoP6DZoKaUo04mH1Xc2EJ2rG
sg9KglNfDZ4sQFGQKPA3BJbSqbrRHBAFgMgAcwk4M+CJAOT5QgSCUYDfuQseIr8yTfQ94tdcUsBu
VgXdAuwvoBpFIMv4NqPgyfhCFAQkqzZT/J6Z5ZcjAKEwEeHR0yvCQwk4Ir93CTRXwDMKoghQGfM0
Ex5KXHxUVW5Rm6vh8f5PSb7OAjPznCOlIlGoMtBMB+dKeHogisCBJP3uhAdVpsjnLofmLniQ/1kG
v7/6fCMXtvf9beDcBU8Wot59s86/dnxu1AS8+ooX8FWOoQzaP+JY6mDIepzdeueFW+l1tlFqMfuG
qBO/+/LAvJryjDiucuP514u+V9/9Ir3bMb7C+dWbvvvA8+bH+3HAfNrFKA8oT8N/+nlVerZne7Zn
e7Zne7Zne7a32/4nwACTTSLxb1ub1QAAAABJRU5ErkJggg==' transform='matrix(1 0 0 1 583 685)'>
           </image>
          <g>
              <path fill='#FFFFFF' d='M640.05,783.31c-13.04,0-23.64-10.6-23.64-23.64v-28.54c0-13.04,10.6-23.64,23.64-23.64h28.54
                 c13.04,0,23.64,10.6,23.64,23.64v28.54c0,13.04-10.6,23.64-23.64,23.64H640.05z' />
            <path fill='#DEE3E9' d='M668.6,707.85c12.84,0,23.28,10.44,23.28,23.28v28.54c0,12.84-10.44,23.28-23.28,23.28h-28.54
                 c-12.84,0-23.28-10.44-23.28-23.28v-28.54c0-12.84,10.44-23.28,23.28-23.28H668.6 M668.6,707.13h-28.54
                 c-13.25,0-24,10.75-24,24v28.54c0,13.25,10.75,24,24,24h28.54c13.25,0,24-10.75,24-24v-28.54
                 C692.6,717.87,681.85,707.13,668.6,707.13L668.6,707.13z' />
           </g>
        </g>
      <g>
           <path fill='#F9AB00' d='M674.78,761.87c0,3.2-2.6,5.9-5.8,5.9c-0.2,0-0.5,0-0.7,0c-3-0.4-5.2-3.1-5.1-6.1v-34.8
              c-0.1-3,2.1-5.6,5.1-6.1c3.2-0.4,6.1,1.9,6.5,5.1c0,0.2,0,0.5,0,0.7V761.87z' />
        <path fill='#E37400' d='M638.08,756.17c3.2,0,5.8,2.6,5.8,5.8c0,3.2-2.6,5.8-5.8,5.8c-3.2,0-5.8-2.6-5.8-5.8l0,0
              C632.18,758.77,634.88,756.17,638.08,756.17z M653.38,738.47c-3.2,0.2-5.7,2.9-5.7,6.1v15.7c0,4.2,1.9,6.8,4.6,7.4
              c3.2,0.6,6.2-1.4,6.9-4.6c0.1-0.4,0.1-0.8,0.1-1.2v-17.5c0-3.2-2.6-5.9-5.8-5.9H653.38z' />
        </g>
     </g>
    <g>
        <g>

              <image overflow='visible' opacity='0.22' width='144' height='143' xlink:href='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAJAAAACPCAYAAAAVxlL2AAAACXBIWXMAAAsSAAALEgHS3X78AAAA
GXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAEbJJREFUeNrsnYuuo7oOhp1A5/2f
dwrJ0ZF2tbM9sf3bCZeuAQmVsrpaCF9+X3IjerZne7Zne7Zne7Zn+/u29NzbaVt9Cvm5hweqH1D4
P1k1vw6q9MOvMX05EPUB6JxruxKyetL/1QegedeTDr6vdNBDrJMgqQ9A/utIk85fBVCddP5WIKWb
/36afO5KE1Ynn7sFSOlLwBl9f+Y9V8ffRt9fDlK6GTwJhCINwpROhMcDRQ187lKI0s3BsY5TALAr
AbKOa/D/LgMpXQxPBBDr1QvTSDkgDjAKTQVg8oJUvx2gUXC01xlAHQEQ+uDb1+qE6jYgpZvAg4Ji
AeSF6WyAJGgsmKJAHQ5ROhEeTREQcKRz0nv+PRHT5gFIO9Zg8b6XoEJAqt8AUAqoDgKOtFuQRc3a
DIAQONC/ad/lUaN6Z4A0eDTV8UKTHUBJMI1AJIXYlvLM3BGYDodoPRmeCDTZeY4MsDy+URSgCDgF
PMeh6V1bYse181zqnQCKwMPfa1Bk8Bxq7iSIEJAQeBCAivC+fU3KZxHI09EQpQvhSQAwvVfrM/z7
CARpNkAoPC0w0nHvFTVxkmmbYs5mmrAoPFxJsnIsnbNAstQoUpkkpxmFpwdM732rIqV5tZSop0DT
TNcsgNIAPBY43p1/jwYTYspQgBAFKiA82p4aePjvFQAiyXQNQbVeDI8FzWK8b8/1VMkLkdeEIX6P
BI+07533vev/QFP+uUcJpAqAEoboCBPmgccCZ2HnpPM5aNK8EKG5Hs1kWeBk9j51QOJlXdj/Rpzq
UxXIUp8IPEvnlR8vwud6ICEQeU2ZFyALnr15Xf55zQ0MO/N99k7lbIEZgSgE1XqQ6fLAgwBj7ZIS
jaiQ1/+RAOIQ7ex4Z8d7A1JmIH0g0q61AJXgdnkgmgRPu6+O96gSSQlHD0SesJ0D1IOnt28dX47v
u3GdRQjTk+IXucFaDzBdElA9eNqHvnbgaF9X4W+LoGJRFfL4QJb5ktRHA+ejPptw7WgCMQtqxOG5
LAojp+lKguOrgdLbNYiWoClDEoq9BCISeRUDoK15XZj6bOC1tteUmSnzjjBxAbVOUB80wywpkAXM
C4TJ8okkgEiJbiIOtOY4c4C2f66/B0/PhHnzVIk51ZI5uyyM10xXFgBaDHheneOXApMGEaJCSLOG
1nzBE3oSRBY8vWtPhgnTTChXoir8/1A72Xqw+kgZZg5RD5hX572mSJYp64X1BJoGyw/S/B/Jcd4a
iN7KNSPqo3Xv6H1HBRzq0zPRBJqvpWPCJHikvQdSq0T8d5IR1cyMwhD1aQH6KNBbUB8LoPa3l44K
VfYdtaOsYRUazQNZnbmy4DxLJqyF5Jdw/OqYuaWjRFmp0YgCoT5QbUwFh0hTn7UB6N1AhAIkqd8i
mDKeI5qiQuuA0nhyQKjzzIH5xY4liFYHQBlwoqNhfOmYMU19WnM7Ak8RlKd2zFgSVCgUka0Hq4/k
9ywKPC8GjwYRN2WSGYs40ZEorOdEa87zZpguVHU4TEvnfO95Hd6U4ZmgQOtymg0n+iWoEAqR5Ez3
Wus1EzYrEy2F71Lk9Q7AI6ULlkaFimIZKuBYn94WZuV+MqhAvxwQWdHYSBiPRj0EKtDKIELVRwN0
7UDU3jNXIXI6zipQ6wTzhYbyGjwRiCQV0nIqZyqQZMJ6CcNeWVYAnlZ1JIiKoUCXhfE0AM8ChvG/
AH+o/V+pkTUdqEBIJro1YQuY8+n5V1rHs0J/tuJnw6Guo/7QOhh9JUfjKZKJfgnO9MtQIcsHQgHy
KJAVifUettZcQQ7F4d1A2nv2qNBwt471APOVFSd6CZgyKSeEKpCUByI6rj8QN2GLoA5WY6cGz974
VS1EO9ldQTRfyOUHrSeZLykPtILNGRI8EQU6A6DK/JMWog10mCVw1g5E/H57sBbF1wpHY7M71Xtb
4tHWeKRpQwIoGwm6ke4cqB/UdlOVGkd5RpvD82IAbUJl2Y17nmrG1qD/I40ypYATLflCSMu8BpCU
AzoDoNrxRXYwz6OpzsqON6DCZMOEER3cnUOaFMGTTPTmhDTfCOkjhDjQUROGZqQz9cd19aItCbyX
ANBGev9wadhUEZ5XOKQfNWFoH+j2vNQN1XKs0b3XjIEoUMQHkhQodyCywOnljTYnNNqgS6R1X2sf
60I1c3KFEYcaVSLLZzrK/4mYsUR/dqWwTNfCoqqVQWQ11yDgePyg6Y2pZNTanrnzwIOOzFgJG+qT
HeZrlgIR/dkLsPdb3GStrMnD6rWI9L5E5gcY2tYBYLyTRGUQqMi4MGlsmWW+ZgNE9N+JEKTZMBbB
55EUCB19os0TgFT4KU0ZlpOMJhlHBxeiSiWN9EAbUCMmTOvdJ3Up7UHE27GsQZYZ9HnQGdxoNJQ/
qjuHNRmUtXtqHFIbe3MFecCRnExtJrB2woP291sFWjqt59q4f2sQ5ajpckdiIz6QJY1J8IWQJKNW
oFqUJf1GZBgPgQUt/a0N51vlyQyahTV5ZEflmTVPUjgSm9kaT4RPkOmZPErr04zMZBYdSEgBoHoq
VNh1105mGp21BDVX3tA9HIkdPUOZZwJNCygLLHTuRJoAUa/9SBp7xc1Xe20FrCjJcJIjoftopZnW
mEqKb+FRpGSYoAyCiM4hPaI8SYHJqlDcN/JEqp5Z2GZFnNMBSkZ0Fp2+NxtgJYolyLQ0xIgJsxxp
qYkAmQ/SO59kZFL2KVue4Pd480aeWVqtSA4ZJIiM/0oTygDNiSFlgqpy1FTTLFXKNG9LTpNGztqT
HL9BZPd5nlUTvbklj5lHTRUB8FhlFyqLo5xoZO0vr3oQYNvThNrlnSfaisqo06xhVYgZlWy24oYV
KCpxHpOC5JdS4DfIUAEKFKyVP4ksaocARcDfD3OWjzJh3iwn0vxBFDNlo7CPBhNoaoPAZJ6lTmgZ
RirIJT6QJ9GI1J4RNUBM6qwEYlSpCACAnMBEyygMUz4YmBmpAMQhtHynw6XcgGOWOUQ/P11prlAg
j0lDzqXgQzq6EL0q4wEj8rcEwDYtEs0TVcXjL9EBMFyhOEeAh0aUt7i/fJOCjEZF6QtAiYB9lNqm
nwjQN6rHtyjaX6FAn60+DHzflm8CTg3CVL8EvHpCRalXlFW+sCCtmx694W9UNGT15VvdVz6wpley
Ry94Hnw9AJp6Qs3vlUevbKwVEHvfZa3eXO8C0FlyWx0FX8GHc4UioSbL+5CtckO+D10+/DKAaqBW
SjVytMAQkGaayTpJta0VmCvw26coUZ5Y2+qAgiAOdQVr9FlSXgcVCXnIljmrzvKd7vDnwYLywIAs
k02Er3+O+hDI9UZU01MWZKgJBcqjGib9FCVaA3RG+g7XwQKyTN2M65zpJ1XAzHnWmq+Dle4wE3ZE
j0TL0UWWykZWxEELut2kju5HwFJJX0XHo0See/ZahiHI8kHgWE4fOmV/AZUHLcCZ0m7dbwSM3j1r
a2Icod6nKFAkp1Ed4HgLWPotaQbSngLVAxSIAg9YKosilE8BVQmNHA8HCF3ppTpqnVZIyLnPCE+p
EKXxWtQBbCR890BTjPsrwrFUkazKNSNCO9QHithttJCsc3wMurRynzYhQqRgkciyANBo66wW4b6t
yoXmk24BUHXAVEA4pAKMKJC1gvFMBxpZArwCoEiqpH0e8XumJlPXCYXncZYLAJO278L5rECC5rrS
JHhIUQIJEmspg91QJA9MSGABw7UGC1YyCZ7oSlsSWzvPlw7g8y+3Zqw0JoqvHyo50cmhtFZFKiAk
0v0jZVEUnynSVFQ9MK1OaHoFjIbpXqXZlQL7QMT/Dxnd0Rsx6vWDrGXAtWWfLLWRFGgHyi2iQEMR
2eqQ6wTIfVSBdqVW9mBqFUgboJc74MwY9uNd/lK6n+heJgN1mQ80YrI01WnXVOfn+ZJJyAzwM6f5
tTLPmgJp+8Ze+bEE0W5AU8jnC52WSExA2r5MqI18ruQPPJsCzkL6pN9HKZAEUG/hXet1AyGy1IhD
NKUJY1YYr/k+SNhqgSPBIy3WRgJEif47JyFRfKknb/5HutcN3Hfl/A7A4wnvyatKa0BtJL8INWO7
4iRuHXD4wrQWOO266XyipjJoxiznmZQQXbrPt/C6Ce8t84aCZDX4QjCtAWh4u5Jn+WtJgTg0LTjt
stja6sbt77aTeI+uF+9RHyJ75eZWVd7g6waat2g0FvaHVqdsJ4cCef2ejZmqRVGfXtjdWymwUHy5
y5kLzkn+TwtKu3N4+DkJHlSFbtWUIS08m6m/dmjpRFWLAA5XHy0HxddQ3wUFGpmxvjoA6inQJpiw
/++/heM34AMh4JTZ/k80D+T1gzSztTCINHCsNUbbJZOkBWi9S36ngPp4FejNwPktQPQ2HG2vDzTs
/0QVSPKD0IThAkBk+TvUaXP6LJOELvk004QhEZilQC1AGkRvA572uAphvKe/UFiBqlCI0gztHogy
gygrDx1d2XhlEGXFhFkzuM5QICQHpAH0WzFn78EobLgVfrYPlIDCLMyk7CyvY0VMGkC9NdS9yz4h
kVgNKJCVrng7INpAn0hLJCLm6/AoTOsmyqfxzx3lySyjjCyUxs2WlF9Z6c8lkRAFsgDyONCW77cL
EdZvBaJ3AB4UIqS3QQgg1IxZTnRqCrGFpAdRNqI9LZvN/SlkCexoHmhUgfZOqI5C9AZ9oAKCc2lT
huRM88VEJFO2ETb9nWQSX43yeNZN9S4JEAVISyRuQj7otxGVbQZESAg/xS8aGVjYO0YVyLsMkfRQ
2nXVN5KX/bZ8oEhTBtqFdVcy75IKaXmhHjxbsCljyHyNtIVVJcy14NnJN0E5v/G94zhL6mMtvGvN
s4xEYUg/oF4ob6mQtmvwWOZrmvqM5IGSQS2HiBg8u0N5pIjrxRxnaynsmVGYJ4yX/CCplb0H0gbk
gRB4pk99M5KJrkCI3T6QYkCEOs57R302kldy9iwPNdOEWQ6/5A+hjatWDggZ6TrcoDqjQ5nV0Zyr
0AceIrxrrPYAVrKXxk6TAEKdaMQPsiDajOYLCSTNiaaZ6jPaGq+BxIe3cBVC/R1v2O5Vn1lRGJoL
knokSF01rE5mSJfW23TnQCOy9gLaAX8FaPHWWrYLyzbv5FseG0kiRvpEexpUtREXGwCTtyNZIXxO
oVPyQJoKWRMrFQAeC6BFabKw1lafoT4RM1bJHtvVAwPtXO+JvrR7OCUPpLWDaSFv2z8IzT9IAC2C
+uSA+qBLCtQARMUww6NDfaQBht7RGKdnoq1xYVJ+qGfOKggO7w7S83cs5ZlhviJ+kDSoEh1MKP29
OBtOkWkAT8kDWSqkXURR8j38AeQOSJn+7G2YHfDMWFOsDqqQNFJ1B1+L4jhrENFseGaF8RGINHPW
G1nRArSDqoOuckwDCoT4QUhkpk0ggYxAReA5ZJ7E2fMDRSHSCj43n21h2YPweHsiEhDuRiHyjNz1
zBfkXSXgsqHNiC9UFYhSp82JF7hmohCTZZmu2QAhUZkGUSV7GhdkUikC/Z5LFUgb6kMONeKzZfAh
yYXkTmcWOBo0M02YlRdCprezZmKzTFV01tZbmDCPKauGQlThe4phqrTuGhY0swBCQCoGUJHp62pA
cYbVaNaUb1YexVr2W9qz8/0RZms0IvP4RpFzBMJTZ8MzEyAvRGQoBQLTCDhnAeQFKbrTFfDMBmgE
oog6aZ8nMFyfcf9Rc0YOMNDJEaxcz+0B8kBEwANHgCHymawrAIoCRc7IatqQ5SsB0r7XUiOPOpFT
cdJB9482VFoAeI8ReA8D52iAvBChQKG+DerrHA0QAWqBKhVipk5RnbMAmgUSBYFBYDkDIBQoBCqv
6hwKz1kAWb+TgscRaM64X1SRPIBEFkipVz/Ys38LdXQjn7sTQJ4QOxqK17s81DuBNArJmffqebij
7y8B50qAIiAdAUs6CRivOnmhuQScOwA0+rDTxPs6a33VOun85eB8toXus6VJnzurUtQD/q8e/Ns/
UoGuUI+rTNjo/9Vvf1jfdH13urd60f/+9QB967VeCdzzUP4yqOrzAJ77+vGgPNuzPduzPduzPduz
Pdu/2/8EGACLEFOAxEueBAAAAABJRU5ErkJggg==' transform='matrix(1 0 0 1 267 705)'>
           </image>
          <g>
              <path fill='#FFFFFF' d='M324.57,803.64c-13.04,0-23.64-10.6-23.64-23.64v-28.54c0-13.04,10.6-23.64,23.64-23.64h28.54
                 c13.04,0,23.64,10.6,23.64,23.64V780c0,13.04-10.6,23.64-23.64,23.64H324.57z' />
            <path fill='#DEE3E9' d='M353.12,728.17c12.84,0,23.28,10.44,23.28,23.28V780c0,12.84-10.44,23.28-23.28,23.28h-28.54
                 c-12.84,0-23.28-10.44-23.28-23.28v-28.54c0-12.84,10.44-23.28,23.28-23.28H353.12 M353.12,727.45h-28.54
                 c-13.25,0-24,10.75-24,24V780c0,13.25,10.75,24,24,24h28.54c13.25,0,24-10.75,24-24v-28.54
                 C377.12,738.2,366.37,727.45,353.12,727.45L353.12,727.45z' />
           </g>
        </g>
      <g>
           <path fill='#FF004F' d='M349.19,758.98c3.13,2.24,6.96,3.55,11.11,3.55v-7.96c-0.78,0-1.57-0.08-2.33-0.24v6.27
              c-4.14,0-7.97-1.32-11.11-3.55v16.25c0,8.13-6.59,14.72-14.73,14.72c-3.04,0-5.86-0.92-8.2-2.49c2.67,2.73,6.4,4.43,10.53,4.43
              c8.14,0,14.73-6.59,14.73-14.72L349.19,758.98L349.19,758.98z M352.06,750.95c-1.6-1.75-2.65-4-2.88-6.5v-1.02h-2.21
              C347.53,746.6,349.43,749.31,352.06,750.95L352.06,750.95z M329.07,779.29c-0.89-1.17-1.38-2.6-1.37-4.08
              c0-3.72,3.02-6.73,6.74-6.73c0.69,0,1.38,0.11,2.04,0.32v-8.14c-0.77-0.11-1.55-0.15-2.33-0.13v6.34
              c-0.66-0.21-1.35-0.32-2.04-0.32c-3.72,0-6.74,3.01-6.74,6.73C325.36,775.91,326.87,778.18,329.07,779.29z' />
        <path d='M346.85,757.05c3.13,2.24,6.96,3.55,11.11,3.55v-6.27c-2.31-0.49-4.36-1.7-5.9-3.38c-2.63-1.64-4.53-4.35-5.09-7.52
              h-5.81v31.81c-0.01,3.71-3.02,6.71-6.74,6.71c-2.19,0-4.13-1.04-5.36-2.66c-2.2-1.11-3.71-3.39-3.71-6.02
              c0-3.72,3.02-6.73,6.74-6.73c0.71,0,1.4,0.11,2.04,0.32v-6.34c-7.99,0.17-14.42,6.69-14.42,14.72c0,4.01,1.6,7.64,4.2,10.29
              c2.34,1.57,5.16,2.49,8.2,2.49c8.13,0,14.73-6.59,14.73-14.72L346.85,757.05L346.85,757.05z' />
        <path fill='#00F2EA' d='M357.96,754.33v-1.7c-2.08,0-4.13-0.58-5.9-1.68C353.63,752.66,355.69,753.84,357.96,754.33z
               M346.98,743.43c-0.05-0.3-0.09-0.61-0.12-0.91v-1.02h-8.02v31.81c-0.01,3.71-3.02,6.71-6.74,6.71c-1.09,0-2.12-0.26-3.03-0.72
              c1.23,1.61,3.18,2.66,5.36,2.66c3.71,0,6.72-3,6.74-6.71v-31.81H346.98z M334.15,760.52v-1.8c-0.67-0.09-1.35-0.14-2.02-0.14
              c-8.14,0-14.73,6.59-14.73,14.72c0,5.1,2.59,9.59,6.53,12.23c-2.6-2.65-4.2-6.29-4.2-10.29
              C319.73,767.21,326.15,760.68,334.15,760.52z' />
        </g>
     </g>
    <g>
        <g>

              <image overflow='visible' opacity='0.22' width='144' height='143' xlink:href='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAJAAAACPCAYAAAAVxlL2AAAACXBIWXMAAAsSAAALEgHS3X78AAAA
GXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAEbJJREFUeNrsnYuuo7oOhp1A5/2f
dwrJ0ZF2tbM9sf3bCZeuAQmVsrpaCF9+X3IjerZne7Zne7Zne7Zn+/u29NzbaVt9Cvm5hweqH1D4
P1k1vw6q9MOvMX05EPUB6JxruxKyetL/1QegedeTDr6vdNBDrJMgqQ9A/utIk85fBVCddP5WIKWb
/36afO5KE1Ynn7sFSOlLwBl9f+Y9V8ffRt9fDlK6GTwJhCINwpROhMcDRQ187lKI0s3BsY5TALAr
AbKOa/D/LgMpXQxPBBDr1QvTSDkgDjAKTQVg8oJUvx2gUXC01xlAHQEQ+uDb1+qE6jYgpZvAg4Ji
AeSF6WyAJGgsmKJAHQ5ROhEeTREQcKRz0nv+PRHT5gFIO9Zg8b6XoEJAqt8AUAqoDgKOtFuQRc3a
DIAQONC/ad/lUaN6Z4A0eDTV8UKTHUBJMI1AJIXYlvLM3BGYDodoPRmeCDTZeY4MsDy+URSgCDgF
PMeh6V1bYse181zqnQCKwMPfa1Bk8Bxq7iSIEJAQeBCAivC+fU3KZxHI09EQpQvhSQAwvVfrM/z7
CARpNkAoPC0w0nHvFTVxkmmbYs5mmrAoPFxJsnIsnbNAstQoUpkkpxmFpwdM732rIqV5tZSop0DT
TNcsgNIAPBY43p1/jwYTYspQgBAFKiA82p4aePjvFQAiyXQNQbVeDI8FzWK8b8/1VMkLkdeEIX6P
BI+07533vev/QFP+uUcJpAqAEoboCBPmgccCZ2HnpPM5aNK8EKG5Hs1kWeBk9j51QOJlXdj/Rpzq
UxXIUp8IPEvnlR8vwud6ICEQeU2ZFyALnr15Xf55zQ0MO/N99k7lbIEZgSgE1XqQ6fLAgwBj7ZIS
jaiQ1/+RAOIQ7ex4Z8d7A1JmIH0g0q61AJXgdnkgmgRPu6+O96gSSQlHD0SesJ0D1IOnt28dX47v
u3GdRQjTk+IXucFaDzBdElA9eNqHvnbgaF9X4W+LoGJRFfL4QJb5ktRHA+ejPptw7WgCMQtqxOG5
LAojp+lKguOrgdLbNYiWoClDEoq9BCISeRUDoK15XZj6bOC1tteUmSnzjjBxAbVOUB80wywpkAXM
C4TJ8okkgEiJbiIOtOY4c4C2f66/B0/PhHnzVIk51ZI5uyyM10xXFgBaDHheneOXApMGEaJCSLOG
1nzBE3oSRBY8vWtPhgnTTChXoir8/1A72Xqw+kgZZg5RD5hX572mSJYp64X1BJoGyw/S/B/Jcd4a
iN7KNSPqo3Xv6H1HBRzq0zPRBJqvpWPCJHikvQdSq0T8d5IR1cyMwhD1aQH6KNBbUB8LoPa3l44K
VfYdtaOsYRUazQNZnbmy4DxLJqyF5Jdw/OqYuaWjRFmp0YgCoT5QbUwFh0hTn7UB6N1AhAIkqd8i
mDKeI5qiQuuA0nhyQKjzzIH5xY4liFYHQBlwoqNhfOmYMU19WnM7Ak8RlKd2zFgSVCgUka0Hq4/k
9ywKPC8GjwYRN2WSGYs40ZEorOdEa87zZpguVHU4TEvnfO95Hd6U4ZmgQOtymg0n+iWoEAqR5Ez3
Wus1EzYrEy2F71Lk9Q7AI6ULlkaFimIZKuBYn94WZuV+MqhAvxwQWdHYSBiPRj0EKtDKIELVRwN0
7UDU3jNXIXI6zipQ6wTzhYbyGjwRiCQV0nIqZyqQZMJ6CcNeWVYAnlZ1JIiKoUCXhfE0AM8ChvG/
AH+o/V+pkTUdqEBIJro1YQuY8+n5V1rHs0J/tuJnw6Guo/7QOhh9JUfjKZKJfgnO9MtQIcsHQgHy
KJAVifUettZcQQ7F4d1A2nv2qNBwt471APOVFSd6CZgyKSeEKpCUByI6rj8QN2GLoA5WY6cGz974
VS1EO9ldQTRfyOUHrSeZLykPtILNGRI8EQU6A6DK/JMWog10mCVw1g5E/H57sBbF1wpHY7M71Xtb
4tHWeKRpQwIoGwm6ke4cqB/UdlOVGkd5RpvD82IAbUJl2Y17nmrG1qD/I40ypYATLflCSMu8BpCU
AzoDoNrxRXYwz6OpzsqON6DCZMOEER3cnUOaFMGTTPTmhDTfCOkjhDjQUROGZqQz9cd19aItCbyX
ANBGev9wadhUEZ5XOKQfNWFoH+j2vNQN1XKs0b3XjIEoUMQHkhQodyCywOnljTYnNNqgS6R1X2sf
60I1c3KFEYcaVSLLZzrK/4mYsUR/dqWwTNfCoqqVQWQ11yDgePyg6Y2pZNTanrnzwIOOzFgJG+qT
HeZrlgIR/dkLsPdb3GStrMnD6rWI9L5E5gcY2tYBYLyTRGUQqMi4MGlsmWW+ZgNE9N+JEKTZMBbB
55EUCB19os0TgFT4KU0ZlpOMJhlHBxeiSiWN9EAbUCMmTOvdJ3Up7UHE27GsQZYZ9HnQGdxoNJQ/
qjuHNRmUtXtqHFIbe3MFecCRnExtJrB2woP291sFWjqt59q4f2sQ5ajpckdiIz6QJY1J8IWQJKNW
oFqUJf1GZBgPgQUt/a0N51vlyQyahTV5ZEflmTVPUjgSm9kaT4RPkOmZPErr04zMZBYdSEgBoHoq
VNh1105mGp21BDVX3tA9HIkdPUOZZwJNCygLLHTuRJoAUa/9SBp7xc1Xe20FrCjJcJIjoftopZnW
mEqKb+FRpGSYoAyCiM4hPaI8SYHJqlDcN/JEqp5Z2GZFnNMBSkZ0Fp2+NxtgJYolyLQ0xIgJsxxp
qYkAmQ/SO59kZFL2KVue4Pd480aeWVqtSA4ZJIiM/0oTygDNiSFlgqpy1FTTLFXKNG9LTpNGztqT
HL9BZPd5nlUTvbklj5lHTRUB8FhlFyqLo5xoZO0vr3oQYNvThNrlnSfaisqo06xhVYgZlWy24oYV
KCpxHpOC5JdS4DfIUAEKFKyVP4ksaocARcDfD3OWjzJh3iwn0vxBFDNlo7CPBhNoaoPAZJ6lTmgZ
RirIJT6QJ9GI1J4RNUBM6qwEYlSpCACAnMBEyygMUz4YmBmpAMQhtHynw6XcgGOWOUQ/P11prlAg
j0lDzqXgQzq6EL0q4wEj8rcEwDYtEs0TVcXjL9EBMFyhOEeAh0aUt7i/fJOCjEZF6QtAiYB9lNqm
nwjQN6rHtyjaX6FAn60+DHzflm8CTg3CVL8EvHpCRalXlFW+sCCtmx694W9UNGT15VvdVz6wpley
Ry94Hnw9AJp6Qs3vlUevbKwVEHvfZa3eXO8C0FlyWx0FX8GHc4UioSbL+5CtckO+D10+/DKAaqBW
SjVytMAQkGaayTpJta0VmCvw26coUZ5Y2+qAgiAOdQVr9FlSXgcVCXnIljmrzvKd7vDnwYLywIAs
k02Er3+O+hDI9UZU01MWZKgJBcqjGib9FCVaA3RG+g7XwQKyTN2M65zpJ1XAzHnWmq+Dle4wE3ZE
j0TL0UWWykZWxEELut2kju5HwFJJX0XHo0See/ZahiHI8kHgWE4fOmV/AZUHLcCZ0m7dbwSM3j1r
a2Icod6nKFAkp1Ed4HgLWPotaQbSngLVAxSIAg9YKosilE8BVQmNHA8HCF3ppTpqnVZIyLnPCE+p
EKXxWtQBbCR890BTjPsrwrFUkazKNSNCO9QHithttJCsc3wMurRynzYhQqRgkciyANBo66wW4b6t
yoXmk24BUHXAVEA4pAKMKJC1gvFMBxpZArwCoEiqpH0e8XumJlPXCYXncZYLAJO278L5rECC5rrS
JHhIUQIJEmspg91QJA9MSGABw7UGC1YyCZ7oSlsSWzvPlw7g8y+3Zqw0JoqvHyo50cmhtFZFKiAk
0v0jZVEUnynSVFQ9MK1OaHoFjIbpXqXZlQL7QMT/Dxnd0Rsx6vWDrGXAtWWfLLWRFGgHyi2iQEMR
2eqQ6wTIfVSBdqVW9mBqFUgboJc74MwY9uNd/lK6n+heJgN1mQ80YrI01WnXVOfn+ZJJyAzwM6f5
tTLPmgJp+8Ze+bEE0W5AU8jnC52WSExA2r5MqI18ruQPPJsCzkL6pN9HKZAEUG/hXet1AyGy1IhD
NKUJY1YYr/k+SNhqgSPBIy3WRgJEif47JyFRfKknb/5HutcN3Hfl/A7A4wnvyatKa0BtJL8INWO7
4iRuHXD4wrQWOO266XyipjJoxiznmZQQXbrPt/C6Ce8t84aCZDX4QjCtAWh4u5Jn+WtJgTg0LTjt
stja6sbt77aTeI+uF+9RHyJ75eZWVd7g6waat2g0FvaHVqdsJ4cCef2ejZmqRVGfXtjdWymwUHy5
y5kLzkn+TwtKu3N4+DkJHlSFbtWUIS08m6m/dmjpRFWLAA5XHy0HxddQ3wUFGpmxvjoA6inQJpiw
/++/heM34AMh4JTZ/k80D+T1gzSztTCINHCsNUbbJZOkBWi9S36ngPp4FejNwPktQPQ2HG2vDzTs
/0QVSPKD0IThAkBk+TvUaXP6LJOELvk004QhEZilQC1AGkRvA572uAphvKe/UFiBqlCI0gztHogy
gygrDx1d2XhlEGXFhFkzuM5QICQHpAH0WzFn78EobLgVfrYPlIDCLMyk7CyvY0VMGkC9NdS9yz4h
kVgNKJCVrng7INpAn0hLJCLm6/AoTOsmyqfxzx3lySyjjCyUxs2WlF9Z6c8lkRAFsgDyONCW77cL
EdZvBaJ3AB4UIqS3QQgg1IxZTnRqCrGFpAdRNqI9LZvN/SlkCexoHmhUgfZOqI5C9AZ9oAKCc2lT
huRM88VEJFO2ETb9nWQSX43yeNZN9S4JEAVISyRuQj7otxGVbQZESAg/xS8aGVjYO0YVyLsMkfRQ
2nXVN5KX/bZ8oEhTBtqFdVcy75IKaXmhHjxbsCljyHyNtIVVJcy14NnJN0E5v/G94zhL6mMtvGvN
s4xEYUg/oF4ob6mQtmvwWOZrmvqM5IGSQS2HiBg8u0N5pIjrxRxnaynsmVGYJ4yX/CCplb0H0gbk
gRB4pk99M5KJrkCI3T6QYkCEOs57R302kldy9iwPNdOEWQ6/5A+hjatWDggZ6TrcoDqjQ5nV0Zyr
0AceIrxrrPYAVrKXxk6TAEKdaMQPsiDajOYLCSTNiaaZ6jPaGq+BxIe3cBVC/R1v2O5Vn1lRGJoL
knokSF01rE5mSJfW23TnQCOy9gLaAX8FaPHWWrYLyzbv5FseG0kiRvpEexpUtREXGwCTtyNZIXxO
oVPyQJoKWRMrFQAeC6BFabKw1lafoT4RM1bJHtvVAwPtXO+JvrR7OCUPpLWDaSFv2z8IzT9IAC2C
+uSA+qBLCtQARMUww6NDfaQBht7RGKdnoq1xYVJ+qGfOKggO7w7S83cs5ZlhviJ+kDSoEh1MKP29
OBtOkWkAT8kDWSqkXURR8j38AeQOSJn+7G2YHfDMWFOsDqqQNFJ1B1+L4jhrENFseGaF8RGINHPW
G1nRArSDqoOuckwDCoT4QUhkpk0ggYxAReA5ZJ7E2fMDRSHSCj43n21h2YPweHsiEhDuRiHyjNz1
zBfkXSXgsqHNiC9UFYhSp82JF7hmohCTZZmu2QAhUZkGUSV7GhdkUikC/Z5LFUgb6kMONeKzZfAh
yYXkTmcWOBo0M02YlRdCprezZmKzTFV01tZbmDCPKauGQlThe4phqrTuGhY0swBCQCoGUJHp62pA
cYbVaNaUb1YexVr2W9qz8/0RZms0IvP4RpFzBMJTZ8MzEyAvRGQoBQLTCDhnAeQFKbrTFfDMBmgE
oog6aZ8nMFyfcf9Rc0YOMNDJEaxcz+0B8kBEwANHgCHymawrAIoCRc7IatqQ5SsB0r7XUiOPOpFT
cdJB9482VFoAeI8ReA8D52iAvBChQKG+DerrHA0QAWqBKhVipk5RnbMAmgUSBYFBYDkDIBQoBCqv
6hwKz1kAWb+TgscRaM64X1SRPIBEFkipVz/Ys38LdXQjn7sTQJ4QOxqK17s81DuBNArJmffqebij
7y8B50qAIiAdAUs6CRivOnmhuQScOwA0+rDTxPs6a33VOun85eB8toXus6VJnzurUtQD/q8e/Ns/
UoGuUI+rTNjo/9Vvf1jfdH13urd60f/+9QB967VeCdzzUP4yqOrzAJ77+vGgPNuzPduzPduzPduz
Pdu/2/8EGACLEFOAxEueBAAAAABJRU5ErkJggg==' transform='matrix(1 0 0 1 689 705)'>
           </image>
          <g>
              <path fill='#FFFFFF' d='M746.58,803.64c-13.04,0-23.64-10.6-23.64-23.64v-28.54c0-13.04,10.6-23.64,23.64-23.64h28.54
                 c13.04,0,23.64,10.6,23.64,23.64V780c0,13.04-10.6,23.64-23.64,23.64H746.58z' />
            <path fill='#DEE3E9' d='M775.12,728.17c12.84,0,23.28,10.44,23.28,23.28V780c0,12.84-10.44,23.28-23.28,23.28h-28.54
                 c-12.84,0-23.28-10.44-23.28-23.28v-28.54c0-12.84,10.44-23.28,23.28-23.28H775.12 M775.12,727.45h-28.54
                 c-13.25,0-24,10.75-24,24V780c0,13.25,10.75,24,24,24h28.54c13.25,0,24-10.75,24-24v-28.54
                 C799.12,738.2,788.37,727.45,775.12,727.45L775.12,727.45z' />
           </g>
        </g>
      <g>
           <path fill='#3C8BD9' d='M753.48,748.5c0.45-1.17,1.06-2.25,1.97-3.12c3.64-3.55,9.66-2.66,12.13,1.8
              c1.86,3.38,3.83,6.69,5.74,10.03c3.2,5.55,6.43,11.11,9.59,16.68c2.66,4.66-0.22,10.55-5.5,11.35c-3.23,0.48-6.26-1-7.93-3.9
              c-2.8-4.89-5.63-9.77-8.43-14.64c-0.06-0.11-0.13-0.2-0.2-0.3c-0.3-0.24-0.43-0.59-0.61-0.91c-1.24-2.19-2.53-4.37-3.77-6.54
              c-0.8-1.41-1.63-2.8-2.43-4.22c-0.72-1.26-1.06-2.64-1.02-4.09C753.05,749.91,753.15,749.17,753.48,748.5' />
        <path fill='#FABC04' d='M753.48,748.5c-0.17,0.67-0.32,1.34-0.35,2.04c-0.06,1.56,0.33,3.01,1.11,4.37
              c2.04,3.51,4.09,7.04,6.11,10.57c0.19,0.32,0.33,0.63,0.52,0.93c-1.11,1.93-2.23,3.85-3.36,5.78c-1.56,2.69-3.12,5.41-4.7,8.1
              c-0.07,0-0.09-0.04-0.11-0.09c-0.02-0.15,0.04-0.28,0.07-0.43c0.76-2.79,0.13-5.26-1.78-7.37c-1.17-1.28-2.66-2.01-4.37-2.25
              c-2.23-0.32-4.2,0.26-5.96,1.65c-0.32,0.24-0.52,0.59-0.89,0.78c-0.07,0-0.11-0.04-0.13-0.09c0.89-1.54,1.76-3.08,2.66-4.63
              c3.68-6.39,7.36-12.78,11.05-19.15C753.39,748.63,753.44,748.57,753.48,748.5' />
        <path fill='#34A852' d='M739.72,772.54c0.35-0.32,0.69-0.65,1.06-0.95c4.51-3.57,11.29-0.98,12.28,4.66
              c0.24,1.36,0.11,2.66-0.3,3.96c-0.02,0.11-0.04,0.2-0.07,0.32c-0.17,0.3-0.32,0.61-0.5,0.91c-1.65,2.73-4.09,4.09-7.28,3.88
              c-3.66-0.26-6.54-3.01-7.04-6.65c-0.24-1.76,0.11-3.42,1.02-4.94c0.19-0.33,0.41-0.63,0.61-0.97
              C739.59,772.69,739.55,772.54,739.72,772.54' />
        <path fill='#FABC04' d='M739.72,772.54c-0.07,0.07-0.07,0.2-0.2,0.22c-0.02-0.13,0.06-0.2,0.13-0.3L739.72,772.54' />
        <path fill='#E1C025' d='M752.68,780.52c-0.07-0.13,0-0.22,0.07-0.32c0.02,0.02,0.06,0.06,0.07,0.07L752.68,780.52' />
        </g>
     </g>
    <g>
        <g>

              <image overflow='visible' opacity='0.22' width='143' height='143' xlink:href='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAI8AAACPCAYAAADDY4iTAAAACXBIWXMAAAsSAAALEgHS3X78AAAA
GXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAEWZJREFUeNrsnelyrDgMhS1D5v2f
9wbw/JmuYhQtR7IN3blQRdHd6SRgPo4WLyrl2Z7t2Z7t2Z7t2Z7t92/0XNdlW3sa+bmGB6gPbfjf
qpQfCRT98nOkD4ehPfDMPy+68frbRb/XHnj6z4UmXhNNvoltwN9uDzyxc6BBn98JTxv0+duARG/8
v2nwZ3ebrTb4s9shog+Apvf91dfbAj/rfX8rRPRG4BAIBHWCRBeDEwGiJb53G0D0xtB4rykB193w
eK9b8vdugYhuBCcDh3eMgtTbBlGlacBnrROoywCiN4YGOVIAuBlKhCqN9hkCThS+ywCiNwAnCgfy
+l3h0cBBXmfUaSpEdBE4iNpYr6Pv+euMOYvCk4Um+l4D63IVWm8CR1MU6T0Ffq79rTvh8cBpDjit
4AlDOh0lWGgkQHQzOCgw2d1SoF6AEF8EgaVn98wb6tTfCo8FDr9JUVgq+BkC0VXwZKA5nPcRmC4B
aL0IHALgqcb7qhxRmJDoDIVIC8Wj5ulgryv7jAyoIiZsuLma5fOgfo2nMtJRex1Vo5nwoIpzGMdD
8F8O5e8fHQB1A7UOVp0MOAgo2mcWTChAiL+GhOQRtZFgOdj+UiJiABWmVpZjPRWgdQI4BQRHgya6
awDVpP8ThcdTnkOBB9n5uR+nYwHNWHMgSQO0DgLHirDI8WWsfXHeWzBpAJWgAqGOMgKOBs/ufLYz
gKT/j5qwYf7PKJ+HBqjNIrz2jihEEd+HOiIsz1Rp+y4c63/HFzi7okSFmbkMQCmg1oHmKgKOpTKL
8Fr7mQfSCPOFmKsCRFOesuz/XcsZHGKvdyFvVQyAJEDeXnksk+VBg+4cJg4S94WikVcUoKji7Ayc
/QTOeeeKsxvnzAGimc7zOkh1vFyOpTYchFV5Lb23lAiBZ5TyROHZGTwbA2hzzn03zu8wrqeNVKHR
SUI0l2MpzXo6rsJnKEQZ9Yn6PJ7JQsFZGET1BJB0vki2+Ah+P6w+60DVoaCpkqDh4PCdf4+DpPlA
aOQ1Ah4phyP5N9zPeUF0Vp0NgF1zjImZMK2T9PJQ3UuuaSH5ogCkwfJl/EwCyHKgJYAi8KCRlheO
c/O0KuDUgPJo53mUnz3tXv4HhmmdoDqSr2OZKw2WL+WoqZGlPlKaIJrv8fI7Wk5HUx0OziIEAB44
mtnkCkSG33NpqI6ojme6LHC+hNdfgBItSvSV8XusDDMaomu+zhmeF0DfwrnXoKnie2UAlSKP9Umr
zzpRdVB/hwPk7RpES0B9ZkVbUt/V7sDzUp5vx1wRAIwEkOdAp9Vnnag6WhbZiqzOkPyjvJdM2QKq
z/ncRpotT3leAG0CPN+G4mSA4Tu/Lw2MslyYevI8yPBQKxkogbMyUP5RQPpKqI/kyI+MtqyM8guY
lUVWi6M6JQDPcbpmD6AuxUHhoU7V0aKtxVCcs9L84wBkqY+UMCTDl/BMl+f3IPD0qA4CD/d3DsH3
scY5T8vzoJnlrL+zGvB4APHIy8r3oA6z1phRh1nKJGuqEwVHSkLWU36nKsqDgmPC1JskRDpDI2br
SzBXHkCa6dJ8HiRU9xoP6ZrQzNam+GYV8HPQcUCVqY9kmhsQwqeVhwIARYZdeMnBCEA88pKUZynY
8IxRfVuW8uxOPqcEwXkBeR7GsbAO0ir4PkN613uGZFhDMSpourQcDwIQ93u8ZKGVYe7pVZf8Hp4g
PEdboxTnDM5yguZgf/tQHhh0XI8K2YgMcwnmeCpgtrSQ3TNdi2MSeiItS3kKmOep5eewC091DscB
P1/vrlyzB5DXYTqtbwvtSV8U5fHM15cTviPKk00QIj6P17fFb+oG5nK8iM0DyALHu75hGeaI+qDd
Eh5AX0AYH1GeK+GRbvy555z3liOmav/vOnm4r6mspT7FSRh2Kw8BSoNEWZGQfXE6R70uCy1JGMnx
oBlm1O85mMkiwMexkour0qnKlb1HfYb2qmfW2ckMO10DEK2Ow9zrLPfAI/WqS8NJEXC+2HE1euOr
4fdYvQGI3yMCNcrn8fyfyLhlDaKvYg/d8OCRcjxIn1akj6uUn1OHyXnyJVO1C7mhs1lHTBYy8bHL
7xnVMYrOCEUVyBqGiowoRHrUM+Ag3RS1/JzxuQPQcNO0M2i2gk0EIMdUl1F+zzpAdTyT5QGEqI81
MH5VlAcJ01GTFTVdJHQL7IapWgWANgCa6MhJMkCaNhgMmdedna8VmXZjKY83locGKY+X65FMlddP
JSlPRG2iJgsZppGCx4u0ogoUmWK8OFChs0ut3vQywGwR0M/Fpwafv7+coDlnhzVAvHC8Km3sKY6m
PkPyPF6kFfV9qtNhKkHjOdje7FFrAFjEbDXh59IUXm8puOXkGy0sD7QA7eLNEPGgIUcI4IhrxBhm
72n2Ii8q2OIG2UUOaomPWfYeJG3yHJWfg7Mkc7Ww71gTIdFrjywvI93DcMR19UIHFkg9y6z0zFOP
+juaxPOGrgwgPkiLDGhGAmMleNPO8kh4SomvL4gu6kQdCjMyv5PZKgOlGQ8Jmp23rrsGFadr610l
A10ciRxnLgpFLfgydMi6PJkkISUeKmmAltYuCCSROfmRiYNTlcfzHVDzVQdAEVnEcpTJsnwfVJGr
AU0dCElkIa52t9kqhv8TMXHkPHkVBGfkYpaa+jSjn8ib1xZ9sKIrwBaj/bsyzXWCne9VpYwT6MFa
gIhw1IMyIiLNLlhegMBgmNmqQZmmDphQhSjAExS5eejTOOpBQc999Or2Xig+PCiYMZLQezpLABwC
n9ZRDeX9Xkv8Pc8XQhUo0l7U8aC3kcoz2ox50Q4FAPXMUTQzHjVXkagT9T+iD1cp/tiqyPnCVqYm
ABjxXcQMEdg9Qp3nQxMUyrtpkYx9BAQCvksd132Z8nh+SVR5vHAbDclnFWvJwOKdExKpjn743wIe
tMGp4+b0qsiVxeqQEk6UVC66Epq7fJ7e0Ld0wFcuAqX3+5T8fJSywsBdrTy9T+2VYMwGrMdfo3do
l/pmDTo75/Sp21ted/2wJ/Vvg+YOU/zR8DybvrUHHrsRvLLO7WHoUZ4IaJHi85+iFkjJa69q8q+H
J1IXHFWXBt6cdzMjbSJ8o6CC/069+Sm0Zhq0IERX3cwRfxNdeQNpj+Y8WNMeonqh4niNo11s9Pca
eHNmPAjW/0SgaMA1t8RDMwWimgRhZMM3Q4LRqnoRn+Eq9cne0BZUohZ4gN5eeSxb7F1gVnmQpx8x
n1kwGvi/mvPwWA+J9N1oINECD567rYNAaYADhyy/jzSm9n+ln2llgq7Oo1hgRNrSA9f7H1GL0K08
veFxS8KC7JGIpEdtMlCgCtWAB6uACnRpeH+F2SqOekRgQUxiA1Wvp2GRGxYxSd5+JNuozYRonQgN
alpGNFQLNBSfKjwqBxPxU6xV47VSk2j7REP2NFDrYHUpiafsKH5Ncq0wiFcqyFpvT1rIMZvHiarp
kXhQjo4HLOL0T4WHV0xpJVefwQMCfRJ5dZeDgYGs/kClL8EYKZdtXZNVT0L7/V4z9lZmC5Vnr/Ga
0oDS53y52OPk02lLndCA6408KC2gsNb1RpVrWiS5DgQHAekofhnpI7lLynNeodSrS06d11sKVtLo
CFzvHvx+VoFug6eBmWJLvqXG2ou+hP6iNB5XnpdJqgwSyWlGVr9A+5EsP00rl21dMwKT5wui2X3Y
D1oBMLwFGaOmC23Qw2hYCaC9YPOaWpEXNqKAvKNV/6Ty2OeHQANoN9rBMuVRh3q48mQkHFGcIyDR
u9HAL3DOS+Rby9XW03HUAk+RYrXeQ7ArwFifHx3mqyeKTpktNKSNmqgdaEy+HvFe/r9EvjUBrha5
xrg3hz7j65QSq62+Gder/Wx3wIo60l0D7XpC9UxkYZkma3+Bcz5qC1W+znEp+oLas5XHe1g25+gB
hjrWSD9hOhLrSRJSp5k6AGCkRaytskN8tVFerLW3UJulOMXw6XiR2o0BE3mPwIQCdGu0hTrNCDgb
260CHcjK6nwRyKNgq4RRp8lCKhyfS2N/C6+lXQMJSQVkHOchxdqsiIs6QnLJr5FqjqPwNAce6oQn
klU+ilxn9Hy93+xavdcSSJoCedB0O8sWPBYwPKWPdkNo1X7PKsN9m28FGqQmp7X8LGK2KKk6mvJI
Kqspz3cHOFnHOez/rEETRaDyRH0dDZrXa7RC3sryQCg80WJtEXikh2VnoPw5AXN+zYHaDHA8/weJ
sm7JMEdURwNHgsZTnPP/4WWizwCNrDHqjSdCzfTGAPmjgIP4QpEE4uUdo4jfU0D1sRSHQ+OF49w0
RKsbowt69zjLWpjOTZSnPt8GOBnV8bqVpuV5JL8HUZ0XELwxvYo1VlrgKD/rVdUSr/Y3Qnm8uuqb
oDybAM4fQYm4L7Q7EHldFV5fnQvSGlQbze+RbihfLp+DtBW8IEdxFIdXAY4UMRvt81hhupSS+HbU
54+hPp7qHGXiEI1ZvepWYfnzzdzAkNpSHSnk71UeCuR4kA7R13Ezcj2e8nw7Pg9quoaYrJ5oqyny
Lo2nOfd4S5VctgQ4WqH6s/+UUR4KPChZ5fHgQZxnz3QhDnOXyULgQec+SeaLZ3SlAvXequaWI76f
ANqKXN046jBTUGmRHI8Wbe1CGC6Zqz+dpmvKcIyM2dLUhwT55kVauQKhK5dr4HyVn7XHrXqcaNU/
6lAer19LC9e3QN4HNVue6nT7QSM7RjXzxWuL753gHAya1VEdrY6VlyjUoi3Pz8v25X0bZswCZws6
zNkp2UP7tjRwShIgL/moqY5msl4A0aD+rRZwmL0uGe6rfAMQbYbZOowuiimq05PnIceMtSBAVied
pzqLkRwcXW8Uma16gOqzKQqkmTKvi0LrHD0SqjM9w9wUWdfyPmczsQPDH5DuDWnoRrSQaybaQlIU
kt8TAcjrWe9xlj3VmZZh5uCQ4Q8cQPINNVeH0RuPqo7n74yCB+2e0UYTImN6vKGobzXprxV8oJSV
A0EVR1OeVcjpaPAQkCCkBNwIPKjjbCnRXvChGKjPU0aoTm+GWVIf6aSOzt75c2Oce8sXw0lGy2ZH
czxRv0dTThQi6/Uh9GVFzNXl3RPemB4yGvlIAsThsZQmqzpWr3oDAUJ8H2vuVnQ/DHOFTvzrWuxg
5HRja7GA83xyywwsjsnycjnVye30+DsZvweZHbsDMB0ONN4wjGGdoSNDdcl8ZQA634BFgacWfZQg
Ck7P+OUsPN5c/D14RKYdI+bqliVWsp2nGkDSRZ6nzjT2e7X87J1Hczqj521FllbRVruIzk/PzpIY
Cs7o7gkCzRgHSBvoVcv/Z3ye4ZFgyYKD1qyKDkf1AOpZESSy1MoUkzUq2rLMlwUQCY5pVRqAQ2I5
xdGEYBYeS3lK8VcDQ9ckshZ2iqhNGak6M81WxA/iK1bUIq/6RR3QIHmdEdON0fAdgclaCSwajrfR
4EQbLPI30Drh2i4B4cFSi1+HvFwED+JEo2svWuAhU2rQBcZvgScLUAEBogAsntr0OsoeQJ7pQtUo
szpsCeRzhvhAI8sOIgAVQBUoAUkUmtnwlBJbgDuzymkp+RUw2qwbPgugiAqVDli8DPLIYq9onQx0
3lRmTZ1bwJkBTxag4tx4CnwWUZpZ8KAAIaYtUlviMnBmwRMBCIGoBEAh4O+Pvv4oQBGgUCf4lsqH
M0stE/B5RpEyx1nwoCWSMiBlYLm0ZObsOt0E/oyCMJUELFcpD+ILZQF5C2iugierQhF/JeLPXHG9
LahEUTgihdnKb4AnokIRIO5QmpGmLALG20BzBzy9EGXe33WtqBnpfX8bOHfBg/7vrJLQDdfeWw47
89lt0LwDPFmIsp+PbIc2ELA2GMjLtqW8z0YfBHyb9Htt8v//dcrTe070BtfeLvi99uk36pPO752u
rd30u381PJ96ruU3wvLbbgg9sDyN/zdcVyvP9mzP9mzP9mzP9mzP9nHbvwIMAJys9oiHehm7AAAA
AElFTkSuQmCC' transform='matrix(1 0 0 1 372 779)'>
           </image>
          <g>
              <path fill='#FFFFFF' d='M429.05,877.55c-13.04,0-23.64-10.6-23.64-23.64v-28.54c0-13.04,10.6-23.64,23.64-23.64h28.54
                 c13.04,0,23.64,10.6,23.64,23.64v28.54c0,13.04-10.6,23.64-23.64,23.64H429.05z' />
            <path fill='#DEE3E9' d='M457.6,802.08c12.84,0,23.28,10.44,23.28,23.28v28.54c0,12.84-10.44,23.28-23.28,23.28h-28.54
                 c-12.84,0-23.28-10.44-23.28-23.28v-28.54c0-12.84,10.44-23.28,23.28-23.28H457.6 M457.6,801.36h-28.54
                 c-13.25,0-24,10.75-24,24v28.54c0,13.25,10.75,24,24,24h28.54c13.25,0,24-10.75,24-24v-28.54
                 C481.6,812.11,470.85,801.36,457.6,801.36L457.6,801.36z' />
           </g>
        </g>
      <g>

              <radialGradient id='SVGID_14_' cx='-50.1218' cy='997.6483' r='0.0486'
                              gradientTransform='matrix(-346.4626 -399.8405 -287.7029 249.2952 270120.8438 -267900.0625)'
                              gradientUnits='userSpaceOnUse'>
              <stop offset='0' style='stop-color:#00CACC' />
                <stop offset='1' style='stop-color:#048FCE' />
           </radialGradient>
        <path fill-rule='evenodd' clip-rule='evenodd' fill='url(#SVGID_14_)' d='M444.64,830.12c-0.92,0.11-1.62,0.85-1.68,1.79
              c-0.03,0.41-0.02,0.43,0.9,2.8c2.09,5.38,2.6,6.67,2.68,6.85c0.21,0.44,0.5,0.86,0.86,1.23c0.28,0.29,0.46,0.44,0.77,0.65
              c0.55,0.36,0.82,0.46,2.94,1.09c2.07,0.61,3.19,1.02,4.17,1.5c1.26,0.63,2.14,1.34,2.7,2.18c0.4,0.61,0.75,1.69,0.91,2.79
              c0.06,0.43,0.06,1.37,0,1.76c-0.13,0.84-0.39,1.54-0.79,2.13c-0.21,0.31-0.14,0.26,0.17-0.12c0.87-1.08,1.75-2.92,2.2-4.59
              c0.55-2.02,0.62-4.2,0.21-6.25c-0.79-4-3.32-7.46-6.88-9.4c-0.22-0.12-1.08-0.57-2.23-1.17c-0.18-0.09-0.41-0.22-0.53-0.28
              c-0.12-0.06-0.36-0.19-0.53-0.28c-0.18-0.09-0.68-0.35-1.12-0.58s-0.94-0.49-1.1-0.57c-0.49-0.26-0.83-0.43-1.07-0.56
              c-1.14-0.6-1.63-0.84-1.77-0.88c-0.14-0.04-0.51-0.1-0.61-0.09C444.82,830.1,444.73,830.11,444.64,830.12z' />

        <radialGradient id='SVGID_15_' cx='-49.3864' cy='996.8116' r='0.0486'
                        gradientTransform='matrix(526.0025 -225.395 -375.6281 -876.6003 400840.1563 863532.9375)' gradientUnits='userSpaceOnUse'>
              <stop offset='0' style='stop-color:#00BBEC' />
          <stop offset='1' style='stop-color:#2756A9' />
           </radialGradient>
        <path fill='url(#SVGID_15_)' d='M447.14,850.19c-0.06,0.04-0.15,0.09-0.2,0.12c-0.05,0.03-0.15,0.09-0.22,0.14
              c-0.29,0.18-1.05,0.64-1.7,1.05c-0.43,0.27-0.49,0.3-1.04,0.64c-0.19,0.12-0.4,0.25-0.46,0.28c-0.06,0.03-0.31,0.19-0.55,0.34
              c-0.25,0.15-0.68,0.42-0.96,0.59c-0.28,0.17-0.78,0.48-1.11,0.68c-0.33,0.2-0.77,0.47-0.97,0.6c-0.2,0.12-0.39,0.24-0.41,0.26
              c-0.04,0.03-1.83,1.14-2.73,1.69c-0.68,0.42-1.47,0.69-2.28,0.8c-0.38,0.05-1.09,0.05-1.46,0c-1.02-0.14-1.95-0.52-2.76-1.12
              c-0.31-0.23-0.91-0.83-1.13-1.13c-0.53-0.72-0.88-1.49-1.06-2.37c-0.04-0.2-0.08-0.37-0.09-0.38
              c-0.02-0.02,0.01,0.29,0.07,0.68c0.06,0.41,0.18,1.01,0.31,1.52c1.01,3.96,3.87,7.18,7.74,8.71c1.12,0.44,2.24,0.72,3.47,0.85
              c0.46,0.05,1.76,0.07,2.24,0.04c2.2-0.17,4.12-0.82,6.09-2.06c0.18-0.11,0.5-0.32,0.73-0.46c0.23-0.14,0.51-0.32,0.64-0.4
              c0.12-0.08,0.27-0.17,0.33-0.21s0.18-0.11,0.26-0.16c0.08-0.05,0.45-0.28,0.8-0.51l1.43-0.9l0.49-0.31l0.02-0.01l0.05-0.03
              l0.03-0.02l0.36-0.23l1.25-0.79c1.59-1,2.06-1.35,2.8-2.07c0.31-0.3,0.77-0.82,0.79-0.88c0-0.01,0.09-0.14,0.18-0.28
              c0.39-0.58,0.65-1.29,0.78-2.12c0.06-0.39,0.06-1.33,0-1.76c-0.12-0.83-0.38-1.76-0.66-2.36c-0.47-0.98-1.46-1.87-2.89-2.58
              c-0.39-0.2-0.8-0.38-0.85-0.38c-0.02,0-1.35,0.81-2.95,1.81s-2.99,1.85-3.09,1.91c-0.1,0.06-0.26,0.16-0.37,0.23L447.14,850.19
              z' />

        <linearGradient id='SVGID_16_' gradientUnits='userSpaceOnUse' x1='433.7433' y1='99.5908' x2='433.7433' y2='56.5713'
                        gradientTransform='matrix(1 0 0 -1 0 914)'>
              <stop offset='0' style='stop-color:#00BBEC' />
          <stop offset='1' style='stop-color:#2756A9' />
           </linearGradient>
        <path fill='url(#SVGID_16_)' d='M427.99,845.43l0.01,6.91l0.09,0.4c0.28,1.25,0.76,2.16,1.61,3c0.4,0.4,0.7,0.63,1.13,0.89
              c0.91,0.54,1.89,0.8,2.96,0.8c1.12,0,2.09-0.28,3.09-0.89c0.17-0.1,0.83-0.51,1.47-0.9l1.16-0.72v-8.2v-8.2l0-7.5
              c0-4.78-0.01-7.63-0.02-7.85c-0.1-1.4-0.68-2.69-1.67-3.66c-0.3-0.3-0.56-0.5-1.33-1.03c-0.38-0.26-1.08-0.75-1.56-1.07
              s-1.25-0.86-1.73-1.2c-0.48-0.33-1.17-0.8-1.52-1.05c-0.74-0.51-0.8-0.55-1.03-0.63c-0.29-0.1-0.6-0.14-0.9-0.11
              c-0.86,0.09-1.55,0.71-1.72,1.56c-0.03,0.13-0.03,1.88-0.03,11.35l0,11.19h0L427.99,845.43z' />
        </g>
     </g>
    <g>
        <g>

              <image overflow='visible' opacity='0.22' width='144' height='143' xlink:href='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAJAAAACPCAYAAAAVxlL2AAAACXBIWXMAAAsSAAALEgHS3X78AAAA
GXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAEaJJREFUeNrsnemSq7oOhWVC7vs/
705A98/uKh8fDUuygKQPVFGZu8F8LA0eRHRv93Zv93Zv93Zv9/bf29p9XqdtfDf0fQ43WF/e+L9V
Mb8WqvbLj7H9AiD4Buj442oXnz+f9Du+AZo/nnbgObWLgeFvA6l9yXG0ovevBIiDn/E3gNQ++P+3
4veuNmE8AQ9/KkjtC8CZfX3F+VbCwp8MUvtQcBr4WQamdgE8ESg48b3LQGofDI73vCUAuwIgBBD0
vQhI/FsAagXgeI8IWEcBlVUcdoCZgeo0kNoHwaMBgECUBeoIgLTXkUcOwnaZGrUPU50GAII8vxIg
xDRFn3tgae8dDlG7AB5Pfcbn0mvvOxGQKgFCTRWDryNwIWrEnw5QA1UHBaeBn0WU6QqAUFisnQCw
TodovRgeFIzsTpQ3bVnfh0GAvH1XIPo5Xg2ONjxn4ZrwpwGUhWe80IsCwuK8jqrSWQAh4OzKe834
HEkotjMgagcBhDjIDYBFer4koEIgirQHarak17vxelceLZAsc+c51/wJChSBR3qtgdA/as8toCIg
VQBkOcsRYPa/5zKqUBu+F1WgQ5RoPRme/uItADTSaxQoFCIUJCRcR+EZgemf7x0wywBP6+BhByYU
ossUCOmeaKC58mBBd8sEzpoyFCAGAdoFgDYFpDbA0wa10kBCIJqC6ogoLAKPBc0j+FqCMQJRhQnz
4NEAGk2XBNMGgORBpEGThmgtNl2Iw4wozSP4aCnREgjxEYjQXM+uACTt2wDB9vf5NsCzKYpEAET0
aSYMgWuECFGch/D8YXzuqZEGEQGmrAIgS3U24XHrQFgEeDbjOC2I6AhTth6gPgSaLg2cyD4ChfhF
iApFzJinPr3pkszSCE+/j8fbQ6Qd8wxEl0Vhlt/jqU4PwDq8tyrPLZgiAGUhQpxnVHmk/W34dBtw
fPtwLqjShIFaJ9WHkqpjKc7aPUrPPZgiKoSE9JnQ3XOWLXB+TNi7e9TAR0AiBaYSFVoL1QeByYJn
hEXaJbA0gB5GrqgaIESBNgWgd/f46MCR4LGOjwcT5f2mJLm4TqoP0rflRVseNE8AKM+USQ41JcwY
Gr5rCjSarvff45fgWRzfDe2j682ZZsouDeOjzrOkPBo02iMCkaZCnh+E3OUZgDx4pONHwLH636xz
tOCBgVoL1AfJ+3j+zwjPc4Dm6UC0Ar5QJJyXzplBM+Y5zz1APxC9lOP2AJI6TzWT5mXYLwnjPfWR
IjBPeaT9f8PnEkhWVNYAPygbhfXPd8f/kQD6UaDXhPpYvtgymDIGoy8IqDWpPuSoz2LAo4EkQdM/
PgU1+vndA1AhNB80a8Is/0cyX+8OnpcCkAUyskvnyxW+UGVXRiZ81xSoh8cCKWPGLEe6KgpDzVd/
rC9Qfayobxk6V1v3uv97rDjW4bzQOmm+IoPErLzPKvg8/xt2CaL+d5YzLQF0VBTmOdCa+qDKY4E6
mi5NgbyorEyBIosZIOqzOGH70wDIgkiKyBbhwkScaDLuUk2FduXi9uqzGuqDOMwWrJISaaaMA1FZ
eR7Iyk1YIFnJw6dhwjSIPGcayakgY4I4YMYiCoT6PJ55XLvnD/r3qEZtRxxnE6jKIa2I+mh+0BPw
g0aIJBWqViA08iEQIDRhGIFnBGeEiAeQqDKZONsX5vk/aO4HicQ8FbKy0pFMdEVnqjTKcOvg8RKG
DMKzDec7Dk5bBpCs5GLKH1oT4Xt0sh8KkpUDeoJmbFSgCECVvfEaQJuiQKNjK6maFdX9tOWmKP5u
mLBTOlNbQJG8EB7pA3s6u+dIo3kgpFNYC3WRZGJvTsahGgvg86C9+BZEnt/HgClTIavygSoGkK3J
vJCnQF7XQAYgyw8as9HbYFK0XnYy8kl9BLcNvffSvgmJ3F2BRzvXsky05/+g/V/oUA4tunoq6iQB
5PXIe/5PNBNtXfxFAMiDZwTnOTxqHbHITYNEY9MmLOr/UEKFNAV6OOZMitokEzbjQFcB1PtBKDw9
QE9BfdYOnh6iJQhRqve9si8MGcYx3v0PJanomTJLiUYFsgDK9oFFQ/kRoCY4spbPswnq08OyggrU
DDNmOdPlwzmQjlTUnFkD41dDibw9kgMiOn5e2HjBtkCOR+p4XQ1wHoKP2RzViwYNIlRHdqais00f
jhJpvpL13iPQBxaFiB0lsnrBNwOeNdD1gYw6iEzxjgzzKFOg6Fo948lZSvQAYVrJn6ERCd+rFldg
wGRJEEWnNFkzddF1Aqa2TB4oOjNDG5ccnR+2GJLtTTTM9H/NqtA4c7T/zmN4zMCTHftkKW9JV4YX
gXmRWbR3fnHuKAQsZJZqJv+jgdMUNWn077HJmgI9ht7zKDzIQhPN8VNpNiudGc5Bzh0cNWXonHik
0ZA5YTPmC51f1YQcEAvwLEOm+kH4QhLWQLlGsbHV6CiEch8oo0TRZV0eCVAiPfDRjlSvJ7vRPwd0
UQcRDVHaeJ4bxReUaAkHOusH/UuhKrsyKAAOukpHdM0gb32gSoAYTMhpw0q91daQJWyimWcvhxeO
xKrXB4r0zCPrIs4usBkZQBZ1opvwWoJqNGPSmGVkdbbMimzataGqSKxyWo/lJ6GKhCxx1wCzFYGn
TbYBkz+OuilK5J3DApj9iJkqX1h+LTBbGu0oRC0AmfcZga9nQngtItO6A0Zl6o99J3/V2ah5jq4P
ObUtB5gwS4EoqErROwuFpAHpidnzpSDc1kq1UTOPpiqmFWqh+s1L1FWokgcikT/qsOpORMspoI5s
RpERpW2JHF+5CZu5U9H6FxT8LpqnqqqjyuDf0CbvZZ3d7M1XpbhpBYpeKE/io+YN/X0kw9wm7sKW
+NuoAjTCJm9aylYRJJxqwhrQsOggLjSnFAnDo9nXI9S4gcBEk7KUgK3UyhztA0XNmwdT9H818GJW
ZeMjSuW1Q5uAqcS/QX630DVbC15wxDFuiQt3xnnNQNaCZjByriXtsRygKkiYG4UI+R0lj/EsaFoS
jAiE6I1UFokuFyvPIaHliYpzpAojxYgvP8flQxsz6kN90xaJatun3xjLlzTst2/tQ46h/WaA+BcD
xB9yDPxbAeJkg/MXgsfAZ4eWqfxmgDgAwCwc/KUgoTXhP+LmWQ6805mw2qLRhq2Ehg8CgZ324GD7
MtjO2nPk+19lwjh4Mdi5CGjDXXFenLgxM6DxwTfbaQBFqNZqoTPYWJk7lwsbcgYOrx3YaItIHfkZ
leEzAMpKJoMni5q+yN+suBt54rvexff8Hg9CMqArVaal4C5DLxyyIAFyN3qNhUQwPHGncuJvowGD
pUAEtFPWnKW3NXHXZcYOsyG7yHtoY43HmT3eI1WZQdMdaTPk+4ekPo72gRgwZ0fsnlpVNqR1ocjx
YyjRFjsAFQXPN90Wy0HgRJxAmgQFzZ1Q8R3pnS8VnPcOPPegQhz3tPquRXcdO3dk5I5CGkf7bPxf
WmnrcfrNjOmK+HlE9lJ4UqXnXWmDfUKZy0zYmmwwpNGj6rKTXG9LakSpNgTiLxH5A96zURUCzQ6c
705+7Y0oRIc51OsBJmwGFAY+242/28/xjtxpGRVi8IZBIUEUyHov4pyXKdFaCA8C0w40rFTCaDfu
xp3kuhC9Op2ReUbMFIMKNLNHlQhN2pYDlM2YosVDrHLZDwWqHwXauyBhhIgVM9Ym4RkhisKxKedr
fb4HfCbUxIdSHCvwg0jNcS6GZqwzoX3XmqEgLW/LSV+IgzcSAtHmnK917lGfCc3Kw3CtQWikxkWV
Zyesnjr6Xr+kfw+S5uMghUZQXyjj/yDntinvaaBFTFm2e6nEB0Ll3gMHlfJNabytg6Z/lJbR7Y8J
WaU1sj4gukorB9RGqqm6ORBtikJlorLDVqqfjbo4IN9aY/ZrJf9Uu3kTVld9GSAimlupNZJN1s73
rTxuQaA0XzHqVJ8ehUkrdCE1PT3nGIFnrLflrYI6+jtnrVRvVS3UIJL2Ddi9qBXpU0v1C86G8ZZ9
1fIbqOpoFWk0BZLgGeuHLo4CVQJkFZ17O7v2PQSizQHJCwhCMK0JtdH8oqz5khqrL4strQmNVLzp
l5Mbi41kzVikWg8rTnN/rq/hsX//JcAjwWRFamjGOu0PrQloxi4BDvoAu2Oq+lrqL7JrX2g+2Vh4
9spyT9I5vw2IXs7nkt+E+EE7xYbElPtA2sqkBPo/fei9g35OX1MdgWcXTNdVFQvZ8X9GtdF2y8y9
FZAiEdmUM13RlaF1Gi6BUL03VS8HHAuelf5Zd2KmZmoL5n6I7JKXm2HC/vx9/scB6WX4SpZD7Zks
yna8rkn1mVEhDZzHANESNFvrAFG/6vuVVZtRBfqjPPfUaHOSjNHxQqGZJdnhHJIfJDXiElSfBQSH
FD+rN10VdeMjVZujN87o3/xRHi2INiA/5PXWH2bCWLnjtRXaUSd6EeB5k79svwdPX1cUqVqI+kFR
BdqNHFB/wV+KD/THgMgyYxV5obIoLJNUlMbmLAI8fUMuCXiI7BqjSA3RTO0wDioQkoV+KWZsBiIp
F3TY7I1sFKYNEyUFpBGiJQkPWl+0QoEaCE9EgUbHV1IhFKI3GMpHoq/wjN7McA4mveAa4gdJECGL
RmoJuuegPm/Cqhijq9vPdGEgeSDNjI0QSQChoTw6kyOsRLPzwthwpsdy1/2Qi2gtT6+6sVY/FQUo
U3R31om2VMiCSEoqZnvm6UwThpg0a5zyJoAUBWg31KevqR4tQov2iVUpEKJCLyMqeztmzOvGyETc
aYAQM+YNaRghilTRm1EfKw9UFYV5WegdBOgtqIyVF9IUaCYTfcq8MG9az0/j9XczAhEC4qg+76D5
yjrRmX6wUYF2w4y9FXP2BvJAHjwjSKjSHJ6JlkBqpNdPlyBC73DtTl6HyEuq5KwVcqODTRgDHclv
xR+yOlk1E7YnFegSH0gb4sqG6aHhzt+Mi2UNi7AGnHmlv9GhrbNONKpCHkRv4z1NgTYDHLTP65BE
oqVCUqFZVswZ2jmLDAuVBpxZ6mNVOJyNwlA/yIvKvIFmnvlCZ2SUzFitiMLY8BfGacd7wGwh3RVj
t4XVn+YVaosCREZOZXc6Va2RmJvS1+WBsyUc5+mlACvXB7IctN3oqIwoUA/N2iUlM/Ac1RfmTWXy
INpIH6rhzdRAO1DpjM7UrApZaf89YLo0gB6K+iwJgDx4GhC5RFXImjgZ3Xcn+oomDE8P4zWHmg1z
thd0Skr+zgJknmcdaNQP8nwhzy/y5sdFh69G1g06VYEQn6g/SA0izXQ9SB8Wa6nOjOnKzAtDFlWY
mc6NjHv24PFM12VhvASPdTAjRNqd8iB5gPwGhOqW+mTyP9l8EDpHzpovtwOmCoHnkMU2j1QgDyLp
AkpTc/o5Xf2+AaqDwpOZlRFxpjWIOAATsmYQarIumZURjciQueb74D8tZA8LsdQGMVto6D4DEIEA
WarEgInK+juVC60fUvIRKe/dFJUYn0twaMB44FTMi/eScOgCmshSfp6JikwWPKz6T+WQVjQq01Ro
vMDSvPYdNFWt2HRFACLCF9ZEl/WLLqp5CjzVPhBNQCTBJC0E1QYnvBE+PKRiUYWMPxTxjTIr1SKr
9R9WfKayBKJX39Mq3d0O2i1/5wyAoiChS/kS4Z2kh1Yuqq6hmYEoAhNR3FR5wLQCtUXMGWLaosux
lE4S/ASArL/ZACfbginyOmKyzgAIVSTvvYipOqVm2lHVhBvwfgs8Rp9HTFUlQARcUA5ARUm1Oa3g
3tHlqLNqRAEwWvDvnQkQAWqBKlUWnMPgOQOgqBplVQoF5gqAUKAoCAw6IIyvuLhn/p82CVXUvzn6
nFFFiihKZkoyX31hPwWkCCBnKM5RioTCdrnqXAkQ8j9b8esrzjdycWdfXwLOlQBlQZp978jz54nv
VVSW5k+9iJ9yDK3o/ar2qC6jnfVj+OqL96DP2lrBd864KY6qw84H//9fqUAVx9UuPn8+6Xf87Rfq
247xU86PL/rtDdCXH++vhOa3XZB2w3I3/n/hvJju7d7u7d7u7d7u7d7u7Vds/xdgAFl0VjuAU5AT
AAAAAElFTkSuQmCC' transform='matrix(1 0 0 1 476 774)'>
           </image>
          <g>
              <path fill='#FFFFFF' d='M533.53,872.43c-13.04,0-23.64-10.6-23.64-23.64v-28.54c0-13.04,10.6-23.64,23.64-23.64h28.54
                 c13.04,0,23.64,10.6,23.64,23.64v28.54c0,13.04-10.6,23.64-23.64,23.64H533.53z' />
            <path fill='#DEE3E9' d='M562.08,796.97c12.84,0,23.28,10.44,23.28,23.28v28.54c0,12.84-10.44,23.28-23.28,23.28h-28.54
                 c-12.84,0-23.28-10.44-23.28-23.28v-28.54c0-12.84,10.44-23.28,23.28-23.28H562.08 M562.08,796.25h-28.54
                 c-13.25,0-24,10.75-24,24v28.54c0,13.25,10.75,24,24,24h28.54c13.25,0,24-10.75,24-24v-28.54
                 C586.08,806.99,575.33,796.25,562.08,796.25L562.08,796.25z' />
           </g>
        </g>
      <g>
           <g id='layer1_00000139278263744421932530000009244873081183959222_' transform='translate(-51.448719,-71.557488)'>

                 <g id='layer1-9_00000164506521573242786080000002924948895218726536_'
                    transform='matrix(0.26458333,0,0,0.26458333,31.72956,-43.303112)'>

                    <g id='g3646_00000099638631100591608380000017260383035136424594_' transform='matrix(4.370655,0,0,-4.370655,325.04631,434.11881)'>
                    <path id='path3648_00000150791369770905428540000003886789993381763488_' fill='#FFFC00' d='M428.26-695.71
                       c7.78,0,14.08-6.3,14.08-14.08v-23.73c0-7.78-6.3-14.08-14.08-14.08h-23.73c-7.78,0-14.08,6.3-14.08,14.08v23.73
                       c0,7.78,6.3,14.08,14.08,14.08H428.26z' />
                 </g>

                   <g id='g3650_00000156575430880606415060000011212515001098796987_' transform='matrix(4.370655,0,0,-4.370655,224.62439,685.45071)'>
                 </g>

                   <g id='g3654_00000154410012812449614780000011486054001074181815_' transform='matrix(4.370655,0,0,-4.370655,225.4015,482.85644)'>
                 </g>
              </g>
           </g>
        <g id='layer2_00000178181757715019298870000000181674568676799638_' transform='translate(-51.448719,-71.557488)'>

                 <g id='g823_00000137824110983434937520000010736081975580263099_' transform='matrix(0.05532529,0,0,0.05532529,62.544683,82.653856)'>
                 <g id='g819_00000101804439548927068640000000553625691130396846_'>
                    <path id='path817_00000072262913022279400660000017881129431520358830_' fill='#FFFFFF' d='M10066.69,15069.28
                       c-3.2-10.58-18.49-18.04-18.49-18.04l0,0c-1.45-0.76-2.74-1.45-3.81-1.98c-25.49-12.33-48.1-27.17-67.12-43.99
                       c-15.3-13.55-28.39-28.46-38.89-44.29c-12.86-19.33-18.87-35.46-21.46-44.22c-1.45-5.71-1.22-7.99,0-10.96
                       c0.99-2.51,3.96-4.87,5.33-6.01c8.6-6.09,22.45-15.07,30.97-20.55c7.38-4.79,13.7-8.9,17.43-11.49
                       c11.95-8.37,20.17-16.89,24.96-26.1c6.24-11.87,7-24.96,2.13-37.82c-6.54-17.35-22.75-27.7-43.38-27.7
                       c-4.57,0-9.28,0.53-14,1.52c-11.8,2.59-22.98,6.77-32.34,10.43c-0.68,0.3-1.45-0.23-1.37-0.99
                       c0.99-23.21,2.13-54.41-0.46-84.02c-2.28-26.79-7.84-49.39-16.82-69.1c-9.06-19.79-20.85-34.4-30.06-44.98
                       c-8.75-10.05-24.2-24.89-47.49-38.2c-32.72-18.72-70.01-28.23-110.81-28.23c-40.71,0-77.93,9.51-110.73,28.23
                       c-24.66,14.08-40.41,29.98-47.56,38.2c-9.21,10.58-21,25.19-30.06,44.98c-9.06,19.71-14.54,42.24-16.82,69.1
                       c-2.59,29.76-1.52,58.45-0.46,84.02c0,0.76-0.69,1.29-1.45,0.99c-9.36-3.65-20.55-7.84-32.34-10.43
                       c-4.64-0.99-9.36-1.52-14-1.52c-20.55,0-36.76,10.35-43.38,27.7c-4.87,12.86-4.11,25.95,2.13,37.82
                       c4.87,9.21,13.01,17.73,24.96,26.1c3.65,2.59,10.05,6.7,17.43,11.49c8.29,5.4,21.77,14.16,30.44,20.17
                       c1.07,0.76,4.72,3.5,5.86,6.39c1.22,3.04,1.45,5.33-0.15,11.42c-2.66,8.83-8.68,24.81-21.31,43.76
                       c-10.5,15.91-23.59,30.75-38.89,44.29c-19.03,16.82-41.63,31.66-67.12,43.99c-1.22,0.61-2.66,1.29-4.19,2.21l0,0
                       c0,0-15.22,7.76-18.11,17.81c-4.26,14.84,7.08,28.77,18.57,36.23c18.87,12.18,41.86,18.72,55.17,22.3
                       c3.73,0.99,7.08,1.9,10.12,2.82c1.9,0.61,6.7,2.44,8.75,5.1c2.59,3.35,2.89,7.46,3.81,12.1l0,0
                       c1.45,7.84,4.72,17.5,14.38,24.2c10.65,7.31,24.12,7.84,41.25,8.52c17.88,0.68,40.11,1.52,65.6,9.97
                       c11.8,3.88,22.53,10.5,34.85,18.11c25.88,15.91,58.07,35.69,113.01,35.69c55.02,0,87.44-19.86,113.47-35.84
                       c12.33-7.53,22.91-14.08,34.47-17.88c25.5-8.45,47.72-9.28,65.6-9.97c17.12-0.69,30.59-1.14,41.25-8.52
                       c10.35-7.15,13.32-17.81,14.69-25.8c0.76-3.96,1.22-7.53,3.5-10.43c1.98-2.51,6.39-4.26,8.45-4.95
                       c3.12-0.99,6.62-1.9,10.5-2.97c13.32-3.58,30.06-7.76,50.38-19.25C10067.6,15094.77,10069.27,15077.73,10066.69,15069.28
                       L10066.69,15069.28z' />
                 </g>
                   <path id='path821_00000162318388426177637270000001826666617883522187_' d='M10087.84,15061.06
                    c-5.4-14.76-15.75-22.6-27.47-29.15c-2.21-1.29-4.26-2.36-5.94-3.12c-3.5-1.83-7.08-3.58-10.65-5.4
                    c-36.61-19.41-65.22-43.91-85.01-72.91c-6.7-9.82-11.34-18.65-14.61-25.88c-1.67-4.87-1.6-7.61-0.38-10.12
                    c0.91-1.9,3.35-3.88,4.72-4.87c6.32-4.19,12.79-8.37,17.2-11.19c7.84-5.1,14.08-9.13,18.04-11.87
                    c15.07-10.5,25.57-21.69,32.12-34.17c9.28-17.58,10.43-37.67,3.27-56.54c-9.89-26.18-34.7-42.46-64.69-42.46
                    c-6.24,0-12.56,0.68-18.8,2.05c-1.67,0.38-3.27,0.76-4.87,1.14c0.3-17.81-0.15-36.83-1.75-55.4
                    c-5.63-65.45-28.54-99.77-52.44-127.09c-9.97-11.42-27.32-28.08-53.35-43c-36.3-20.85-77.4-31.35-122.22-31.35
                    c-44.67,0-85.77,10.5-122.07,31.28c-26.18,14.92-43.53,31.66-53.42,43c-23.9,27.32-46.8,61.64-52.44,127.09
                    c-1.6,18.57-1.98,37.59-1.75,55.4c-1.6-0.38-3.27-0.76-4.87-1.14c-6.24-1.37-12.63-2.05-18.8-2.05
                    c-29.98,0-54.79,16.29-64.69,42.46c-7.15,18.87-6.01,38.96,3.27,56.54c6.54,12.48,17.12,23.67,32.12,34.17
                    c4.03,2.82,10.2,6.85,18.04,11.87c4.26,2.74,10.43,6.77,16.51,10.81c0.91,0.61,4.19,3.04,5.33,5.33
                    c1.29,2.59,1.29,5.4-0.61,10.58c-3.2,7.08-7.84,15.75-14.38,25.34c-19.41,28.39-47.18,52.43-82.57,71.61
                    c-18.8,9.97-38.28,16.59-46.5,38.96c-6.24,16.89-2.13,36.15,13.62,52.36l0,0c5.17,5.56,11.72,10.5,19.94,15.07
                    c19.33,10.65,35.77,15.91,48.71,19.48c2.28,0.68,7.53,2.36,9.82,4.41c5.78,5.02,4.95,12.63,12.63,23.74
                    c4.64,6.92,9.97,11.64,14.38,14.69c16.06,11.11,34.17,11.8,53.35,12.56c17.28,0.69,36.91,1.45,59.28,8.83
                    c9.29,3.04,18.95,8.98,30.06,15.83c26.79,16.51,63.55,39.04,124.96,39.04c61.49,0,98.4-22.68,125.42-39.19
                    c11.11-6.77,20.7-12.71,29.68-15.68c22.37-7.38,42.01-8.14,59.28-8.83c19.18-0.76,37.21-1.45,53.35-12.56
                    c5.02-3.5,11.42-9.21,16.44-17.88c5.48-9.36,5.4-15.98,10.58-20.47c2.13-1.83,6.77-3.42,9.29-4.19
                    c13.01-3.58,29.68-8.83,49.39-19.71c8.75-4.79,15.52-10.05,20.93-16.06c0.08-0.08,0.15-0.15,0.23-0.23
                    C10090.2,15096.22,10093.93,15077.58,10087.84,15061.06L10087.84,15061.06z M10033.28,15090.36
                    c-33.33,18.42-55.48,16.44-72.68,27.47c-14.61,9.44-6.01,29.76-16.59,37.06c-13.09,9.06-51.67-0.61-101.52,15.83
                    c-41.1,13.62-67.35,52.66-141.4,52.66c-74.2,0-99.69-38.89-141.4-52.66c-49.85-16.44-88.51-6.77-101.52-15.83
                    c-10.58-7.31-1.98-27.63-16.59-37.06c-17.2-11.11-39.35-9.13-72.68-27.47c-21.23-11.72-9.21-18.95-2.13-22.37
                    c120.7-58.37,139.88-148.63,140.79-155.4c1.07-8.07,2.21-14.46-6.7-22.75c-8.6-7.99-46.88-31.66-57.46-39.04
                    c-17.58-12.25-25.27-24.5-19.56-39.57c3.96-10.43,13.7-14.31,23.97-14.31c3.2,0,6.47,0.38,9.59,1.07
                    c19.25,4.19,37.98,13.85,48.78,16.44c1.52,0.38,2.82,0.53,3.96,0.53c5.78,0,7.76-2.89,7.38-9.51
                    c-1.22-21.08-4.26-62.18-0.91-100.61c4.57-52.82,21.61-79,41.86-102.21c9.74-11.11,55.4-59.36,142.69-59.36
                    c87.52,0,132.95,48.25,142.69,59.36c20.24,23.14,37.29,49.31,41.86,102.21c3.35,38.43,0.46,79.53-0.91,100.61
                    c-0.46,6.93,1.67,9.51,7.38,9.51c1.14,0,2.51-0.15,3.96-0.53c10.81-2.59,29.53-12.25,48.78-16.44
                    c3.12-0.68,6.39-1.07,9.59-1.07c10.27,0,20.01,3.96,23.97,14.31c5.71,15.07-2.05,27.32-19.56,39.57
                    c-10.58,7.38-48.86,31.05-57.46,39.04c-8.9,8.22-7.76,14.61-6.7,22.75c0.84,6.77,20.09,97.03,140.79,155.4
                    C10042.41,15071.41,10054.51,15078.64,10033.28,15090.36z' />
              </g>
           </g>
        </g>
     </g>
    <g>
        <g>

              <image overflow='visible' opacity='0.22' width='144' height='143' xlink:href='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAJAAAACPCAYAAAAVxlL2AAAACXBIWXMAAAsSAAALEgHS3X78AAAA
GXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAEc1JREFUeNrsnYtu4zgMRSnZnf//
3mlsLRbYABoOH5eU/EjXBow6SZta8vHlQ7JI9GzP9mzP9mzP9mzP9v/bytOu07b2dPTThgesD+/8
n6qYHwtV+eHnWH4AEO0B6PjzKhe3v530d+0BaPx8yoFtKhcD0z4NpPLh0JTJ7boKoHYwcD8SoIyS
jLx3BxPWku/dFqTyAeCMvr6ivS3w2ejrS0EqN/p/BYSiDMJULoDHggD97JYglRuD4x2XBGB3AEg6
jnzugXUqROXC/5GBpQAQRRRqRh9kFacFPotAdypI5WJ4EAAQiLJAnQFQG/gZAeoSNSo3UJ0oIMjx
XQDSQPJgaUGgEJDapwCUVR3rOPo6AtJMgFDlkWBB3ssq02EQlRO+D1UdCYriQFMAiO4AkKUu6E4A
WKdDtF4MDwLFyM7/T8S0RU1XRnG8fRegeZ+vBkdhx024Ju1uAGXhQffqvEZBOhsgFJxdeV2Uz5HM
dDkDonIQQKifg4JSwfcipg0N80fMlgaQBswufL4bEHnmzsolTTFn68nwSK+rcVwFaKoCUqW4STsC
IER1duPn/l9behXau5/9d0QU6BAlWk+Gx1MeDkoF34uAZPlFHkhRvycKzi68VxSIyAEJhegyBUKG
JyLgaKBEds+0jZoy1O9BANLg6feNnX8PEQEgIRANQXVEFIbA4ynOv/uiHFvvcRgjEI2asCg8FjQ7
g6WHaRf+d2/2ToVonWy6ovBUB56FHUs/UTVCIjMEohZQIO6zIOBUARjpmMOEQkR3MWFIpplAeLja
LAJEiwKWBZMVpRFgyjyIrJAdhWfrfm4dBO82cHis8/QgotkqtE5UH0+BJHgWRXEiu6dGlhJ58Fid
6uV7LEd5U+DZGEhVAKk4IFkQITmiS6KwoiiOBFENQLMqx/1rSZFmmLKMAkkmjANkQfPeX4KKRgaC
OUSo0oSBWgfVhxLgVMVcScCsyvEsiJCQ3vOBIurjwfPq1OclnDvv5804z125MTxTdnoYXwyYqgKS
Bg4HRtoXBSbJLyoORCMAaSbM8n00gN7gLEx9Xs65kjK00ZyE6bSxsnVQfTSfhwz1seDh+1cAJs8n
sgCKmLGM+doYRByepQPICgYKqIhVUSJJgS4P4xH/xzNfEjTaTxQiTYVK18HRfBCa//EcZw7PypRn
UeAp4DlxFeIj9OTAAwO1TlAfInugVIu+FkVxvtjxlwLRl+AfLYYvFAnnSTEVkfyPpz6vDpyXoJ78
fBHHflHMqmfKLgvjEcUpjglbFGC+DIi+FCXSTFkJmrFIFBbxfzhALwf66lx0ZGS+MlPWwOgLAmqd
oD5eJIbAI6nQv/svAKZViMwkPwjNB80yYVyBNIDe+7fi93ggIztqBg8P40eUaAmYsB6eXwZMiApZ
memZUZjlA22G+fp2zjUCzjv/0zrFqQJELXCN25F5oKzvE4HnlwGRpkJRgFAzNkuBuPn6BqMuJFnZ
my5NgUowwZgGqAQgKgmILP/nl7BrEEkRmRTNZJ3oyDQOKwJ753ss9ZHgscBZhfcqA6gpKlRGzdg6
wWRZnyE5IE+JNJAQM2blUwrFZie2gCOrDZquLGloheyU+P7390mmTNoRx9kEauaUVgmcYvhAq5MD
+gUo0aqo0AwFyvpBXg5oEQZLCwCPN59oUY4rg0iDY7oJQ81XJIFoZaG/QF+Iq1D/t1IUtihhPAHp
/qKoz4gTzYcrNKcZUZweyoV9XunPqSFNSS4O+UPZPFDkCYuIGUN9Ic2MaT4QAtDM0XhtEPVF8lQN
6WJ68GydWdw6gKTv3w0TdspgKvqsuwcR4gNZSUXJmeZZac0HiiYRoz4QKSPx3GxtTqjeAsnIjSmQ
ZCKLApE2Ih/yg2b5QEUZhY9M41gNU8ajL1SBKuBrZEyYNaWDZ6MXwXQVIFSX4PkyxtKy8GhtnZaJ
JuAORdVHmki2gmq0OlnpNZCJ9vyfaCbaMmO98hQn2iLDf1rZz1d3vAVuGiQaGzZhyFCFl0i05j57
Uzq0wdNVGROTTJg1Gn8GQH1meEs4zBtTnS+WCljZIKw3NxyNxqb7QFn/JzKdY1ESgivZUz2sTLQW
gRGd81gPD6F3ZyzK8nl6aFYBHiTz7uWDUiH9jDnRWmZae9DPeuLCms6qzVpcElloOgEgftG2ADwb
8204NAvZMzG1Z+TQxbbQEfupz4VFnwFbnFH6xQBkNX7Hi8Cy5suCh8BR8M2AZyV93hDyZEp1wPHW
WoKhmTkaH12iRTNjWYhW8h/1mTWVNeoL7YRNo2gdPIgia+Yq40QPb5k8UPTJDMkee0+lLiBc3tOr
Vidm1CcyLmb5Pa1L/C0CPKugPt4NF4m8tOs6ZSjDi8C8yAwByYrOIu95vz+a/9HA4REUd0SldXo4
PI0NfGr+jHWjeI92F8dPpdGsdGY6h5e9zZgyDyhryudCsSdTRxxo9PmqwqKwSn/P0als9HxzlAaB
RVP6iOmyprFM94G0AdWR8B5xtqthGr1nwWYtcaeFvYXBQh1EJADUt20z8jqLkyCNONBZP+gvhTpq
jURkCTtkiZca8KO8Fc9mA9TAhJw2rbQG+iC6RlLU90lHYrNX54iYMHQNxGo45AX8DHGgo0605Qf1
m2bGdrCd6FJ/HjDeE7mHRmHZiGwWWJ65yq7YOmK+LCWSYONKVMF2Wj5NDZqp6QvLr4NqQwbtKEQ1
+Nr6jMDXIyG8FpFpwwFeX0QUaGSp46nK08vrbBNGQec6k5T0Fg/3IClAemK0vTSh3ahvl01VDCtU
pfmbl6g7enV6LzqcfSei5RRQRza66DqqtCWR45tuwkbuVLT2BVEs9PRWDaHJbWzgdzTS5xujzm5J
9g/y1MxpJgx1lFGJpyA8BKpKJMNcBu7CkoQWUQBPKb1+igY6t/CBCtCxkacgkBVgI7BHs69HqHEB
gEF9NXRIqSSVp5wN0Ig9RaQ8Ay1i3mbcoWVAqaIK5Jm6GdcjDFOla7aSVBDErkcXpDy6XSOQlaAZ
jLR1Sn/UA1QFCXOjEB3VKeVEaEoSjAiE6I00LRKtFyvPUdJ7luIcqcLezIFyhzbWm3Rk1i8pHwDK
SFRb7n5j1A/p2E/fyk1v1B8FEFL//FO3dpNzaD8RIKth7YpOuRCmU8pUXgHQrAvVCC9L3QYvTvtQ
kNCa8Le4eerBHWRd0Bbs6HYAGO2g72hOf7TgDdrAftaOkd//KBOGdjBSiz3ScVe1K6rsGdCi6tvu
qkDWxSVFhlvShKF37gwlpMlweH3RDHPVCK8jP6Iy7WiAMg1pQQXy6qKPKMAIRG3gd9G+iEDTHCVu
R/RFDX5hG1CirAJZKuLduREHvQ2qnqecDfisOc6y9/npwcR6wHcid06k1oMHjHYO0qSucpAaWTB5
6tMAU5bpt+ZEcB8VhWUa7nUKBaFDLu6sdiJhN9IOawVYCyok8JjiJ9VJKoNK+xm7F8XMuiMRMFvg
tVUy0zr2oEIc97T6rsmOKwmTFbm79kBnWv9fK23NH78ZMV3NObaWf9HaJy0VjPQBenNNM2HrBPlG
8zgeODv5RUR2p8P7R3S1jvImvGejKgKUZie8NLh1I+1BiA5zqI9yomlApnfSl/W3Or4G0wokgDMa
mhPQTqQtFjCeckWd88sBGnGWLVAswCR4+KKW/WdnZJ0bAFFzlEZb4d67sXZFxREl8m62w5a4i1Sv
8Zbut+pA9GsGWn/DV0QlBtaMEB9tO4HmyWuz9XMHVCpi3lLJ0xX4A6TmOALPDpit3bn7tKo3O+nL
yUlL3LakL4Qk7BCFlQrxWpBswE3nuQMoNCG41iA0Uud6YzaWzd9IL4st1YaQ4OErwWs+DlJoBPWF
0AXHtXWfJVXdwB1VZmTYg4DhoSk+kCT3Xop9J7+KMXo3Snu/3Mkbnk05d7TIyug60UR+xR6kbRGI
EDVCfaHTnGhN+lH/ZyOsCg2vB6EBZJkcviYPEfbY9aj6eNV2XvRn/VT+Pj+WILLMHBLm3yaMzzrN
Fjh9PQipLoS3/vLCIjFrvZ5ZChSpGSbB8lKOEUXKRGRDEdiMKAwd4IvAo0GEVvqjDp7KFGinOauV
RbPM3GRJsGj7ZrxvQaRFZAQMb8AwrUlzVYCMdAOUR+pUDo5VV91y5q3lfoniC45Hss9W6e8eim/2
kx9Lry3zZiVed4qN2A+ZsKZcIM2RRkJ2K4TtgXkxcHhddbRY23ITgDT/5yVA9G2A5Zm3PRCRTZuV
OWrCkPLUaOlGDhEHR4NHqhbYJxBHV6xvARO2BwF6A/K7A8hSpW/HN4r6QcPO9IzB1EjmWYJmYUrT
g2PVgJDMZV+0ZKexmqkIQFr22Sr7/b7wXHl+K8ffjimznGrLD/Km40ytF+b5QUgSTTJbiwHR+7gY
zrL0P5GicyMVeyLZ56bkfDQF+s2OOUQvwKFGFYgC/k9qofGIH1RA9emX9PcgslaZJ+X71wRAI+We
MgBpCtQDpEH07ShRNJynO5gwzYHsF9aWQOo7NFKiiZwEJVq1sExSIKtis2W6Xw5AvwFzhuSF0AHW
lFO9BmGxqtUg+Z+NDT9IeR1UeazKxpG6YSNlv9EBVBQgD6KXYs68YY7Dnt6YNZRRBPXxIHqxnxnl
2ZR0gFTdJqNACEBNAbsZ415aGP/bgOjbgOelwBONvsLP1WWmc1jvaVNMd/qzbnoPUUnAI5XGloY8
rNJQlMhItwEF2oUL/i0oEQLRDD+IaMK013VQeXi4aznTEkiVgeQlCDk8X/R3aWyrrmhxHGnPD8qM
ge2KE20lE72ozPODojMULzVhvDMLybXS+9LXBVAcb2ikB0crwOvlgSIKFJnCYeWBeDb524HIg+cF
DGNk50RPGUxt4F1ZhKSaBNHOQCqOydAuyJfg+yCVm6Xyl5korDkZeB7GR1XI2qODqofNCRp9Lqwp
QwokmC6uQhtw4Zrj+/Tqg5iviBNdwHEwJIzfnRkHHkjSa2kuETIijyrNYZloLzstyToJpowCpqs5
40paTfWFsGqGs0yYlUjUwvnNgcgbXB3xgW4VxmtJNg6SN+pNil9hwfMiuyx2JBM96kR7kRgK0eug
IYzIMoOHJhJ5+C79vgYNVyJkcBaBJ1L++6goDB0L1MzZRtgEsw0M4Q9dAmbGdA7vKQ4plEeHRqSx
rq3LNr+cyAv1f44AyJqJ6SnR5gDzGjBZmUW6DskDof+YPz3qOcua+eqnamwKOBHn+YixMC+cRyGS
ILFyPpmJZLcZTC2OKfPg0aIc6U7WoImariw8BPh6EVOWfbRnd+DJJgwvW97FesqTWG4IMV1aGKwp
jqc8aPg+Yz5QJDNtzRG3Xnuqk5kHdIkCeaokdbqnRP2k+L7jKwOpgj5PVH3KZIAsiEafi58Jz+VD
GcVwrJtg1jwTUOnP+c39NFX+eLNVZ92LvKKP9UQeLPTW9kHWAYg8hYr6PdO22ZPqPX9IgqgAIEmT
5GfCg5RV8sLg6HpI+8DewDEvVG3anUwYkh8q9OeqGRZIu2OmUJMVDd2jAFnRZHQBKe29CDjIcsnD
qnREyUervDcJF7QqrzVIqvF7ZRCezDLAzRkfiyxv561CFlnaDoHnFgDNgEgDqjpgWSBm/J5ZACGO
NbIKK7oyK10BzxkmzDNn/Pf5xe7NHZ9rhCgO6veUZBsj/hARtuYzsoSvN7/ntLpjM0sgen5ESSrS
yE6EF7GdCRCRX4YpspA4urA4mmluR170syFCYaKk2swwW7PMGaJKUaWZ9pDgHQCyvrMMqBIBQBHh
WeazAMooElHeRJ0Kz1EAIRChjjYF1CVrqkb7AA3vUWUiyvk2SLUg+hSAMiBpFxzxZRB1OwIgZOGF
LEwZpTkVnjMAGlEjopwjXMD0wlEKlDFtGXVBhyjaFRf3zP9TBqGK+jdHtjmjSFFAbgHO2QCNgBQB
5AzFocRFQ2u2tgR8l8FzBUBRkGa8Pru9EVVAxqVuCc7VAGUv9sh7R7Y/W8x2VuHgdscLeKfzKJPe
n9Efs0tot8lQnrotdK+tHAjbGSZq9G/bCefwIxVo9LzKhe1vJ/5t+/QL9WnneJf2tYv+9gHow8/3
xwHzEy9IeYB5Ov//0K5Gz/Zsz/Zsz/Zsz/Zsz/Yjtn8EGADvAVY76jGP0gAAAABJRU5ErkJggg==' transform='matrix(1 0 0 1 581 777)'>
           </image>
          <g>
              <path fill='#FFFFFF' d='M638.54,875.37c-13.04,0-23.64-10.6-23.64-23.64v-28.54c0-13.04,10.6-23.64,23.64-23.64h28.54
                 c13.04,0,23.64,10.6,23.64,23.64v28.54c0,13.04-10.6,23.64-23.64,23.64H638.54z' />
            <path fill='#DEE3E9' d='M667.09,799.91c12.84,0,23.28,10.44,23.28,23.28v28.54c0,12.84-10.44,23.28-23.28,23.28h-28.54
                 c-12.84,0-23.28-10.44-23.28-23.28v-28.54c0-12.84,10.44-23.28,23.28-23.28H667.09 M667.09,799.19h-28.54
                 c-13.25,0-24,10.75-24,24v28.54c0,13.25,10.75,24,24,24h28.54c13.25,0,24-10.75,24-24v-28.54
                 C691.09,809.94,680.34,799.19,667.09,799.19L667.09,799.19z' />
           </g>
        </g>
      <g>

              <radialGradient id='SVGID_17_' cx='-52.6494' cy='994.3567' r='0.1842'
                              gradientTransform='matrix(0 -253.715 -235.975 0 235285.0781 -12493.0332)' gradientUnits='userSpaceOnUse'>
              <stop offset='0' style='stop-color:#FFDD55' />
                <stop offset='0.1' style='stop-color:#FFDD55' />
                <stop offset='0.5' style='stop-color:#FF543E' />
                <stop offset='1' style='stop-color:#C837AB' />
           </radialGradient>
        <path fill='url(#SVGID_17_)' d='M640.29,814.1h25.06c6.1,0,11.05,4.95,11.05,11.05v25.06c0,6.1-4.95,11.05-11.05,11.05h-25.06
              c-6.1,0-11.05-4.95-11.05-11.05v-25.06C629.23,819.05,634.18,814.1,640.29,814.1z' />

        <radialGradient id='SVGID_18_' cx='-41.1378' cy='998.1277' r='0.1842'
                        gradientTransform='matrix(22.2595 111.2061 458.3952 -91.7545 -455999.9063 96974.9766)' gradientUnits='userSpaceOnUse'>
              <stop offset='0' style='stop-color:#3771C8' />
          <stop offset='0.128' style='stop-color:#3771C8' />
          <stop offset='1' style='stop-color:#6600FF;stop-opacity:0' />
           </radialGradient>
        <path fill='url(#SVGID_18_)' d='M640.29,814.1h25.06c6.1,0,11.05,4.95,11.05,11.05v25.06c0,6.1-4.95,11.05-11.05,11.05h-25.06
              c-6.1,0-11.05-4.95-11.05-11.05v-25.06C629.23,819.05,634.18,814.1,640.29,814.1z' />
        <path fill='#FFFFFF' d='M652.81,819.26c-5,0-5.63,0.02-7.6,0.11c-1.96,0.09-3.3,0.4-4.47,0.86c-1.21,0.47-2.24,1.1-3.26,2.12
              c-1.02,1.02-1.65,2.05-2.13,3.26c-0.46,1.17-0.77,2.51-0.86,4.47c-0.09,1.97-0.11,2.59-0.11,7.6s0.02,5.63,0.11,7.59
              c0.09,1.96,0.4,3.3,0.86,4.47c0.47,1.21,1.1,2.24,2.12,3.26c1.02,1.02,2.05,1.66,3.26,2.13c1.17,0.46,2.51,0.77,4.47,0.86
              c1.97,0.09,2.59,0.11,7.6,0.11c5,0,5.63-0.02,7.59-0.11c1.96-0.09,3.3-0.4,4.47-0.86c1.21-0.47,2.24-1.1,3.26-2.13
              c1.02-1.02,1.65-2.05,2.13-3.26c0.45-1.17,0.76-2.51,0.86-4.47c0.09-1.97,0.11-2.59,0.11-7.59c0-5-0.02-5.63-0.11-7.6
              c-0.09-1.96-0.4-3.3-0.86-4.47c-0.47-1.21-1.1-2.24-2.13-3.26c-1.02-1.02-2.05-1.65-3.26-2.12c-1.17-0.46-2.51-0.77-4.48-0.86
              C658.44,819.29,657.81,819.26,652.81,819.26L652.81,819.26z M651.16,822.58c0.49,0,1.04,0,1.65,0c4.92,0,5.5,0.02,7.44,0.11
              c1.8,0.08,2.77,0.38,3.42,0.63c0.86,0.33,1.47,0.73,2.12,1.38c0.64,0.64,1.04,1.26,1.38,2.12c0.25,0.65,0.55,1.62,0.63,3.42
              c0.09,1.94,0.11,2.53,0.11,7.44c0,4.92-0.02,5.5-0.11,7.44c-0.08,1.8-0.38,2.77-0.63,3.42c-0.33,0.86-0.73,1.47-1.38,2.12
              c-0.64,0.64-1.26,1.04-2.12,1.38c-0.65,0.25-1.62,0.55-3.42,0.63c-1.94,0.09-2.53,0.11-7.44,0.11c-4.92,0-5.5-0.02-7.44-0.11
              c-1.8-0.08-2.77-0.38-3.42-0.64c-0.86-0.33-1.47-0.73-2.12-1.38c-0.64-0.64-1.04-1.26-1.38-2.12
              c-0.25-0.65-0.55-1.62-0.63-3.42c-0.09-1.94-0.11-2.53-0.11-7.45c0-4.92,0.02-5.5,0.11-7.44c0.08-1.8,0.38-2.77,0.63-3.42
              c0.33-0.86,0.73-1.47,1.38-2.12c0.64-0.64,1.26-1.04,2.12-1.38c0.65-0.25,1.62-0.55,3.42-0.64
              C647.07,822.61,647.73,822.58,651.16,822.58L651.16,822.58z M662.65,825.64c-1.22,0-2.21,0.99-2.21,2.21
              c0,1.22,0.99,2.21,2.21,2.21c1.22,0,2.21-0.99,2.21-2.21C664.86,826.63,663.87,825.64,662.65,825.64L662.65,825.64z
               M652.81,828.23c-5.22,0-9.46,4.24-9.46,9.46c0,5.22,4.24,9.46,9.46,9.46c5.22,0,9.46-4.23,9.46-9.46
              C662.27,832.46,658.04,828.23,652.81,828.23L652.81,828.23z M652.81,831.55c3.39,0,6.14,2.75,6.14,6.14
              c0,3.39-2.75,6.14-6.14,6.14c-3.39,0-6.14-2.75-6.14-6.14C646.67,834.29,649.42,831.55,652.81,831.55' />
        </g>
     </g>
    <g>
        <g>

              <image overflow='visible' opacity='0.22' width='144' height='143' xlink:href='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAJAAAACPCAYAAAAVxlL2AAAACXBIWXMAAAsSAAALEgHS3X78AAAA
GXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAEbhJREFUeNrsnYmuo7oSRcuG9P9/
bwfspyfd6Pq6a9hVNkNOg4SScIaAWewaPBTRsz3bsz3bsz3bsz3b37el59pO2+rTyM81PFD9gMb/
yar5dVClH36O6cuBqA9A55zXlZDVk/6uPgDNO5908HWlg25inQRJfQDyn0eadPwqgOqk47cCKd38
+9PkY1easDr52C1ASl8ADgJHcvz/o6+5On42+vlykNKXgJMcgCXg+9KJ8HigqIHfuxSidJPvSsH3
KQDflQBZ72vw7y4DKd0cHO1YcvzuUYqEOMAoNBWAyQtS/XaA0iA42msEqNmKhCqOBU51QnUbkNKF
8KDgeN97YDobIAkaC6YoUIdDlE6Ex6s0/Xv0mPR/IqbNA5D2XoPF+1mCCgGp3h0gj8lClQXZyQHU
mQAhcKA/0/6XR43qtwEkmRHrxkt7dgIlwYSG/J58jqU8M3cEpsMhWg+Ex6s6KDAZ+B3NzHl8oyhA
EXAKeKyHhju31L2vzL2pdwIoAo8HnKwcQ1VpljlD4EEAKsLn9jUpv4tAno6GKB0AD+Ice4HJwjEJ
LkuVzgAIhacFRnrPvaImTjJtU8zZegN4sgEN99kDluVvRR8myWlG4eGA4T63KlKaV0uJOAWaZrqO
8IG88FgqE9k1gDQ1GgEIUaACwqPtqYGn/74CQCSZriGo1onqg8LTmyoJmsX4zB2TVAn1h7wmDPF7
JHikfWc+c+f/gab8c50SSBUAJQzROtF0STAhqiOBgb5aajQLIjTXo5ksC5zcfU4MSH1bl+5vI071
LaIwLteDwLMI8CzAZ1SNolHZCEAWPHvzuvzzmhsY9s732ZmHswVmBKIQVOtBpgtxmDlwOFg++2pA
tASVCIWHgurTQ7R37/fu/d6AlDuQPhBp51qAh+DWCoSE6JI5koBZBYgWB0T9uVAQIk/Y3gPEwcPt
G6Ceu3GeRQjTk+IXucFaJ6mP9LPEgJMNpfmAsjLwrIoiaRB5VMjjA1nmS1IfDZyP+mzCuaMJxCyo
UQ/PZVEYAX6P5Sgvisqsws7BxIFkKZEXIC6BiERexQBoa16XTn024Fz7c8qdKfPOMHEBtU5UH7R7
goNIgualgLQ6lUhSIVKim4gDrTnOPUDbP+fPwZOFZKgnT5U6p1oyZ6eH8VYm2jJfkvK8GHheAFCo
CmkZak2FtO6LPqEnQWTBw517MkyYZkJ7JarC3w/1k60HqA8BiUIOohfz+mI+9zBx5k/zhRBH2pNI
tPwfyXHeGojeAvio+mjDO7j/UQGH+vRMNDnMVmYc5h6alwIRokTt91k5oZlRGKI+LUAfBXoL6mMB
1H73wqhQ7f5HP9yDRlRoNA9kjQzMSugu+T4tJL8AoCR/KCtPdA5ko60uDK6HXVOftQHo3UCEAiSp
3yKYsj5HNEWF1gGlsdQnOx3nlwDPLwUmVIVQR3o0jC+MGdPUpz3XEXiKoDyVMWNJUKFQRLYeqD6W
37MIDnMP0S8HRJYfFHWi0SiMc6I153kzTBeqOj1MC3Ocu1+Hd2V4FiiwhqFyIGmmi9s5iD5/q3V3
SEM9uBB+RiZaCt+lyOsdgEdKFyyNChUlpVIBx/r0vjArgdjeVC33o4HUQyRFZKMK5Il6CFSgtYMI
VR8N0JWBqL3mXoXI6TirQK0TzBfSgYooUG/KfgEQrYoZQ5zoIxVIMmFcwpBrywrA06qOBFExFOiy
MJ6c8Fj9Xi/DkeYgkqKx3oSlAxUIyUS3JmwBcz6cf6UNPCtML342HOo66g+tg9GX1oGK9IFZYbwG
keZIRwHyKJAViXE3W+uuIIfi9MNA2mv2qNDwsI71APPFmQuPD4RA1B6zFEgyYUTHjQfqTdgiqIPV
2anBszd+VQvRDnQea76Qyw9aTzBfaBSGhPQvJRJDFegMgGrnn7QQbaDDLIGzMhD118vBWhRfKxyN
zR5Ur4GEjgHiOlA1qPooDFGgyPwwz3yw3oy1w1SlztE+o93D8+oA2oSHZSdsCMsUM7YG/R9pnpXl
B1mjD1cDJO11Vboy0skAVcYX2cE8j6Y6a/d+C2beU7T3fbQrQ7PZyDDW7DRnKDwvRxIxOqDem5HO
xM/r4qItCbyXANBG9mA6rjupCPcrHNKPmjB08Hx7XJttgQIldaCuDvM1MjvVmpWaGYgscLi80eaE
xjMSUxOLivaLzRxU70kmIv1j6BBXT0dq1HxFzFiiP4dSWKZr6aKqtYNoNZQWHcKL+kHTO1PJeGo5
c+eBx5rK49k1X4AOVCCiP0cBct/Vm6y16/KwRi0ioy+R1UqGtnUAGO/KYnkAqNUJE+pAzwaI6L8L
IUirYSyCzyMpkDWp0lp4An3gp3RlWE4ymmT0QMRJsXQMefoWRwdqxIRpo/ukIaUcRH0/ljRhEjFX
3hkoUt/bVAWKDuewFoaydmnSoaZcVkNmofE815sMkNqb0C540H5/q0AL03uuTddG1gYYMV3uSGzE
B7KkURtgjwz5QKY/I0+ftdCU92GxGjcx4XyrPLmDZum6PLLDXCWKLW+TAIsCOdUze+OJ/HPDEtAo
2nAMa6kYdCKhFyCpYTkVKt05ViYzjfiIHnPlDd3DkdjsBaYoAJPVgMnZiNbaiTQBIq7/SJp71Zuv
9tyK81q0dZC8ofvoQzOtM5UU38KjSAk0QSPrR89Y2o4EeNAHqveNPJFqMiBDet5pFjxRgJIRnc1a
vldLyaeBJ22WCbMcaamLwHpwMvlWOYk+UFO2PMHv8eaN0DAf9XcsE4UsNp4mtAGaE0PaxKvKXlNN
s1Qp07wtOU0aDZomAjtGkRs9M4iwckujSx97Sj1Y7TDcFkc50UjtL696EGDb04Sny7tOtBWVEdOt
YT0QMx6y2YobVqCoxHlMCpJfSoHvIEMFKNCwVv4kUtQOAYqAnx/mLB9lwrxZTqT7gyhmykZhHw0m
0NQGgck8S53QNow8IJf4QJ5EI/L0jKgBYlJnJRCjSkUAAOQEJtpGYZjywcDMSAUgDqHlOx0u5QYc
s8wh+vvTleYKBfKYNORYCt6koxvRqzIeMCI/SwBs0yLRPFFVPP4SHQDDFYpzBHhoRHmL68s3acho
VJS+AJQI2EepbfqJAH2jenyLov0VCvTZ6sPA9235JuDUIEz1S8CrJzwo9Yq2yhc2pHXRoxf8jYqG
VF++1XXlA5/0SvbsBc+NrwdAU0948rn24NrGqoDI/S+renO9C0BnyW11NHwFb84VioSaLO9NttoN
+X9o+fDLAKqBp1J6IkcbDAFpppmsk1TbqsBcge8+RYnyxKetDigI4lBX8Ik+S8rroCIhN9kyZ9XZ
vtMd/jzYUB4YkDLZRHj9c9SHQM43opqetiBDTSjQHtUw6aco0RqgMzJ2uA42kGXqZpznTD+pAmbO
U2u+Dj50h5mwI0YkWo4uUiobqYiDNnS7SQPdj4Clkl5Fx6NEnmv2WoYhyPJB4FhOH7pkfwGVB23A
mdJuXW8EDO6atZoYR6j3KQoUyWlUBzjeBpa+S1qBlFOgeoACUeAGS21RhPYpoCqhkePhAKGVXqrj
qdMaCTn2meEpNaI0X4sYwEbCdw80xbi+IryXHiTr4ZoRoR3qA0XsNtpI1rF+DrpUuU9bECHSsEhk
WQBotDqrRbhu6+FC80m3AKg6YCogHFIDRhTIqmA804FGSoBXABRJlbTfR/yeqcnUdULjeZzlAsCk
7btwPCuQoLmuNAkeUpRAgsQqZbAbiuSBCQksYLjWYMNKJsETXWklsbXjfemAfv3l1oyVxkT19UMl
Jzo5lNZ6kAoIiXT9SFsUxWeKdBVVD0yrExqugdEw3as0u9JgH4j6v0Nmd3AzRr1+kFUGXCv7ZKmN
pEA70G4RBRqKyFaHXCdA7qMKtCtPJQdTq0DaBL3MgDNj2o+3/KV0PdG9TAbqMh9oxGRpqtPWVO+P
9yWTkBXgZy7za2WeNQXS9q177d9LEO0GNIV8vtBpicQEpO3LhKexXyv5A8+mgLOQvuj3UQokAcQV
3rVeNxAiS416iKZ0YcwK4zXfBwlbLXAkeKRibSRAlOi/axISxUs9efM/0rVu4L4rx3cAHk94T15V
WgNqI/lFqBnbFSdxY8DpC9Na4LR10/uFmsqgGbOcZ1JCdOk638LrJny2zBsKktXhC8G0BqDp+5U8
5a8lBeqhacFpy2Jr1Y3b720X8R6tF+9RHyK7cnOrKm/wdQPNWzQaC/tDq1O2k0OBvH7P1pmqRVEf
LuzmKgUWipe7nFlwTvJ/WlDavYenPybBg6rQrboypMKzmfjaoYWJqhYBnF59tBxUX0N9FxRoZMX6
6gCIU6BNMGH/338L79+AD4SAU2b7P9E8kNcP0szW0kGkgWPVGG1LJkkFaL0lv1NAfbwK9O7A+S1A
9DYcba8PNOz/RBVI8oPQhOECQGT5O8T0OX3KJKEln2aaMCQCsxSoBUiD6G3A076vQhjvGS8UVqAq
NKK0QrsHotxBlJWbjlY2XjuIsmLCrBVcZygQkgPSAPqtmLP3YBQ23As/2wdKQGOWzqTsXV7Hipg0
gLga6t6yT0gkVgMKZKUr3g6INtAn0hKJiPk6PArThon2y/hnRnlyl1FGCqX1ZkvKr6z0Z0kkRIEs
gDwOtOX77UKE9VuB6B2AB4UIGW0QAgg1Y5YTnZpGbCHhIMpGtKdls3t/CimBHc0DjSrQzoTqKERv
0AcqIDiXdmVIznRfTEQyZRthy99JJvHVKI+nbqq3JEAUIC2RuAn5oN9GVLYZECEh/BS/aGRiIfce
VSBvGSLpprR11TfBgUZ8oEhXBjqEdVcy75IKaXkhDp4t2JUxZL5G+sKqEuZa8OzkW6C8v/CdcZwl
9bEK71rrLCNRGDIOiAvlLRXSdg0ey3xNU5+RPFAyqO0hog6e3aE8UsT16hxnqxjvzCjME8ZLfpDU
y86BtAF5IASe6UvfjGSiKxBitzekGBChjvPOqM8mwLOQrzzUTBNmOfySP4R2rlo5IGSm63CH6owB
ZdZA816FPvAQ4UNjtRuwkl0aO00CCHWiET/Igmgzui8kkDQnmmaqz2hvvAZSP72lVyHU3/GG7V71
mRWFobkgaUSCNFTDGmSGDGm9zXAONCJrT6Cd8FeAHm+tZ7t02eadfOWxkSRiZEy0p0NVm3GxATB5
B5IVwtcUOiUPpKmQtbBSAeCxAFqULgurtvoM9YmYsUr23C4ODHRwvSf60q7hlDyQ1g+mhbzt+CA0
/yABtAjqkwPqg5YUqAGIimGGR6f6SBMMvbMxTs9EW/PCpPwQZ84qCE4/HITzdyzlmWG+In6QNKkS
nUwo/bw4O06RZQBPyQNZKqSdRFHyPf0NyAxImf4cbZgd8MyoKVYHVUiaqbqDr0VxnDWIaDY8s8L4
CESaOeNmVrQA7aDqoFWOaUCBED8Iicy0BSSQGagIPIeskzh7faAoRFrD5+Z3W1j2IDzekYgEhLtR
iDwzdz3rBXmrBFw2tRnxhaoCUWL6nPoG10wUYrIs0zUbICQq0yCqZC/jgiwqRaDfc6kCaVN9yKFG
/WoZ/ZTkQvKgMwscDZqZJszKCyHL21krsVmmKrpq6y1MmMeUVUMhqvB/imGqtOEaFjSzAEJAKgZQ
keXrakBxhtVo1pJvVh7FKvst7dn5+QizNRqReXyjyDEC4amz4ZkJkBciMpQCgWkEnLMA8oIU3ekK
eGYDNAJRRJ203ycwXJ9x/VFzRg4w0MURrFzP7QHyQETADUeAIfKZrCsAigJFzshq2pTlKwHS/q+l
Rh51IqfipIOuH+2otADwvkfgPQycowHyQoQChfo2qK9zNEAEqAWqVIiZOkV1zgJoFkgUBAaB5QyA
UKAQqLyqcyg8ZwFkfU8Kvo9Ac8b1oorkASRSIKVefWPP/i7U0Y383p0A8oTY0VC83uWm3gmkUUjO
vFbPzR39fAk4VwIUAekIWNJJwHjVyQvNJeDcAaDRm50mXtdZ9VXrpOOXg/PZFrrPlib93lkPRT3g
7+rB3/0jFegK9bjKhI3+Xf32m/VN53ena6sX/e1fD9C3nuuVwD035S+Dqj434LmuHw/Ksz3bsz3b
sz3bsz3bs/27/U+AAQAc01NxshiL2gAAAABJRU5ErkJggg==' transform='matrix(1 0 0 1 476 871)'>
           </image>
          <g>
              <path fill='#FFFFFF' d='M533.53,969.64c-13.04,0-23.64-10.6-23.64-23.64v-28.54c0-13.04,10.6-23.64,23.64-23.64h28.54
                 c13.04,0,23.64,10.6,23.64,23.64V946c0,13.04-10.6,23.64-23.64,23.64H533.53z' />
            <path fill='#DEE3E9' d='M562.07,894.18c12.84,0,23.28,10.44,23.28,23.28V946c0,12.84-10.44,23.28-23.28,23.28h-28.54
                 c-12.84,0-23.28-10.44-23.28-23.28v-28.54c0-12.84,10.44-23.28,23.28-23.28H562.07 M562.07,893.46h-28.54
                 c-13.25,0-24,10.75-24,24V946c0,13.25,10.75,24,24,24h28.54c13.25,0,24-10.75,24-24v-28.54
                 C586.07,904.2,575.33,893.46,562.07,893.46L562.07,893.46z' />
           </g>
        </g>
      <g>
           <circle fill='#0F2834' cx='536.3' cy='931.73' r='3.5' />
        <circle fill='#0F2834' cx='548.3' cy='931.73' r='3.5' />
        <circle fill='#0F2834' cx='560.3' cy='931.73' r='3.5' />
        </g>
     </g>
  </g>
</g>
  <g id='w4A1'>
  <g>

        <image overflow='visible' opacity='0.22' width='143' height='143' xlink:href='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAI8AAACPCAYAAADDY4iTAAAACXBIWXMAAAsSAAALEgHS3X78AAAA
GXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAEWpJREFUeNrsnYlu47wOhSnZ/d//
eae2dHGBCaCyXA612GnHBgInaZLa8ufDRQuJnu3Znu3Znu3Znu3Zfv+WnvO6bKtPIz/n8AD1Qxv+
tyrljwQq/fJjTD8chvrAs/640o3nXy/6Xn3gGT+WtPCc0uKLWCf8dn3giR1DmvT+nfDUSe+/DUjp
jf93mvze3WarTn7vdojSD4Bm9PXV51sDfxt9fStE6Y3ASSAQaRCkdDE4ESBqx+duAyi9MTTe89QB
193weM9r5/dugSjdCE4PHN4+CtJoG0SVpgLv1UGgLgMovTE0yD4FgFuhRKjSaO8h4EThuwyg9Abg
RCFBnr8rPBo4yPMedVoKUboIHERtrOfR1/x5jzmLwmM9t+CIvtbAulyF9pvA0dRFeo3+zfqtu+BB
wKjg35FjSc1egiXNBCjdDA4KRe/DUqBRgBBfBIFl5OGZN9SpvxWeCDg9sGTnNQrRVfD0QFOc1xGY
LgFoXwwOv0AePNl5noHPIgqUAklFNBSvQcUp7Hlm7yUDqogJm26uVvk8qF/jKYy0z8BnelVoFjyo
4hRjXwT/pSi/XwYAGgZqn6w6yQCpF5gswJMVsFCIoiE8EpJH1EaCpbDHS4kSA4iYWlmO9VKA9kXg
aObKg0aCYlNAyo4yaSARoEAReDzlKQo8yIMfe2n2BJqx6kDSDdBqs4WA4z0257UGkwdPr+NcAyaL
g6PBczrvnQwg6f+jJmya/7NPVp1RcDbhubdHIIr4PmkgwvJMlfY4hX3+uz8baE5BiYiZuR6AuoDa
bwDHMkstGJvyOgKRBRBqvhBzRUA05SnL+fdcWnCSANGpHKcEkASIBkoYoNUZZisM59BooLweu/E5
Sb0kZzoSeUUBiirOycA5G3DaR6s2p3PMHKC0MnzfJ6sOgaZqM5SGg9K+3hWwEAWaBdAMc3UyeA4B
osM49tM4vmKcT3VUKATUzCRhAk2WZqJ2Y78DEElQRtUn6vN4JgsBpzVXBwPnEFQb3Yrx+Xq18vSG
5p5TLAEiPTbhNaJCFkBW31cvPFIOR/JvWoXZ2L4Fx4NdUsS27Yvh/wzlfmYmCZGuhyyYqR14fBgw
aQAhznPEaUYjLS8c5+DsAjiayY32uWkmbIrfs09SnYi5ygA4H84+CpCU+4nme7z8jpbT0VSHgyMd
u6Y85PhdXIG8c7osVPdS+l5Yvjnq89E8tNe7YsJ2wfdB/R4LoEh+x1IeDs8LoE8DekR5pGPJggKh
IToE0z7JVFkmK6I8H+Cj/fwmQJQd9VkVbUl9V6cDz0t5Ph3VQRRnY68TAGC3+uwDCmOpjpbb0aIr
CZz/2F4DSHOirZA9TzZbnvK8ADoEeCLQeB2vVegslX6vzlCffaHqZMdRtpSnBUeDiJuwzVAfrb9r
ZrRlZZQ5PNzP+TRA98Dh/3cTPtNeFytZeHmex1OeDAL0IQD0HwCQ5EBrfo8Gz4xoy4Pn/Htslup4
+RxvuMemqI8GEK2MthCThURbWl+VpTz/AQC10G2O6erxeaQ7Meowc5/HU52o4hT63mOfm+dWkFBH
TNc+wWShTrLlLGuOsQeQZLosvwf1ebzGQ7omtEjLSyl4isN/e2evN/o6oAxRny7HefYYZiS7rPVd
7YbDLAHE1WeF8vT0bXnKg+RzODReJ+tG30chZkV5unvRI/CgJkvLLmcnu2wlBj2AXu9piUPEYUaU
B82rkBKqn4LD7OVzpN+3gNwaeKRe+aJcH7RjVIVsX2CykC6JLWC+JIC46ZoBT1R5EHikC304Pg5q
/loHfKPv44Gyo0DDpmtfZLIy4P943RMSQB9O1CVFW17GdtTn8fq2NgWc5DjHBYCHm8HT8PHKbNM1
o3vCyvNkUH22AESjyrManqr4JKfSW444xi9QPoS+MQ2i1Gm6lsBjzQDtGcujDfbS+rA+FKA8eCI5
HjTDjPo9hfkiKag4HJq2M3Vn0PQOguseYbgHnGVyEliRMcuW8mh+kAYWEqqjwzFmwCP1qvPhpCg4
H8y/2YXeeG/2iQcOP2d4fHNv3xaaHERzPsj4ng/yx/1sgPJE+7QifVxE36cOJydklkyV5BTvDCBv
LDcyCZJoYED8rO4JK1noTbPxFGgjf5Sh1zGK9GmNwkNN1wCf8Xk60FQnojocfzGiPASAs9RhRpdO
6enn2gBwNsKGo+aO/A7aMWqZriRkdE/HOd6Z0pwANBvNGey/dDDYyHIpyBRia5CYNw0HhWdk4DsS
bRHrBiiED6vYGUCaEiNKEwWp22neJznLFIDIgwaZx5UBcDbCJ/1F4NESa1IvdlG+v7Ge8JPsCY6Z
8GlGCDSeSEA+0Ky+rZ6FmpC56VZDWnPXvfxOzyKXVfibNIXXWwquHTaxGQBlR22iJisK0rDZQiKt
aAepZspQKDbgTkwUGwBGgbtSSuNLABX6Oo64VaeNfQaZi2/NSUMXeLCuUzjimqk8RH0JQxQmZEUM
dK76iL9Din/AGzozgNpBWoX8OW3IkjKZ/CX4kLlql3dPRPu7UkCRsvGZyKpgK5zlyJYZKNUBPQWg
QUBaeo5XTvpDfaAUUBft+9GVwXryPCmozIkpD7p0nnWjRPycaYozAk8Co7De1U4z2SuIoX9Li1TH
833QbpviAOEp7cjqH1MGh40kCdGQHjVj2cgPJcLmnBP1Lerdm+dJStTFozHvOBEgRhbsRK5XOEmY
J/s6M8xY77rLBISjyTG3I+dKwP9LHTfU6BrUo+d6CTyRhkXXSk7gBbdg8e78mZEmBZTGu8C9N8ms
G2SK2er1D7wL5jUagQ01G4pI3xYa0qeAUqdA2xD5i5SnjuOFfKA86c5DspaoH0SBO8672wgwWz3d
L9HfpSAYyXFsewOAqVnmq8wW0pmK+g+zLnivs0wT/ycCIqLUqKpPT2LdsXkgoP4Dai69VMOMc4jU
GUPOO+K/3FLiPHeqyQzHOVrzc4bJufPG6FWcke/NuG5vozwJSFaNADHb/Mz+fpr4P25XoHwRMCnY
SGlS477DNjo+6pKw+yf5PL2+0Ns14JuASHe0T6ZnewcgfuTN8cBz/6bVyaoPPP9w4y5qi942+5Hw
1OBJ/8uKg7TL27RRftOGrGBDWRegvqFa1EmAWWUhfy08EiToxa/BC3WF0vWWnEZW3kB/1yuJPQvY
bnhmHEQFG9DybyrQSNZnVt6dyNqFBJyfpCwVUKJfYbYQ0KyaCZYsE9iYkeOpE2GhicdFgKJYN82o
Ar2dw1wdBfBAiqhKxDREndcK/i56nMjDu9kiUNcZQO2TwKgA7RVUHk+6PXPQTvddFQqP3kBekFAB
pUFuzCggIec+z/5BEDC0iH0FYEMUZUbDRqFAFcozPd7fakDdfpTPgzi/PY+Is0kLGjaSWkBvBK80
QE8bLfV99kXQRGU42nDFaVCtobTlREZUlQAoes9ZOs8SuLHQlEi9UnlGQk+rIYqxL4HGqwGFmK08
iJmRzsW7IcqAEkWc/qXKI1VMidZnKIaSFKeBtYvAFxFoZ2R6DZNoLMHorctcnPO0Kub03kSoGXsr
s+XJMaou2mutyku7EheHKQ9EYKMmqwbUpDgqbN1YETP2Vj5P7QApAgny4MojgYWoae/5eubJgl+r
u85rTXht1KtAl8ODRDlIOSGtpKJUpMMDqFUeIn2JE8th7lnQkl+QAgJ0OufIQTqNtvP8JnISlOG8
1w4Akoz3I4pTwIZEG7Vdkv/1njaMNTNFkpaSjURdaNU/RF3OwDlbatPjUE9XHgsY6c5EFAepv2kB
JBXrkFZWl5arzfS1VPTM1VCRwMBSUOnctHMvAQXuBSakQnugsRLgH6DlDD2AtOIc7ftIJeDMAELW
I54RZVl1t7zHwfYaXKfiH0Ucaa/7Z6nPg5optH6UBI5Wa8GrHrORvqD2FcpjnetB38tkW3sLlhIA
iEZgmQWPtIgRWm9TU5uDPSRwtHpVSQGnLRnUW9UYDRaIsMp8hwLRIbSDBBoCEwrQraE6mk22ANJU
h4ODlFbkxemtanc9pgtNDFrK8wLhk76WyD7YexpEHCbP94nkgkIZ571DZbxMsweNZZ4OpjK87ngC
wSnUXzJpBjya8nAQPgVwJKgOw9RJCuRBM+wsW/B4EVcb5vaG5RZAW7P/FFSEDHBeZqsQXhZbgiZN
yO+g8HwKIFkQIaYr6jiH/Z89aKJS0O+R7kDLVG2N2kjF6rPjtPPiH7011XuLtRXgvLnZ+v/+jwLS
p2PKTieJGo2ybvF5EIf5pK/1v62SQJLaWBVkeIF6rVhrpNqf1WEaMVmo8vwBQDoc3+cEcz+Xdowi
qkMd/o4EkLb6ubRpBerRmpsEqk+9AB4LoANQnxOExnu9NM8jFepIgSiLJ/qkWuPZCK1J8at4ld/s
OM2o34P6O1om/XSiLQ2cP476nA5EXleF58u5IO3BKAutNVVYnxKv8ns0XQsHqBBaPuX1u1Khs16z
5fk8PcojpSI+FfXRQDoEgM6AuZo6RGPUYZaiLj4kIjFwOECeebF8HO4/IXXVe8pF9pTFrqDyaOqD
mrCo6ZpismZlmDWzJamPBhBSTMwqH80z0hlUnll5nl7lORzzhZguL1yvhu/TbbIQeBDTpZkTSX3a
oRNaH5VXk1O6KGi98Z4iH5FIyxs5wP2UTyDy+qPkgVD1WTIco0d5pKyylTRsuwRO6itdaIHDS2Mf
ZFf/u1N5itGH9WkAZPk8x4DqDPtBszpGLfXhI/xOiq1EruWMPoQMtVaLNHUkCtE8jzd68FQiTU99
kFzPGTBdyHy25X1bEfXhAJ2GClngFMNc8frrVvHWu6Kt4nQAa31dVpfFGVCf6aozmmFG1OfViETf
R/uN+jlccXZHdWaUjIyOINQirlMxYZ+GKTuCoXp1QvXhkH1GhtlSnxYcPiTiDHR9aEnGXeniQAvV
roQnoj6n0QkayTBHRxUiHb9L+7Y09eHTYZBoBukfazPJh2KuUNVZFW1x9TmDACFjekbyOpf2bUXV
R/JZeuApSha5zSQfjq+j1S+lifAQYYP9IybMGmkYGU0YXc9neZIQDd+rokA98Gysx3wz+rF6TNbo
MNTIwH8PIOt5b4Q1fSjqPmimvFkVPHUfNVltg7TDLTLQE4+oDlpaKbLeIKI+3tQi5FGMhCA68W9o
sYOZ042txQLaab/I3Wypj+bfeDXXPXBmKA+RPgEPAaiQP9UmMm5n6VK7o6tkcHBGAGqHkPKxyHxA
WXYUJ3Wqzsi8LU99UBWyoPLmryPR1RTVWaU8lhPNAdJOsp2s1wKU6fuMUURtvErKvcpDhC3gVAIq
5M2k9ZZguQSc2d0TqBnTVq7QIGrnXWX62iuP+DdRR7lnoQNkiZVC+AxaZDrxaC7nFrMVMV8WQO1w
jXYuudTolmnqUZzV8FAHQJWwZVgi6/MgKnPbAHjPfCF+EJ9/lQVwOEQJ8G2i4MyYbkwBE4YucFWp
f1GnJeaqp8Eiv+GVd+av+UWXgEBgyWTXYreAGYVHA4cIW4exd0k9pOSAl264DZ5egIj8sT2eqqC+
zYipWmXCECiia+1El/ild4AHBYgAVUgdkEShuQqeCETR5XJHVsCoqy74KoBQFaIgKB6Is0xVjwlD
VocfWdH0NnBWwNMLUMSsEeF+zd3wEHjRkdIAiD9zGTir4LF+NwVhGnlOQPfDbHjIuZhRoFBoPP9m
SZ5nZfnlBLw/okiRPU30dVDfh0CV8ICKKMylJTNX1+5O4N8SoE4R0FBQVitPNC9EgajpNmiugmcG
RKi5u0ppZilRxPz0Fmaj3wAP8r9Qp7bnc3fCgwI08rnLwbkanlGIRgG58lzrJLDeEpo74emBaAUo
6QJYVgF1KzTvAM/ohU4Tz+uKeqRR/2RFHfmp20bvs6VJn7vqhqgLvlMX/u9fqTx3qMYdZmu1ej3w
TDy+dzq3etN3/2l4fuqx3gnbc0H+IaDq0/jPef1aSJ7t2Z7t2Z7t2Z7t2f7l7X8CDAD7FgGZ9du9
9AAAAABJRU5ErkJggg==' transform='matrix(1 0 0 1 845 282)'>
     </image>
    <g>
        <path fill='#FFFFFF' d='M902.17,380.54c-13.04,0-23.64-10.6-23.64-23.64v-28.54c0-13.04,10.6-23.64,23.64-23.64h28.54
           c13.04,0,23.64,10.6,23.64,23.64v28.54c0,13.04-10.6,23.64-23.64,23.64H902.17z' />
      <path fill='#DEE3E9' d='M930.72,305.08c12.84,0,23.28,10.44,23.28,23.28v28.54c0,12.84-10.44,23.28-23.28,23.28h-28.54
           c-12.84,0-23.28-10.44-23.28-23.28v-28.54c0-12.84,10.44-23.28,23.28-23.28H930.72 M930.72,304.36h-28.54
           c-13.25,0-24,10.75-24,24v28.54c0,13.25,10.75,24,24,24h28.54c13.25,0,24-10.75,24-24v-28.54
           C954.72,315.1,943.97,304.36,930.72,304.36L930.72,304.36z' />
     </g>
  </g>
    <g id='XMLID_1_'>
     <circle id='XMLID_2_' fill='${accentColor}' cx='911.99' cy='357.87' r='3.5' />
      <circle id='XMLID_6_' fill='${accentColor}' cx='925.39' cy='357.87' r='3.5' />
      <path id='XMLID_8_' fill='${accentColor}' d='M935.04,331.68c0.37-0.95-0.33-1.98-1.35-1.98h-29.39l-1.39-4.8
        c-0.17-0.6-0.72-1.01-1.34-1.01h-7.42c-0.78,0-1.45,0.64-1.43,1.42c0.01,0.76,0.64,1.37,1.4,1.37h6.41l7.17,24.72
        c0.17,0.6,0.72,1.01,1.34,1.01h20.82c0.78,0,1.45-0.64,1.43-1.42c-0.01-0.76-0.64-1.37-1.4-1.37h-19.81l-0.65-2.26h18.16
        c0.79,0,1.5-0.48,1.79-1.22L935.04,331.68z' />
  </g>
    <g>
     <defs>
        <path id='SVGID_19_' d='M917.39,343.11l-4.4-4.66l1.23-1.26l3.15,3.29l5.78-6.17l1.28,1.23L917.39,343.11z' />
     </defs>
      <clipPath id='SVGID_20_'>
        <use xlink:href='#SVGID_19_' overflow='visible' />
     </clipPath>
      <g clip-path='url(#SVGID_20_)'>
        <polyline id='lineOkA1' fill='none' stroke='#FFFFFF' stroke-width='2' stroke-miterlimit='10' points='913,337 917.27,341.85
           924.51,334.32 \t\t\t\t' />
     </g>
  </g>
</g>
  <g id='w3A1'>
  ${iconCard || `<g>

    <image overflow='visible' opacity='0.22' width='143' height='143' xlink:href='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAI8AAACPCAYAAADDY4iTAAAACXBIWXMAAAsSAAALEgHS3X78AAAA
GXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAEZNJREFUeNrsnYmO4yoQRQvbmf//
3m4DT0+aSAxTy60CL+mxJStLJx2Dj28tYIro2Z7t2Z7t2Z7t2Z7t52/paddpW306+Tn+B6oP7Pyf
qpIfC1T6wceXfggM9YHnuGNKN2h7Pel79YFn7FjSAW1KJwFTJ/3P+sAzBkP6MHiq82/17hClD4Im
gf8jXdD2kRONgFPvCFG62W+mya+vautMUOpdIUofAE0KfG5UlWaDgwIR/dwlEKWLwUFOvgUSCtpV
8CBAoO95IKqfDg+qNgl4z/OZM5UoqjTVgCUK1GkQpRupjQZLAoHywHQUPNJrz2N1ghbxtW4LTxqE
RnoP+Qyiamcpj6QeI8895u5QgNKF4FgnnwMHec+jQLPhQc1TBV/XSYp0CEDpBuBY6oLsqDpdBY8E
hgRLdUB0GUDpJHD6E+YBZnGCNKJEM3yd6oBH2osDIg2kQwHaTgQHMUkaNEsAKguio+FBoOFASQBI
2nGl7nllzk29AzwRcBIIzqK8h8AkqduoCUNNFfe6KK9Ld8JL97z/HgEQHQZQugk4EiQL+B6iRL0K
zYZHc4wtWNpH6Tn3iJg2LRKrVyvPCDgaCP2elPdQiCz/x4LI6+dI4PSAcK/fbXk/bx8JUCBOeXq1
GVKfbaLqeMCxFKXdVwGmxVCk5IRoFjwRcLS9Pe7SHOP77wsIkARMGKBtMjjkBAcBZnWAFAVo1GyN
KM57z8rrLEDUg1uUY53iJJ9htlBwFgWc9nEV/mZBZDnSEYDQXI7k20jgrA0w+fdx5waczLShPQaP
Ck1Rn+0gc+UFRwJmZeBZHRBxABFovhCAEHgQtckdQEsDy8IARJ35oisAmp3n8YIjqcv6+9hWY9fM
muZEaxlo7iKxxq8s1dEUp9/7PuoVJxuwF+XYp5qubZLqSH/rAdLAWRlwNgdM/f9FIrDZylNBU5UF
ePbu+aIcezaOsSgXwRT12SapjeXnWOBsAjjSc1SJPOrj9XkkgCzV0cBZfz++wdmF47YSg5aq1hkq
tA2oDhnQWCAhsHD7qoDE+UEcQCN+jyen06pPVkxVbsBpzdUbIAQIzrwW4LNh9dkmqg4HkqQ6Ejj9
/nKAtBoOdJpguqyhCMtkZcVU9W3YnYrT+179d6twrg5PElrDENbouAUQB8yrg+cFAmSpz0i+xwMP
ojr772PfFfg1xUGHHYpivsLqMxqqJ8B09ZEW5+9sHTAv5XUPkqY+K5OQ9A5XoONZiOoUximWwElB
5elH5y0FOjTDjNycpymOBc7KgGLtkgJZ6oP4PX270TBdSgpmxmztjep8A/BYv19BgKqiXqfleRIA
kAccTnl+Cc9fghlbDd8HyTbPMFuc8mQBnjbC+nbC49kj6mPCtA2aLEl1FsPXkcDZOlh+dc9fgi9k
RV4zcz1ojkdylls/Z2+UR/JzLHBaWN/fb+cCLR1A09RnC5gs1HlejGhLUpxXB44EkKU+aMQ1O9rS
lIdzkNdOdSTFsWYg9q8Xp+9D3mhsm6A6krlqX2uZYc1sIQBxkRcacXkGSJEB0aL4PLkBZxNUxwOO
tC+MAknqc/nYFpoUXAxwNsbHkQB6GQBFlScCD4HKkzuA1i4inAFODxAHjmS+3KZrGzRZVl5Hg8jK
7/xymK/ZyqNFW9pcHm5ognOWtWOTwLGAycIFU5SL+/RpqJ77qzTVQXwfDaDe99GShWlQeTwzCC2f
Z1UGPVFwWiDbvuzVJzEAaT6NK+oayTBbI+mWw4yE61LILkVe3mgrojwjSUIEHMlMcUMbWwdQZtSs
KAo0ZLqO9nkQ9ZHGsjQVah855VkA0+DN81RHorAwJ14DJxmKk3+3URpQXQWAUN/n9FF1Lfqypogi
jrMVxiPKE4HHY7asLPN7X41Rckm9cgfOi/GdPAECAaZrGJ7k9He8vo+VMJScaGmoQvJ50I4czfNY
pisD41Wc4ryYKG1j8kVLACApbIf9npGxLXRQdAHM18ao0AsAanMqz+gkeBSeyjivGQCH829eDTyb
kmj03gBAho83fXhCGxTVQIqYrtUJjgaPNQkeHZrQwOkhWEi+dcbycbjhjK0DCJmCu4DW4fL7trx+
jyfzrM0ilBKEnkgrCo+kPFx0kw1otg6ajUkqWneVIJPfEk1aHWVkPo932RPtLtAVAEl71CbEW9I9
674tYkayl055smCqtsax3gSH2Jrw78moc+0+7L4tlNio32OF8uitOCuT9pcirSOV5w1PEXyKVYjI
+nbvDrWJrluEOs0QPJHCIYlid1FotxhLd4pKt+xId5RK/s4R8PTDFMSMZK+NaVsVeBay5yh552uj
C3xC850jUzI0JfKsvZMAiCQgVrLvOo3kd9Boq188iTrFIUZ9qqA8a5fsQ9ptheWzfJ5pUzI8PtBI
CG8B4lk1Y8akdyucJfp7NS9uOgT3uDJJRO+9+dqANOLrhCOuo5aV8y4Vp62Y4ZXqRfndSGLQfUU2
Wzs0wEVkI21FLxRkkavToq3oGFcSGqsphgYI8n+Q/A7R/IU92+VOuPYX0tcoQha+SgFgpm4zbr1J
5Luvy/J/EmHLylnL0s1c1InzfZLxt9SoDzEmq5C89iJihiPgjCRGpyYJE/C3yKqn1kKWifCoIhnH
PVpikls4gITnqCIjpj7iHCdgZKBebbYsMxaBybsGMwUkPBKqcw6zdFJSAKIl2CeegCDsMC80f0PM
GDlgQgAhZ4SBqqjnQtGg9KpxAlUogb8x0tYh5RmV+ZE6Eh6IUBM600lGlKcakFlTRFDo0N+41GG2
IizEv4jAhHYgMu2Agn8nio1Ae5xXa90jTdGs3/CkIExTdoTZQpSHjAw1gZ0aVckR04UcIwEXjTfF
YWX2Pf7NlNH1M30eT7iPzvjzDNaOqI33ezOOywOcdWEeYraWiUB4ncoUvKrRSjrIVT/7orFyXZbq
eR1gOrhNpyuPp4PT5A5JB3/eA8wIaJ5jRS8qmi0UZ/s83qhuRPovuRonR6sJDP8vafNC99o8Nxd+
+uZ10m/X9uVDOvnWnXiDi2uGj/ox8PyLJ94b+t++j66Cp074Xv0h8HBlH+sntG+5YUeinfsvbnUA
yI+EB21IndxJ9eYnHOmXOtA3P055JHmuk66eoz9/xe9pfXOpIi+T1UP7jlUs1TJRnu8dWow+eLKt
vkSKyXq/N+O83d5hRkGrTnNVwd+e1YaIKa5ge2/nAy4HgWHV4JSk11PW2eM31IBajHw+clzeC6YC
fXuoA71MAqUCB4jcoqt1DPr5CijBbBDR34xcMN621knny9w2EBBuYrdHgaryHjnBQP2H5DzeI0yA
10/zXBwE9K0XEFdUd5bZIrCjrBoK5PhcBVVsVlurUzVH26Z9f4bjfKrP4+0EZDn84uhwNGI5IiJB
fw+9kLQ+KIG+PQSibRIonujBCxEClPQb0jrD/VzdGTmYajiy6DJ0kX6pYL/PSsOE4eFWhvCuVaxV
xdMK2ms1yxfQIeRu0KMOpGgex6uyI+0tToCsJONlCx2Q03epzg6y3uuXcfNGExH1qQF4tAvF096I
CbuN2fJ0qrZKKLdSulZnQavD0NeZasGiE9pJ4MVRA21D+wrxC+lO8EQd4uqApq+5IHX6W3lKExD0
AFXS7y0fAYdIXou5AqBkoA8sYLxqdBg8UqeiOQfLJEl1qaQiHe1S/MgytUT8yqQ14PtE/DrtIkDa
qf1d6scKBBXWeYTUarbyeOy+drXlroMlgDL9veKo5NMk0lc+R30f9IKpxskvRvu4fkBVGM0RTVee
SnYN7ogJQ8xRJrkGefv+0j22ax1zxzRS4S9isqqhqlkBRGt3Bs1b1FS5VGhzqIu0/t4INJLavFc9
556/lyDZyb51tl9QCVnkyFs+gIs0rWp/fZXj/nlWnmdHn3qiL7dzPZIkTIFEYAE6VOrktiQQV3qo
P76VSRaepTxc4ZLSgaCBsjv6JgJMCJbZPk/UYSwgMNwi1pLycOCspBcrmwmPFCRkQVkl5eF2L0DF
kY0/NFTXfKDkSAAWw8b3HbaSXKADqZDXFiez1mPmwEmAk2yZrCq08//Hb/qztvrevYfApDnVSOhO
NDAHfAsAI3VsxN+RCtW/ofkmvSiH5KyXTnX6NfyIfAtcogOexYi0epX5ZsDhoNoZlUL9HxQad8i+
OU2UtBKW12HufRmp3nhfsN4CpzDmCq1uPMtsaTVGJXi+GZA4iLLiYEcd5rD/M+LzWFEXlwTknOAs
KI0EjQZOWz1mtDR21NezIq3ebP3/+NUA8yUA1SuRFIllYwCVQLN1isOchA5cwAir92t6aFBTtdHf
dau46jdpEKAKmuvKJAQ15fkSwOEg2hVoEL9nynhXNM/TyrzXXK0MODvZ5Q5JUZz3/+5LRSPwjJqt
aKTVm6gvZtfgyYLz7DFfNALV5oyyuDrciOnqAVq6zlzIrguqgVPoz8p4SOmkiN/jGUm3cjytGfpi
AOpN2C4AZIXsHpPlut9t1thWcozvtHkatF4Uojq98qDwzFYeDR4uJfGtqA8C0A6E64dM0RiJtjTT
VejvQh39mJQEUHIqDjessZBd8e8In0fL8RQhIfjNOMuo6dqVQWRr6gaNQhWZklEdeZ4WpHbke2EA
QtWmv6pfTK4oojyWAnkzy5ryZCa3EwHIyjZ75vO4b9GeNTxhqU9i1MdThc4qH22VjLYKncyEB40w
dyXf8yU8ar6Px2G2koKHhOpWZRcNoNw9eoqKSOC8a6vvxNdVjyqPJ8PsVR5tiOJbAUmLurSIy3OH
6fSqN4jpsqKPBIDj9XNenfJYqoMWb/NEW9ZoOqo+38JwhTfPg5otGjVZUbMlOc5WBGIBpP2PYpgr
S3XQUH1GkrCQPoswK6Po34ov1MM16u+cdusNqj79VdhezRxA2u+hPoRkshYQHmtFeu99Wqj67Ir/
sxtjXLsxtiVFWjRLdUYcZkR9OJAS/T1lNDnhycJovFRaGil4NtPn0fweFCANFiu341GeoZzP7LEt
rnNLIAlXldDcAgepOU4HRFtE9v1XoxPDpJmI3lmFp45taUMTWu6nvw24ACdHA2drOkqaLLY6TBZN
gIccpkub5L4rj8gourUogtWW6UlCD1SaTS3O/8fB0063yMRPT/WAc9TY1ghAyOT3DITnnrk8l+R5
enAS4B+U4Ah1oT/n6XDAIObKqgoYHdsiwqbgavdrefZC8Qnw5BwYPUV5JBXqTVgZiLA0pbFCcy2/
M0N5iOwFHCQH2rp/i4NFAgedenq68lghelL8HwsgaQJ76b6XDWgscNACalb4Wp3qY90tm8FH6x52
5FZwNGQ/PFTnwNEOpAdIkti1gaif0N7OEswdMGgtciJ/zSpt0ji6Ho8WxkvvZQCY6Jzly0J1GgAo
CfmUXn0W+nO1i3bPFC9i780sezPNnsUePHsFzdQh92rNgkdblgQBqIeoEr+6V1HMEmqm0IK3o/Ag
0Ze1WoiVKfZAgwAThmlmYXpSnE+txPMivNZMUQQaFByv2fKqUAHMWQWB0aaZIk5ynXnizwCIjBO8
GM8X4LNWPXL0TlEUHiJsQUtkxTDk7zMWL6hXmq2R8F36fH+yK/O80J/TWz3F7KOKE1GgkRVgC/A5
ooMXMjhLeaT/ZdVVR5QoulvQHA1PBKLI+soRcOrd4IkChIBETli8icARh9mbQPSsCB8B5rTSUelE
NUsDakQDsFwNTxQmGlCZU6ofHlVBF6mPjjjVZMCRgO8fAY4HIAJOPvIcdYZPK5t5dPllD0TSSbeU
BH1ETWsUHK8vhD6i1RJPr7V6Ru3uqAqhQIyap5nKEzVnCEi3UJuz4bF+J0147gHmyDbXAZBGn58K
zpnwjEA0629XwOPxi0b+dio0V8HjhSiqJOnC9tZJYCGvL4HmanjQ3z8alHQgLEcBdTk0d4EnClFE
wWYcSz0AsKizW68+aSvda0sHw3aGytDBMNRPO1l3P650UR/UE79bP/0kfdox3qF99aLvPvB8+PH+
OGB+2slIDyhPx//ktlV6tmd7tmd7tmd7tmd7to/c/hNgAE1AHCZl4qAGAAAAAElFTkSuQmCC' transform='matrix(1 0 0 1 845 182)'>
    </image>
    <g>
      <path fill='#FFFFFF' d='M902.17,280.19c-13.04,0-23.64-10.6-23.64-23.64V228c0-13.04,10.6-23.64,23.64-23.64h28.54
           c13.04,0,23.64,10.6,23.64,23.64v28.54c0,13.04-10.6,23.64-23.64,23.64H902.17z' />
      <path fill='#DEE3E9' d='M930.72,204.72c12.84,0,23.28,10.44,23.28,23.28v28.54c0,12.84-10.44,23.28-23.28,23.28h-28.54
           c-12.84,0-23.28-10.44-23.28-23.28V228c0-12.84,10.44-23.28,23.28-23.28H930.72 M930.72,204h-28.54c-13.25,0-24,10.75-24,24
           v28.54c0,13.25,10.75,24,24,24h28.54c13.25,0,24-10.75,24-24V228C954.72,214.75,943.97,204,930.72,204L930.72,204z' />
    </g>
  </g>`}
    ${iconSvg || '<path id=\'w3A1M\' fill=\'#C2CDD6\' d=\'M938.53,233.17h-41.8v25.53c0,1.44,1.21,2.32,2.32,2.32h37.16c1.11,0,2.32-0.88,2.32-2.32\n     V233.17z M896.73,230.85h41.8v-9.3c0-1.11-0.88-2.32-2.32-2.32h-37.16c-1.44,0-2.32,1.21-2.32,2.32V230.85z M932.03,223.18\n     c1.03,0,1.86,0.83,1.86,1.86c0,1.02-0.83,1.86-1.86,1.86c-1.03,0-1.86-0.83-1.86-1.86C930.17,224.01,931,223.18,932.03,223.18z\n      M928.02,225.05c0,0.92-0.82,1.74-1.74,1.74c-3.94,0-19.23,0-23.17,0c-0.93,0-1.74-0.82-1.74-1.74s0.82-1.74,1.74-1.74h23.17\n     C927.21,223.31,928.02,224.13,928.02,225.05z\' />'}
</g>
  <g>
  <defs>
     <path id='SVGID_21_' d='M549.81,613.23h-0.72v-12h0.72V613.23z M549.81,591.23h-0.72v-12h0.72V591.23z M549.81,569.23h-0.72
        v-8.83h0.72V569.23z' />
  </defs>
    <clipPath id='SVGID_22_'>
     <use xlink:href='#SVGID_21_' overflow='visible' />
  </clipPath>
    <g clip-path='url(#SVGID_22_)'>

        <line id='l3A1' fill='none' stroke='${accentColor}' stroke-width='2' stroke-miterlimit='10' x1='549.45' y1='613.23' x2='549.45' y2='560.4' />
  </g>
</g>
</g>
</svg>
`;

  return (
    <Box ref={container}>
      <Box className='main_animation_container' sx={{ ...sx }}>
        <Box
          dangerouslySetInnerHTML={{ __html: svg }}
          sx={{
            '& svg': {
              maxWidth: { xs: 'calc(100% + 32px)', md: '100%' },
              mr: { xs: 0, md: '-2px' },
              ml: { xs: '-16px', md: 0 },
            },
          }}
        />
      </Box>
    </Box>
  );
}

HeroDesktopAnimation.propTypes = {
  sx: PropTypes.object,
  icon: PropTypes.string,
};

export default HeroDesktopAnimation;
